<template>
  <mcw-dialog-title>
    Документы
    <mcw-icon-button class="btn_close" @click="$emit('close')">
      <mcw-material-icon icon="close" />
    </mcw-icon-button>
  </mcw-dialog-title>
  <mcw-dialog-content>
    <mcw-list v-if="docs && !selectedDoc && !addForeignDocForm && !generateDocForm">
      <mcw-list-item v-for="(doc, index) in docs" :key="index">
        <div class="document-card">
          <span>
            <b>{{ doc.doc_name }}</b> (Добавлен: {{ doc.login_add }} {{ doc.da_date_add }})
            <mcw-material-icon
              v-if="doc.id_num_document !== 0"
              icon="edit"
              @click="setEditedDocType(doc)"
            />
          </span>
          <div v-if="doc.id_document.length" class="badges-row">
            <div v-for="id in doc.id_document" :key="id" @click="selectDocId(id)" class="badge">
              <span style="display: flex;">
                <mcw-material-icon icon="attach_file" />
                <span class="id_wrapper">{{ id }}</span>
              </span>
              <span v-if="selectedDocId === id" class="document-action-icons">
                <mcw-material-icon
                  icon="open_in_browser"
                  @click.stop="openDocument(selectedDocId)"
                />
                <mcw-material-icon icon="delete" @click.stop="deleteForeignDoc(selectedDocId)" />
                <mcw-material-icon icon="cancel" @click.stop="selectDocId(null)" />
              </span>
            </div>
          </div>
        </div>
      </mcw-list-item>
    </mcw-list>
    <EditFillableDocument v-if="selectedDoc" :doc="selectedDoc" />
    <AddForeignDocument v-if="addForeignDocForm" :patient="selectedPatient" />
    <GeneratePatientDoc v-if="generateDocForm" :patient="selectedPatient" />
  </mcw-dialog-content>
  <mcw-dialog-footer>
    <mcw-dialog-button
      v-if="!addForeignDocForm"
      :disabled="selectedDoc"
      class="all-patient-table-btn"
      @click="toggleGenerateDoc()">
      {{generateDocForm && !addForeignDocForm ? 'Назад к документам' : 'Заказать генерацию'}}
    </mcw-dialog-button>
    <mcw-dialog-button
      v-if="!generateDocForm"
      :disabled="selectedDoc"
      class="all-patient-table-btn"
      @click="toggleForeignDoc()">
      {{addForeignDocForm && !generateDocForm ? 'Назад к документам' : 'Добавить документ'}}
    </mcw-dialog-button>
    <mcw-dialog-button action="dismiss">отмена</mcw-dialog-button>
  </mcw-dialog-footer>
</template>

<script>
import EditFillableDocument from './EditFillableDocument.vue';
import AddForeignDocument from './AddForeignDocument.vue';
import GeneratePatientDoc from './GeneratePatientDoc.vue';

import { BASE_URL } from '../helpers/Api';

export default {
  name: 'ForeignDocumentsModal',
  emits: [
    'close',
  ],
  components: {
    EditFillableDocument,
    AddForeignDocument,
    GeneratePatientDoc,
  },
  props: {
    docs: Array,
    selectedPatient: {
      type: null || Number,
    },
  },
  data() {
    return {
      selectedDoc: null,
      addForeignDocForm: false,
      generateDocForm: false,
      selectedDocId: null,
    };
  },
  created() {
    this.emitter.on('closeEdit', this.closeEdit);
    this.emitter.on('closeAdditing', this.closeAdditing);
  },
  methods: {
    setEditedDocType(doc) {
      this.selectedDoc = doc;
    },
    closeEdit() {
      this.selectedDoc = null;
      this.addForeignDocForm = false;
    },
    closeAdditing() {
      this.addForeignDocForm = false;
    },
    toggleForeignDoc() {
      this.addForeignDocForm = !this.addForeignDocForm;
    },
    toggleGenerateDoc() {
      this.generateDocForm = !this.generateDocForm;
    },
    selectDocId(id) {
      this.selectedDocId = id;
    },
    async deleteForeignDoc(id) {
      await this.$store.dispatch('contractsData/removeDocument', { id });
      this.emitter.emit('getForeignDocuments');
    },
    async openDocument(id) {
      const path = await this.$store.dispatch('getDocPath', id);
      if (path) {
        window.open(`${BASE_URL}${path.hash}`, '_blank');
      }
    },
  },
};
</script>

<style></style>
