import { modules } from '../constants';
import Api from '../helpers/Api';

const { covid } = modules;

export const test = {
  namespaced: true,
  state: {
    branchList: [],
    vaccines: [],
  },
  actions: {
    async getLink({ rootState }, params) {
      let data = { ...rootState.user.constantParams };
      data = Object.assign(data, params);
      data.Name_module = covid;
      data.id_org = rootState.user.selectedCompany.id_org;
      const result = await Api.post(data, true);
      if (result.body) {
        return result.body[0].link;
      }
      return false;
    },
    async getContractBranches({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:available_branches';
      data.id_contract = id;
      const result = await Api.get(data);
      if (result) {
        commit('SET_BRANCH_LIST', result.body);
        return result.body;
      }
      return false;
    },
    async getContractVaccines({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:available_vaiccines';
      data.id_contract = id;
      const result = await Api.get(data);
      if (result) {
        commit('SET_CONTRACT_VACCINES', result.body);
        return result.body;
      }
      return false;
    },
    async saveBranchAvailability({ rootState }, params) {
      let data = { ...rootState.user.constantParams };
      data = Object.assign(data, params);
      data.Name_event = 'POST:available_branches';
      const result = await Api.post(data, true);
      if (result) {
        return true;
      }
      return false;
    },
    async getPollTypes({ rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:poll_types';
      data.id_contract = id;
      const result = await Api.get(data);
      if (result) {
        return result.body;
      }
      return false;
    },
  },
  mutations: {
    SET_BRANCH_LIST(state, branches) {
      state.branchList = branches;
    },
    SET_CONTRACT_VACCINES(state, vaccines) {
      state.vaccines = vaccines;
    },
  },
  getters: {
    getBranchList(state) {
      return state.branchList;
    },
    getVaccines(state) {
      return state.vaccines;
    },
  },
};
