<template>
  <section class="section-column">
    <mcw-button @click="isMenuOpen = !isMenuOpen">
      Выбрать шаблоны
    </mcw-button>
    <mcw-menu v-model="isMenuOpen">
      <template
        v-for="type in typesForGenerate"
        :key="type.doc_type"
      >
        <mcw-list-item style="cursor: not-allowed">
          {{type.doc_name}}
        </mcw-list-item>
        <mcw-list-item
          v-for="template in type.templates"
          :key="template.id_template"
          class="list-item-child"
          @click="selectItem(template)"
        >
          <mcw-material-icon
            icon="check"
            class="icon_base"
            v-if="selectedItems.find((item) => item.id_template === template.id_template)"
          ></mcw-material-icon>
          {{template.name_template}}
        </mcw-list-item>
      </template>
    </mcw-menu>
    <mcw-chip-set>
      <mcw-chip
        v-for="template in selectedItems"
        :key="template.id_template"
      >{{template.name_template}}
      </mcw-chip>
    </mcw-chip-set>
    <mcw-button
      :disabled="!selectedItems.length"
      @click="orderGeneration"
    >Заказать</mcw-button>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GeneratePatientDoc',
  emits: [
    'closeAdditing',
  ],
  props: {
    patient: Number,
  },
  data() {
    return {
      isMenuOpen: false,
      selectedItems: [],
    };
  },
  computed: {
    ...mapState({
      typesForGenerate: (state) => state.employeesData.typesForGenerate.map((item) => {
        try {
          const templates = JSON.parse(item.templates);
          return { ...item, templates };
        } catch (error) {
          console.log(error);
          return false;
        }
      }),
    }),
  },
  methods: {
    selectItem(template) {
      const existedIndex = this.selectedItems.findIndex((item) => item.id_template === template.id_template);
      if (existedIndex === -1) {
        this.selectedItems.push(template);
      } else {
        this.selectedItems.splice(existedIndex, 1);
      }
    },
    async orderGeneration() {
      const params = {
        doc_templates: this.selectedItems.map((item) => item.id_template),
        id_patient: this.patient,
      };
      const result = await this.$store.dispatch('employeesData/orderGeneration', params);
      if (result) {
        this.emitter.emit('message', 'Запросу на генерацию отправлен');
      } else {
        this.emitter.emit('message', 'Не удалось отправить запрс на генерацию');
      }
    },
  },
};
</script>

<style>
.section-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  }
  .list-item-child{
    padding-left: 60px;
  }
</style>
