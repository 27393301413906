<template>
        <div class="table__header table__header_justify">
          <div class="table__header-left">
            <h2
              class="page-heading"
              style="width: auto">
              {{title}}
            </h2>
            <SearchSelect
              titleWidth="166px"
              label="org"
              :needles="['org', 'inn']"
              universalMode
              placeholder="Организация"
              :options="items"
              :initial="selectedCompany"
              backendSearchEvent="user/getAdditionalCompanies"
              :showCounter="true"
              @selectItem="selectOrg"
              @filter="filterData"/>
            <div class="header__element" v-if="isAuth">
              <div class="actual-redactor" v-html="getActualRedactorHTML"></div>
            </div>
            <mcw-button
              @click="addOrg"
              :disabled="userRank < 100">
              Добавить организацию
            </mcw-button>
          </div>
        <mcw-button class="table__export-btn" @click="exportToExcel">
            <mcw-material-icon
              class="icon_base"
              icon="download">
            </mcw-material-icon>
            Экпорт в excel
          </mcw-button>
      </div>
  <div class="scrollable-content">
  <div
    class="table"
    ref="table"
    v-if="items.length">
    <mcw-data-table
      sticky
      v-if="items.length">
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('org', null)">
              {{transcriptColumnTitle('org')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort"
              @click="sortTable('doer', null)">
              {{transcriptColumnTitle('doer')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('inn', null)">
              {{transcriptColumnTitle('inn')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--numeric"
              @click="sortTable('sum_act', null)">
              {{transcriptColumnTitle('sum_act')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--numeric"
              @click="sortTable('summ_pay', null)">
              {{transcriptColumnTitle('summ_pay')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--numeric"
              @click="sortTable('debet', null)">
              {{transcriptColumnTitle('debet')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--numeric"
              @click="sortTable('bill_without_act', null)">
              {{transcriptColumnTitle('bill_without_act')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--numeric"
              @click="sortTable('debet_common', null)">
              {{transcriptColumnTitle('debet_common')}}
            </th>
          </tr>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell table__cell_second-row">
              Итого:
            </th>
            <th class="mdc-data-table__header-cell table__cell_second-row"></th>
            <th class="mdc-data-table__header-cell table__cell_second-row"></th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric table__cell_second-row">
              {{showRank(getSum('sum_act'))}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric table__cell_second-row">
              {{showRank(getSum('summ_pay'))}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric table__cell_second-row">
              {{showRank(getSum('debet'))}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric table__cell_second-row">
              {{showRank(getSum('bill_without_act'))}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric table__cell_second-row">
              {{showRank(getSum('debet_common'))}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric table__cell_second-row">
            </th>
          </tr>
        </thead>
        <tbody
          class="mdc-data-table__content">
          <tr class="mdc-data-table__row mdc-data-table__row_clickable"
            v-for="item in paginatedData"
            :key="item.n"
            :ref="'row-' + item.id_org"
            :class="{
              row_active: activeCompany && activeCompany.id_org === item.id_org,
              highlight: item.temp,
            }">
            <td class="mdc-data-table__cell table__cell org-name-cell">
              {{item.org}}
              <span class='action-buttons'>
                <mcw-button @click.stop="selectOrg(item)">
                  <mcw-material-icon icon="meeting_room" class="icon_base"/>
                </mcw-button>
                <mcw-button @click.stop="showOrgData(item.inn)">
                  <mcw-material-icon icon="info" class="icon_base" />
                </mcw-button>
              </span>
            </td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric table__cell">
              {{item.doer}}
            </td>
            <td class="mdc-data-table__cell table__cell">
              {{item.inn}}
            </td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric table__cell">
              {{showRank(item.sum_act)}}
            </td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric table__cell">
              {{showRank(item.summ_pay)}}
            </td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric table__cell">
              {{showRank(item.debet)}}
            </td>
            <td
              class="mdc-data-table__cell mdc-data-table__cell--numeric table__cell"
              :class="{table__cell_clickable: activeCompany && activeCompany.id_org === item.id_org}"
              @click="expandDebt(item.id_org, item.bill_without_act, item.Id_org_doer)">
              {{showRank(item.bill_without_act)}}
            </td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric table__cell">
              {{showRank(item.debet_common)}}
            </td>
          </tr>
        </tbody>
      </table>
    </mcw-data-table>
  </div>
  </div>
  <Pagination
  :pages="pages"
  @selectPage="selectPage"
  @changeNumber="changeNumber"
  :itemsPerPage="itemsPerPage"/>
</template>

<script>
import CommonTable from './CommonTable.vue';
import SearchSelect from '../SearchSelect/SearchSelect.vue';
import Pagination from '../Pagination/Pagination.vue';
import PaginationMixin from '../Pagination/paginationMixin';
import { utils } from '../../helpers/utils';

export default {
  name: 'OrgsTable',
  extends: CommonTable,
  components: {
    SearchSelect,
    Pagination,
  },
  mixins: [
    PaginationMixin,
    utils,
  ],
  props: {
    items: Array,
    tableHeight: Number,
  },
  data() {
    return {
      isLoading: false,
      title: 'Организации',
      filterString: '',
    };
  },
  computed: {
    isAuth() {
      return this.$store.state.user.isAuth;
    },
    activeCompany() {
      return this.$store.state.user.selectedCompany;
    },
    filteredData() {
      return this.items
        .filter((item) => (this.filterString.length > 2
          ? item.org.toLowerCase().includes(this.filterString.toLowerCase())
          || item.inn === this.filterString
          : item))
        .sort((a, b) => (a.id_org === this.activeCompany?.id_org ? -1 : 1));
    },
    actualRedactor() {
      const redactor = this.$store.getters['treeData/getCurrentRedactor'];
      if (redactor && !redactor.error) {
        return this.trimName(redactor.persons);
      }
      return '';
    },
    getActualRedactorHTML() {
      if (this.actualRedactor) {
        return `Сейчас редактирует:<br> ${this.actualRedactor || ''}`;
      }
      return '';
    },
    selectedCompany() {
      if (!this.$store.state.user.selectedCompany) {
        return {};
      }
      return this.$store.state.user.selectedCompany;
    },
    userRank() {
      return this.$store.getters['user/getUserRank'];
    },
  },
  methods: {
    filterData(val) {
      this.filterString = val;
    },
    showOrgData(inn) {
      this.emitter.emit('addOrg', inn);
    },
    addOrg() {
      this.emitter.emit('addOrg');
    },
    selectOrg(org) {
      if (org) {
        this.emitter.emit('selectNewOrg', org);
      }
    },
    async expandDebt(id, debt, doer) {
      if (id !== this.activeCompany.id_org) {
        return false;
      }
      const params = {
        includeServices: false,
        actId: -1,
        doer,
      };
      this.emitter.emit('globalLoading', true);
      const patients = await this.$store.dispatch('employeesData/getLastPatients', params);
      this.emitter.emit('globalLoading', false);
      if (patients) {
        const actData = {
          val: true,
          actId: -1,
          sum: debt,
        };
        this.$store.commit('employeesData/SET_ACT_TO_LOADED', actData);
        this.$store.commit('employeesData/IS_PATIENTS_LOADED', false);
        this.$router.push({ name: 'Structure', params: { acts: true } });
      }
    },
  },
};
</script>

<style lang="scss">
.org-name-cell {
  display: flex;
  justify-content: space-between;
}
.orgs__summary{
  flex: 1 1 100%;
  display: flex;
  padding: 0 20px;
}
.orgs__summary-sum{
  white-space: nowrap;
}
.table__header_justify{
  justify-content: space-between;
}
.table__header-left{
  display: flex;
}
.mdc-data-table__row_clickable{
  // cursor: pointer;
}
.row_active td{
  background-color: rgba(0, 0, 0, 0.08);
  // cursor: default;
}
.table__cell{
  &_clickable{
  color: var(--mdc-theme-primary)!important;
  cursor: pointer!important;
  text-decoration: underline;
  }
  &_second-row{
    top: 56px!important;
  }
}
.highlight {
  background-color: yellow !important;
}
.action-buttons i {
  margin-right: 20px;
}
</style>
