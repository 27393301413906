import moment from 'moment';
import EditableInput from '../EditableInput.vue';
import Spinner from '../Spinner.vue';
import { utils } from '../../helpers/utils';
import { transcriptColumnTitle } from '../../helpers/transcriptor';

export default {
  name: 'EditContract',
  mixins: [
    utils,
  ],
  components: {
    EditableInput,
    Spinner,
  },
  emits: [
    'close',
  ],
  props: {
    id: String,
  },
  watch: {
    id(val) {
      if (!val) this.$emit.close();
    },
  },
  data() {
    return {
      contract: {},
      reg: /^\d\d\.\d\d\.\d{4}$/,
      boolParams: [
        'archive',
        'for_medsafe',
        'medsafe_locked',
        'medsafe_half_locked',
        'signed',
      ],
      isLoading: false,
      isChanged: false,
      selectedContractType: null,
      selectedDoer: null,
      templates: [],
      selectedTemplate: null,
      parameters: [
        {
          id: '0',
          text: 'Ничего не делать',
        },
        {
          id: '1',
          text: 'Добавить новые услуги',
        },
        {
          id: '2',
          text: 'обновить цены у имеющихся услуг',
        },
        {
          id: '3',
          text: 'полностью поменять прайс',
        },
      ],
      isMenuOpened: false,
      selectedParameter: null,
      tempField: '',
    };
  },
  created() {
    if (this.id) {
      const existedContract = { ...this.$store.getters['contractsData/getContractById'](this.id) };
      const arr = Object.entries(existedContract);
      const problemFields = ['Id_org_customer', 'Id_org_doer', 'N_contract'];
      arr.forEach((item) => {
        if (item[1] === null) {
          existedContract[item[0]] = '';
          item[1] = '';
        }
        if (problemFields.includes(item[0])) {
          const newKey = item[0].toLowerCase();
          // eslint-disable-next-line prefer-destructuring
          existedContract[newKey] = item[1].toString();
          delete existedContract[item[0]];
        }
        if (item[0] === 'date_contract' && item[1].length > 10) {
          existedContract[item[0]] = this.readableDate(item[1]);
        }
        if (typeof item[1] === 'number') {
          existedContract[item[0]] = item[1].toString();
        }
        if (this.boolParams.includes(item[0])) {
          existedContract[item[0]] = this.strToBool(item[1]);
        }
      });
      this.contract = existedContract;
    } else {
      this.contract = {
        id_org_doer: '',
        n_contract: '',
        date_contract: '',
        name_contract: '',
        type_contract: '',
        id_org_customer: this.$store.state.user.selectedCompany.id_org,
        org_customer: this.$store.state.user.selectedCompany.org,
        id_org_beneficial: this.$store.state.user.selectedCompany.id_org,
        org_beneficial: this.$store.state.user.selectedCompany.org,
        note_for_adm: '',
        note_for_patient: '',
      };
    }
  },
  mounted() {
    if (this.contract.type_contract) {
      this.getTemplate();
    }
    if (!this.contract.n_contract) {
      this.$nextTick(() => {
        this.contract.n_contract = this.contractsCount;
      });
    }
  },
  beforeUnmount() {
    if (this.isChanged) {
      this.$store.dispatch('contractsData/getContractsList');
    }
  },
  computed: {
    title() {
      if (this.id) {
        return 'Редактировать договор';
      }
      return 'Создать новый договор';
    },
    isPossibleToSave() {
      return !!this.contract.type_contract
      && !!this.contract.id_org_doer
      && !!this.contract.n_contract
      && !!this.contract.name_contract;
    },
    contractTypes() {
      return this.$store.getters['orgsData/contractTypes'];
    },
    contractsCount() {
      return this.$store.getters['contractsData/getContractsCount'].toString();
    },
    doers() {
      return this.$store.getters['orgsData/doers'];
    },
  },
  methods: {
    setParameter(value) {
      this.selectedParameter = this.parameters[value.index];
    },
    selectTemplate(id) {
      this.selectedTemplate = id;
      this.isMenuOpened = true;
    },
    async getTemplate(typeId) {
      const templates = await this.$store.dispatch('contractsData/getTemplate', typeId);
      this.templates = templates;
    },
    async setBoolParams(param) {
      this.isLoading = true;
      const newParams = await this.$store.dispatch('contractsData/setContractConditions', { param, contractId: this.id });
      this.isLoading = false;
      if (!newParams) {
        this.emitter.emit('message', 'Не удалось изменить параметры контракта!');
        return false;
      }
      if (!this.isChanged) {
        this.isChanged = true;
      }
      const paramsArr = Object.entries(newParams)
        .filter((item) => this.boolParams.includes(item[0]));
      paramsArr.forEach((item) => {
        // eslint-disable-next-line prefer-destructuring
        this.contract[item[0]] = item[1];
      });
      this.$store.commit('contractsData/SET_SINGLE_CONTRACT', this.contract);
    },
    transcriptColumnTitle(str) {
      return this.capitalize(transcriptColumnTitle(str));
    },
    setNewBeneficial(org) {
      this.contract.id_org_beneficial = org.id_org;
      this.contract.org_beneficial = org.names;
    },
    setNewCustomer(org) {
      this.contract.id_org_customer = org.id_org;
      this.contract.org_customer = org.names;
    },
    selectType(type) {
      this.contract.type_contract = type;
    },
    selectDoer(doer) {
      this.contract.id_org_doer = doer;
    },
    equate() {
      const tempContract = { ...this.contract };
      tempContract.id_org_customer = this.contract.id_org_beneficial;
      tempContract.org_customer = this.contract.org_beneficial;
      this.contract = tempContract;
    },
    swap() {
      const tempContract = { ...this.contract };
      tempContract.id_org_customer = this.contract.id_org_beneficial;
      tempContract.id_org_beneficial = this.contract.id_org_customer;
      tempContract.org_customer = this.contract.org_beneficial;
      tempContract.org_beneficial = this.contract.org_customer;
      this.contract = tempContract;
    },
    async saveNote(note) {
      const noteObj = {};
      noteObj[note] = this.contract[note];
      const params = {
        id_contract: this.id,
        json_str: JSON.stringify(noteObj),
      };
      const result = await this.$store.dispatch('contractsData/saveContractNote', params);
      if (result) {
        this.emitter.emit('message', 'Примечание сохранено');
      } else {
        this.emitter.emit('message', 'Не удалось сохранить примечание');
      }
    },
    validateDate(dateStr) {
      // eslint-disable-next-line no-restricted-globals
      return dateStr.match(this.reg) && !isNaN(new Date(moment(moment(dateStr, 'DD-MM-YYYY')).format('MM-DD-YYYY')).getTime());
    },
    async saveContract() {
      console.log(this.contract.date_contract, this.validateDate(this.contract.date_contract));
      if (!this.validateDate(this.contract.date_contract)) {
        const currentDate = new Date();
        this.contract.date_contract = currentDate.toLocaleDateString('ru-RU');
      }
      const params = {};
      const json = this.contract;
      if (this.id) {
        params.id_contract = this.contract.id_contract;
      } else {
        params.id_contract = 0;
      }
      if (this.selectedTemplate) {
        json.id_contract_template = this.selectedTemplate;
      } else {
        json.id_contract_template = '0';
      }
      if (this.selectedParameter) {
        json.id_parametr = this.selectedParameter.id;
      } else {
        json.id_parametr = '0';
      }
      json.QR_RF = '';
      json.QR_foreign = '';
      params.json_str = JSON.stringify(json);
      const save = await this.$store.dispatch('contractsData/saveContract', params);
      if (save) {
        this.emitter.emit('message', 'Договор сохранен');
        this.$emit('close');
        this.$store.dispatch('contractsData/getContractsList');
      } else {
        this.emitter.emit('message', 'Не удалось сохранить договор');
      }
    },
  },
};
