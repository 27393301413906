import exceljs from 'exceljs';
import { sortTableArr } from './sortTableArr';
import { transformAllpatientsTable, transformServicesSubtable } from '../constants';

export async function createExcel(params) {
  function transcriptColumnTitle(column) {
    const title = params.tableColumnNames.find((item) => item.name_variable === column);
    if (title) {
      if (title.field_title) {
        return title.field_title;
      }
    }
    return column;
  }
  const border = {
    top: { style: 'thin', color: { argb: '00000000' } },
    left: { style: 'thin', color: { argb: '00000000' } },
    bottom: { style: 'thin', color: { argb: '00000000' } },
    right: { style: 'thin', color: { argb: '00000000' } },
  };

  const font = {
    size: 8,
    name: 'Calibri',
  };

  const tableData = params.tableData
    .map((row) => {
      const arr = sortTableArr(Object.entries(row), transformAllpatientsTable);
      return arr.filter((el) => params.selectedColumns.includes(el[0]));
    });

  let servicesColumnIndex;

  const columnHeaders = tableData[0]
    .map((item, index) => {
      if (item[0] === 'services') {
        servicesColumnIndex = index;
      }
      return transcriptColumnTitle(item[0]);
    });

  const calculateWidths = () => {
    const items = [];
    tableData[1].forEach((elem) => {
      if (params.selectedColumns.includes(elem[0])) {
        items.push((String(elem[1]).length + 1) * 0.8);
      }
    });
    return items;
  };
  const lastColumnLetter = String.fromCharCode(columnHeaders.length + 64);

  const workbook = new exceljs.Workbook();
  const worksheet = workbook.addWorksheet(params.title);
  worksheet.properties.outlineProperties = {
    summaryBelow: false,
  };
  worksheet.autoFilter = `A1:${lastColumnLetter}1`;
  const columns = [];
  const widths = calculateWidths();

  columnHeaders.forEach((item, index) => {
    const column = {
      header: item,
      key: item,
      width: widths[index],
    };
    columns.push(column);
  });
  worksheet.columns = columns;

  const headRow = worksheet.getRow(1);
  for (let i = 1; i <= columnHeaders.length; i += 1) {
    const cell = headRow.getCell(i);
    cell.font = {
      name: 'Calibri',
      bold: true,
    };
    cell.border = border;
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'dce6f2' },
    };
  }

  tableData.forEach((item) => {
    const rowData = item.map((elem) => elem[1]);

    const row = worksheet.addRow(rowData);
    row.outlineLevel = 0;
    for (let i = 1; i <= columnHeaders.length; i += 1) {
      const cell = row.getCell(i);
      cell.border = border;
      cell.font = font;
    }
    if (params.addServices) {
      const serviceItem = item.find((el) => el[0] === 'services');
      const serviceData = serviceItem ? JSON.parse(serviceItem[1]) : null;
      if (serviceData) {
        const serviceArr = serviceData.map((el) => sortTableArr(Object.entries(el), transformServicesSubtable));
        serviceArr.forEach((service) => {
          const serviceRowData = [];
          Object.values(service).forEach((serviceElem) => {
            serviceRowData.push(serviceElem[1]);
          });
          const serviceRow = worksheet.addRow(serviceRowData);
          serviceRow.outlineLevel = 1;
          for (let i = 1; i <= serviceRowData.length; i += 1) {
            const serviceCell = serviceRow.getCell(i);
            serviceCell.border = border;
            serviceCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'f2f2f2' },
            };
            serviceCell.font = font;
          }
        });
      }
    }
  });

  if (params.addServices && servicesColumnIndex !== -1) {
    worksheet.spliceColumns(servicesColumnIndex, 10);
  }

  worksheet.pageSetup = {
    paperSize: 9,
    orientation: 'landscape',
    fitToWidth: 1,
    fitToHeight: 18,
  };
  return workbook;
}
