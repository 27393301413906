import { createStore } from 'vuex';
import { auth } from './auth';
import { treeData } from './tree';
import { employeesData } from './employees';
import { contractsData } from './contracts';
import { orgsData } from './organizations';
import { test as testData } from './test';
import { loginData } from './login';
import Api from '../helpers/Api';
import { NAME_APP, modules } from '../constants';

export default createStore({
  modules: {
    user: auth,
    treeData,
    employeesData,
    contractsData,
    orgsData,
    testData,
    loginData,
  },
  state: {
    deviceWidth: null,
    descriptions: [],
  },
  getters: {
    deviceWidth(state) {
      return state.deviceWidth;
    },
    tableColumns(state) {
      return state.descriptions;
    },
  },
  mutations: {
    SET_DEVICE_WIDTH(state, val) {
      state.deviceWidth = val;
    },
    SET_DESCRIPTIONS(state, data) {
      state.descriptions = data;
    },
  },
  actions: {
    async getDescriptions({ commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:fields_titles';
      const modulesArray = [NAME_APP, modules.calculation];
      Promise.allSettled(
        modulesArray.map((module) => {
          data.Name_module = module;
          return Api.get(data);
        }),
      ).then((results) => {
        let fieldsArray = [];
        results.forEach((result) => {
          fieldsArray = fieldsArray.concat(result.value.body);
        });
        commit('SET_DESCRIPTIONS', fieldsArray);
      });
    },
    async getDocPath({ rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = 'WEB_division';
      data.Name_event = 'get_pic_path';
      data.id_doc = id;
      const result = await Api.get(data);
      if (result.errorType === 0) {
        return result.body[0];
      }
      return false;
    },
    async checkIfDone({ rootState }, param) {
      const data = { ...rootState.user.constantParams, ...param };
      data.Name_module = modules.medAdmin;
      data.Name_event = 'pdf_check_if_done';
      const result = await Api.get(data);
      if (result.body.length) {
        return result.body[0];
      }
      return false;
    },
    async getFile({ rootState }, hash) {
      const file = await Api.getFile(hash);
      if (file) {
        return file;
      }
      return false;
    },
    async sendBugReport({ dispatch, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = 'module_reports';
      data.Name_event = 'POST:report';
      data.text_report = params.text;
      data.type_report = 0;
      const result = await Api.post(data, true);
      if (result.body) {
        const uplParams = {
          id: result.body[0].id_report,
          file: params.file,
        };
        await dispatch('uploadBugReportFile', uplParams);
        return true;
      }
      return false;
    },
    async uploadBugReportFile({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      const uploadParams = JSON.stringify({
        Name_app: data.Name_app,
        id_login: data.id_login,
        id_object: params.id,
        id_type: 10,
      });
      const send = async (blob) => {
        const formData = new FormData();
        formData.append('imgUploader', blob, params.id);
        formData.append('json', `${uploadParams}`);
        const upload = await Api.uploadFile(formData, 'UploadTM');
      };
      params.file.toBlob((blob) => {
        send(blob);
      });
    },
  },
});
