export default class DaData {
  url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party';

  token = 'd925c94a83608dec71618801f5280ba9f90bc205';

  query = '';

  constructor(query) {
    this.query = query;
  }

  async getOrganizationByTin() {
    try {
      const response = await fetch(this.url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          // eslint-disable-next-line quote-props
          'Accept': 'application/json',
          // eslint-disable-next-line quote-props
          'Authorization': `Token ${this.token}`,
        },
        body: JSON.stringify({ query: this.query, count: 300 }),
      });
      const result = await response.text();
      return result;
    } catch (error) {
      console.log(error);
    }
  }
}
