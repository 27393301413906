<template>
  <mcw-dialog-title>
    Добавление услуг
    <mcw-icon-button class="btn_close" @click="$emit('close')">
      <mcw-material-icon icon="close"/>
    </mcw-icon-button>
  </mcw-dialog-title>
  <mcw-dialog-content>
    здесь будет контент какой-то
  </mcw-dialog-content>

  <mcw-dialog-footer>
    <mcw-dialog-button action="dismiss">Выйти</mcw-dialog-button>
    <mcw-dialog-button action="accept" isDefault>Сохранить</mcw-dialog-button>
  </mcw-dialog-footer>
</template>

<script>
export default {
  name: 'ServiceEditor',
  props: {
    contractId: Number,
  },
  emits: [
    'close',
  ],
};
</script>

<style>

</style>
