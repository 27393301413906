<template>
  <div
  class="table__container"
  :style="height"
  @dragover.prevent
  @dragenter.prevent
  @dragend="clearHighLight"
  @drop.capture="onDrop($event)">
  <mcw-icon-button
    class="table__close-btn"
    v-if="items.length"
    @click="closeTable">
    <mcw-material-icon icon="close" />
  </mcw-icon-button>
  <div
    class="table"
    ref="table"
    v-if="items.length">
    <mdc-data-table
      v-if="items.length">
      <div class="table__header">
        <div
          class="mdc-typography--subtitle1 table__title">
          <span v-if='tableTitle'> {{tableTitle}} </span>
          <span class='table-summary' v-if='items.length'>
             | Записей: {{items.length}} |
          </span>
        </div>
          <mcw-textfield
            v-model.trim="searchString"
            placeholder="Поиск (ФИО)"
            class="table__filter"
            dense
            outline/>
          <mcw-button class="table__export-btn" @click="exportToExcel">
            <mcw-material-icon
              class="icon_base"
              icon="download">
            </mcw-material-icon>
            Экпорт в excel
          </mcw-button>
      </div>
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--checkbox"
              role="columnheader"
              scope="col">
              <mcw-checkbox
                v-model="select"
                @change="selectAll('id_employee')"
                ref="rowCheckbox"
                class="mdc-data-table__header-row-checkbox">
              </mcw-checkbox>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('fio')">
              {{transcriptColumnTitle('fio')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('post')">
              {{transcriptColumnTitle('post')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('tab_number')">
              {{transcriptColumnTitle('tab_number')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('sex')">
              {{transcriptColumnTitle('sex')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('datebirth', 'date')">
              {{transcriptColumnTitle('datebirth')}}
            </th>
          </tr>
        </thead>
        <tbody
          class="mdc-data-table__content">
          <tr class="mdc-data-table__row row_draggable"
            v-for="item in visibleItems"
            :key="item.id_employee"
            v-show="!filteredData.length || filteredData.includes(item)"
            draggable="true"
            @dragstart="startDrag($event, item)">
            <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
              <mdc-checkbox
                class="mdc-data-table__row-checkbox"
                :value="item.id_employee"
                ref="rowCheckbox"
                v-model="checkedItems">
              </mdc-checkbox>
            </td>
            <td class="table__cell">{{item.fio}}</td>
            <td class="table__cell">{{item.post}}</td>
            <td class="table__cell">
              {{item.tab_number}}
            </td>
            <td class="table__cell">{{item.sex}}</td>
            <td class="table__cell">{{item.datebirth}}</td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mdc-dialog
      v-model="isDialogOpen"
      escape-key-action="close"
      scrim-click-action="close"
      aria-labelledby="my-basic-title"
      aria-describedby="my-basic-content"
      :auto-stack-buttons="true">
      <component
        :is="componentName"
        v-bind="{
          title: dialogTitle,
          patients: checkedItems,
          moveId: moveId,
          tableType: type,
        }" @finishEditing="finishEditing">
      </component>
    </mdc-dialog>
  </div>
  </div>
</template>

<script>
import CommonTable from './CommonTable.vue';

export default {
  name: 'EmployeesTable.vue',
  extends: CommonTable,
  props: {
    items: Array,
    tableHeight: Number,
    tableTitle: String,
  },
  data() {
    return {
      title: 'Сотрудники',
      type: 'employees',
    };
  },
  methods: {
    closeTable() {
      this.$store.commit(`employeesData/RESET_${this.type.toUpperCase()}_TABLE`);
    },
  },
};
</script>
