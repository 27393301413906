<template>
  <section v-if='fields' class='visible-fields'>
    <h2 class='edited-doc-header' v-if='doc'>{{ doc.doc_name }}</h2>
    <div v-if='fields.length'>
      <mcw-textfield
        v-for='(field, index) in fields'
        :key='index'
        type="text"
        :label='field.name_for_printint'
        :placeholder='field.name_for_printint'
        v-model='$data[field.field_name]'
        v-date='field.field_type === "date" ? true : false'
      ></mcw-textfield>
      <div>
        <mcw-button
          v-if='doc'
          @click="tryToSaveForeignDocData()">
          <mcw-material-icon icon="save" /> Сохранить
        </mcw-button>
        <mcw-button
          v-if='patient'
          :disabled='canISave'
          @click="tryToAddForeignDocData()">
          <mcw-material-icon icon="save" /> Сохранить
        </mcw-button>
      </div>
    </div>
    <div v-else><p>Не найдены поля для заполнения...</p></div>
    <mcw-button
      v-if='doc'
      @click="emitter.emit('closeEdit')">
      <mcw-material-icon icon="close" /> Закрыть
    </mcw-button>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EditFillableDocument',
  props: {
    doc: Object,
    id: Number,
    patient: Number,
  },
  emits: [
    'closeEdit',
    'getForeignDocuments',
  ],
  data() {
    return {
      fields: [],
    };
  },
  mounted() {
    let fields;
    if (this.doc) {
      fields = this.foreignTypes.find((i) => i.id === this.doc.id_doc_type)
        ? this.foreignTypes.find((i) => i.id === this.doc.id_doc_type).input_fields
        : [];
    } else if (this.id) {
      fields = this.foreignTypes.find((i) => i.id === this.id).input_fields;
    }
    if (fields) {
      fields.forEach((i) => {
        this.$data[i.field_name] = '';
      });
      this.fields = fields;
    }
    if (this.doc) {
      const docKeys = Object.keys(this.doc);
      const instanceKeys = Object.keys(this.$data);
      instanceKeys.forEach((i) => {
        docKeys.forEach((j) => {
          if (i === j) this[i] = this.doc[j];
        });
      });
    }
  },
  methods: {
    async tryToSaveForeignDocData() {
      const params = {
        id_patient: this.doc.id_patient,
        id_doc_type: this.doc.id_doc_type,
        id_num_document: this.doc.id_num_document,
      };
      const docKeys = Object.keys(this.doc);
      const instanceKeys = Object.keys(this.$data);
      instanceKeys.forEach((i) => {
        docKeys.forEach((j) => {
          if (i === j) {
            params[i] = this[i];
          }
        });
      });
      await this.$store.dispatch('employeesData/postForeingDocData', JSON.stringify(params));
      await this.emitter.emit('getForeignDocuments');
      this.emitter.emit('closeEdit');
    },
    async tryToAddForeignDocData() {
      const params = {
        id_patient: this.patient,
        id_doc_type: this.id,
      };
      const instanceKeys = Object.keys(this.$data);
      instanceKeys.forEach((i) => {
        if (i !== 'fields') {
          params[i] = this[i];
        }
      });
      await this.$store.dispatch('employeesData/postForeingDocData', JSON.stringify(params));
      await this.emitter.emit('getForeignDocuments');
      this.emitter.emit('closeEdit');
    },
  },
  computed: {
    ...mapState({
      foreignTypes: (state) => state.employeesData.foreignTypes,
    }),
    canISave() {
      const instanceKeys = Object.keys(this.$data);
      let instanceCounter = 0;
      let result = 0;
      instanceKeys.forEach((i) => {
        if (i !== 'fields') {
          instanceCounter += 1;
          if (this[i].length >= 3) result += 1;
        }
      });
      return instanceCounter !== result;
    },
  },
};
</script>

<style>
.visible-fields {
  display: flex;
  flex-direction: column;
}
.edited-doc-header {
  margin: 0;
}
</style>
