<script>
import EditPatient from '../EditPatient.vue';
import RemovePatient from '../RemovePatient.vue';
import AttachPatient from '../AttachPatient.vue';
import Spinner from '../Spinner.vue';
import PatientDataTable from '../PatientDataTable.vue';
import { utils } from '../../helpers/utils';
import { transcriptColumnTitle } from '../../helpers/transcriptor';
import EditableCell from '../EditableCell.vue';
import MultiSelect from '../MultiSelect.vue';

const chunkSize = 25;

export default {
  name: 'CommonTable',
  mixins: [
    utils,
  ],
  props: {
    items: Array,
    tableHeight: Number,
    infobox: Boolean,
  },
  components: {
    EditPatient,
    RemovePatient,
    Spinner,
    AttachPatient,
    EditableCell,
    PatientDataTable,
    MultiSelect,
  },
  data() {
    return {
      checkedItems: [],
      componentName: null,
      isDialogOpen: false,
      select: [],
      sortIndex: null,
      sortOrder: true,
      dialogTitle: '',
      moveId: null,
      currentPatient: {},
      currentPatientInfo: [],
      isPatientInfoOpen: false,
      searchString: '',
      visibleItems: [],
      step: 1,
      selectedType: null,
      selectedStatuses: [],
      isLoading: false,
      myRecords: false,
    };
  },
  computed: {
    scrollingTable() {
      if (this.$refs.container) {
        return this.$refs.container.querySelector('.mdc-data-table__table-container');
      }
      return null;
    },
    height() {
      return { height: `${this.tableHeight}%` };
    },
    filteredData() {
      return this.items
        .filter((item) => (this.selectedStatuses.length
          ? this.selectedStatuses.includes(item.status) : item))
        .filter((item) => (this.searchString.length ? item.fio?.toLowerCase()
          .includes(this.searchString.toLowerCase())
          || item.id_patient?.toString().toLowerCase()
            .includes(this.searchString.toLowerCase()) : item))
        // eslint-disable-next-line no-nested-ternary
        .filter((item) => (Number(this.selectedType) === 2
          ? item.id_tree_people : Number(this.selectedType) === 1 ? !item.id_tree_people : item))
        .filter((item) => (this.myRecords ? item.my_records === 1 : item));
    },
  },
  watch: {
    items() {
      this.searchString = '';
      this.visibleItems = [];
      this.step = 1;
      this.chunk();
    },
    filteredData() {
      this.visibleItems = [];
      this.step = 1;
      this.chunk();
    },
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      if (this.type === 'patients' && mutation.type === 'treeData/SELECT_ITEM') {
        this.checkedItems = [];
      }
      if (this.type === 'employees' && mutation.type === 'employeesData/SET_EMPLOYEES') {
        this.checkedItems = [];
      }
    });
    this.chunk();
    this.emitter.emit('tableAmount', this.items.length);
    this.emitter.on('checkDocStatus', this.showPatientInfo);
    this.$nextTick(() => {
      if (this.scrollingTable) {
        this.scrollingTable.addEventListener('scroll', this.scrollWatch);
        this.scrollWatch();
      }
    });
  },
  beforeUnmount() {
    this.emitter.emit('tableAmount', null);
    if (this.scrollingTable) {
      this.scrollingTable.removeEventListener('scroll', this.scrollWatch);
    }
  },
  methods: {
    transcriptColumnTitle(column) {
      return transcriptColumnTitle(column);
    },
    exportToExcel() {
      let showCheckbox = false;
      if (this.type === 'allPatients') {
        showCheckbox = true;
      }
      const title = this._title ? this._title : this.title;
      this.emitter.emit('exportToExcel', { showCheckbox, title, items: this.items });
    },
    scrollWatch() {
      if (this.scrollingTable.scrollTop > (this.setBreakPoint() - 50)) {
        this.step += 1;
        this.chunk();
      }
    },
    setBreakPoint() {
      return this.scrollingTable.scrollHeight - this.scrollingTable.offsetHeight;
    },
    chunk() {
      this.visibleItems = this.filteredData.slice(0, this.step * chunkSize);
    },
    clearHighLight() {
      this.$store.commit('treeData/HIGHLIGHT_LEAFS', false);
      this.$store.commit('employeesData/HIGHLIGHT_TABLE', false);
    },
    startDrag(evt, item) {
      if (this.type === 'allPatients') {
        this.$store.commit('employeesData/SET_TABLE_TYPE', this.type);
      }
      if (this.type !== 'employees') {
        this.$store.commit('treeData/HIGHLIGHT_LEAFS', true);
      } else {
        this.$store.commit('employeesData/HIGHLIGHT_TABLE', true);
      }
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('item', JSON.stringify({ item, table: this.type }));
      this.$store.commit('treeData/DRAG_PREVENT', true);
    },
    async onDrop(ev) {
      ev.stopImmediatePropagation();
      const droppedItem = ev.dataTransfer.getData('item');
      if (!droppedItem) {
        return;
      }
      const staff = JSON.parse(droppedItem).item;
      const checkExistedPatient = this.items.find((item) => item.id_people === +staff.id_people);
      if (checkExistedPatient) {
        return false;
      }
      const data = {
        positionId: this.positionId,
        staffId: staff.id_employee,
      };
      const attach = await this.$store.dispatch('employeesData/attachEmployee', data);
      if (attach === true) {
        this.$store.commit('treeData/SET_AMOUNT', { id: this.positionId, amount: 1 });
      } else if (attach.body[0].error) {
        this.emitter.emit('message', attach.body[0].error);
      }
      this.$store.commit('treeData/DRAG_PREVENT', false);
    },
    patientPositionName(id) {
      return this.$store.getters['treeData/getItemById'](id);
    },
    selectAll(field) {
      this.checkedItems = [];
      if (this.select[0] === 'on') {
        this.items.forEach((el, index) => {
          const id = el[field];
          if (id) {
            this.checkedItems.push(el[field]);
          } else {
            this.checkedItems.push(index);
          }
        });
      }
    },
    sortTable(column, type = null) {
      let order = 1;
      this.sortOrder = !this.sortOrder;
      if (!this.sortOrder) {
        order = -1;
      }
      const arr = this.items;
      function transformDataForSort(data) {
        if (type === 'date') {
          const date = Date.parse(data.split('.').reverse().join('-'));
          return Number.isNaN(date) ? 0 : date;
        }
        if (typeof data === 'string') {
          return data.toLowerCase();
        }
        return data;
      }
      arr.sort((a, b) => {
        let result = 0;
        const aData = transformDataForSort(a[column]);
        const bData = transformDataForSort(b[column]);
        if (aData > bData) {
          result = 1;
        } else if (bData > aData) {
          result = -1;
        }
        return result * order;
      });
    },
    finishEditing() {
      this.componentName = null;
      this.isDialogOpen = false;
      this.checkedItems = [];
    },
    async showPatientInfo(patient, refresh = null) {
      if (patient.id_patient === this.currentPatient.id_patient && !refresh) {
        this.currentPatient = {};
        return;
      }
      this.isLoading = true;
      const id = patient.id_people;
      const patientInfo = await this.$store.dispatch('employeesData/getPatientInfo', id);
      if (patientInfo) {
        this.isPatientInfoOpen = true;
        this.currentPatientInfo = patientInfo;
        this.currentPatient = patient;
      }
      this.isLoading = false;
    },
    closePatientInfo() {
      this.currentPatient = {};
      this.isPatientInfoOpen = false;
    },
    async saveTabNumber(params) {
      const data = {
        tabNumber: params.newText,
        id_tree_people: params.item.id_tree_people,
      };
      const newTabNumber = await this.$store.dispatch('employeesData/setTabNumber', data);
      if (!newTabNumber) {
        this.emitter.emit('message', 'Не удалось сохранить табельный номер');
      }
    },
    addNewContact(item) {
      this.emitter.emit('editContact', item);
    },
  },
};
</script>

<style lang="scss">
@use "@material/data-table/mdc-data-table";
@use "@material/checkbox/mdc-checkbox";
@use "@material/textfield/mdc-text-field";
@use "@material/button/mdc-button";
@use "@material/select/mdc-select";
@use '@material/list/mdc-list';
@use '@material/menu/mdc-menu';
@use '@material/menu-surface/mdc-menu-surface';
body{
  --mdc-checkbox-ripple-size: 30px;
}
.mdc-data-table{
  border: none;
  width: 100%;
  overflow: hidden;
}
.mdc-data-table__cell, .mdc-data-table__header-cell{
  padding: 3px;
}
.mdc-data-table__cell {
  height: 34px;
}
.mdc-data-table__row{
  position: relative;
}
.mdc-data-table__table-container{
  overflow-x: auto;
}
.table{
  // height: 100%;
  display: grid;
  overflow: hidden;
  position: relative;
  &__title{
    margin-left: 8px;
  }
  &__actions{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: absolute;
    bottom: 16px;
    width: calc(100% - 17px);
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.12)
  }
  &__header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__container{
    position: relative;
    display: grid;
  }
  &__close-btn{
    top: -8px;
    right: 20px;
    position: absolute;
    z-index: 2;
  }
  &__info-block{
    background-color: #eee;
    width: 300px;
    text-align: center;
    padding: 40px;
    margin: 0 auto;
    position: relative;
    top: 50px;
  }
  &__dropdown-header{
    margin: 0 auto 5px;
    padding: 0 10px;
    text-transform: uppercase;
  }
  &__cell{
    padding: 0 5px;
    font-size: 12px;
    -moz-user-select: all;
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
    &_center{
      text-align: center;
    }
    &_clickable{
      color: var(--mdc-theme-primary);
    }
  }
  &__checkbox-wrapper {
    display: inline;
    font-size: 1rem;
  }
  &__filter{
    min-width: 130px;
    @media screen and (min-width: $tablet) {
      margin: 0 20px;
    }
    & .mdc-text-field--outlined{
      height: 36px;
    }
  }
  &__select{
    margin-left: 10px;
    & .mdc-select__anchor{
      height: 36px!important;
    }
  }
  &_droppable{
    box-shadow: inset 0 0 10px rgba(green, 0.5);
  }
  &__arrow{
    position: absolute;
    bottom: -50px;
    left: calc(100% - 50px);
  }
  &__export-btn{
    margin-left: 10px;
  }
}
.mdc-data-table__sort-icon-button{
  position: relative;
  top: 5px;
}
.mdc-data-table__header-cell--with-sort{
  vertical-align: initial;
  color: var(--mdc-theme-primary);
}
.row_draggable{
  cursor: -webkit-grab;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.dense-grid-table {
  grid-template-rows: auto 1fr;
}
.search-with-button {
  display: flex;
  & .table__filter {
    margin: 0;
  }
}
.table__checkbox-wrapper {
  margin-top: 5px;
  & label {
    margin-left: 10px;
  }
}

</style>
