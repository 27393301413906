<template>
  <div class="payments">
        <table class="mdc-data-table__table" ref="table">
          <thead>
            <tr class="mdc-data-table__header-row">
              <th class="mdc-data-table__header-cell">
                <mcw-button @click="refresh" class="button_min">
                  <mcw-material-icon
                    :class="['icon_base', { icon_rotate: isRefreshing }]"
                    icon="refresh" />
                </mcw-button>
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('date_coming', 'date')">
                {{transcriptColumnTitle('date_coming')}}
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('name_pay')">
                {{transcriptColumnTitle('name_pay')}}
              </th>
              <th class="mdc-data-table__header-cell">
                {{transcriptColumnTitle('sum_pay')}}
              </th>
              <th class="mdc-data-table__header-cell">
                {{transcriptColumnTitle('acts')}}
              </th>
              <th class="mdc-data-table__header-cell cell-with-dropdown">
                <IconFilter
                  :options="doneFilter"
                  :selected="selectedType"
                  @select="onSelect"
                />
              </th>
              <th class="mdc-data-table__header-cell">
                {{transcriptColumnTitle('org_doer')}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="mdc-data-table__row services__row"
              v-for="payment in filteredPayments"
              :key="payment.id_pay"
              :ref="'row-' + payment.id_pay">
                <td class="mdc-data-table__cell mdc-data-table__cell--checkbox table__cell">
                  <mcw-checkbox
                    ref="rowCheckbox"
                    class="mdc-data-table__row-checkbox"
                    :value="payment.id_pay"
                    @change="setPayment(payment.id_pay)"
                    v-model="checkedPayments"
                  ></mcw-checkbox>
                </td>
                <td class="table__cell">{{payment.date_coming}}</td>
                <ExpandableCell style="font-size: 12px" :content="payment.name_pay" maxWidth="110"/>
                <td class="table__cell" style="text-align: right">{{showRank(payment.sum_pay)}}</td>
                <td class="table__cell table__cell_notselect">
                  <span
                    class="single-price"
                    v-for="(sum, i) in extractSums(payment.acts)"
                    :key="i"
                    v-tooltip="{ content: sum.author ? sum.author : 'Автор не указан', pos: 'left' }"
                    @click="setHighlightAct({ id: sum.id_act, idActPay: sum.id_act_pay })">
                    {{sum.sum_take}}
                    <br v-if="(i + 1) % 3 === 0">
                  </span>
                </td>
                <td class="table__cell">
                  <mcw-material-icon icon="check" class="icon_base" v-if="payment.done == 1"/>
                </td>
                <td class="table__cell">{{payment.org_doer}}</td>
            </tr>
          </tbody>
        </table>
  </div>
  <mcw-dialog
    v-model="showConfirm"
  >
  <mcw-dialog-title>В текущем договоре акт по данной оплате не найден</mcw-dialog-title>
  <mcw-dialog-content>
    <div>
      Хотите загрузить все акты?
    </div>
  </mcw-dialog-content>
  <mcw-dialog-footer>
    <mcw-dialog-button action="dismiss" isDefault>отмена</mcw-dialog-button>
    <mcw-dialog-button action="accept" @click="emitter.emit('loadAllActs')">ок</mcw-dialog-button>
  </mcw-dialog-footer>
</mcw-dialog>
</template>

<script>
import ExpandableCell from '../ExpandableCell.vue';
import IconFilter from '../IconFilter.vue';
import CommonTable from '../DataTables/CommonTable.vue';
import { transcriptColumnTitle } from '../../helpers/transcriptor';
import { utils } from '../../helpers/utils';

export default {
  name: 'Payments',
  extends: CommonTable,
  mixins: [
    utils,
  ],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    filterByAct: Object || null,
    loading: Boolean,
  },
  emits: [
    'highlightAct',
    'resetActFilter',
    'showButton',
    'refresh',
  ],
  components: {
    ExpandableCell,
    IconFilter,
  },
  data() {
    return {
      showDone: false,
      isRefreshing: false,
      selectedType: null,
      showMenu: false,
      showConfirm: false,
    };
  },
  computed: {
    checkedPayments: {
      get() {
        return this.$store.getters['contractsData/getCheckedPayments'];
      },
      set(val) {
        this.$store.commit('contractsData/SET_CHECKED_PAYMENTS', val);
      },
    },
    checkedActs() {
      return this.$store.getters['contractsData/getCheckedActs'];
    },
    visiblePayments() {
      if (this.selectedType.value === 1) {
        return this.items.filter((payment) => payment.done === 1);
      }
      if (this.selectedType.value === 2) {
        return this.items.filter((payment) => payment.done === 0);
      }
      return this.items;
    },
    doneFilter() {
      return [
        {
          name: 'Разнесенные',
          value: 1,
          icon: 'check_circle',
        },
        {
          name: 'Неразнесенные',
          value: 2,
          icon: 'unpublished',
        },
        {
          name: 'Все',
          value: 3,
          icon: 'rule',
        },
      ];
    },
    filteredPayments() {
      if (this.filterByAct.length) {
        return this.visiblePayments.filter((payment) => this.filterByAct.includes(payment.id_pay));
      }
      return this.visiblePayments;
    },
  },
  watch: {
    filterByAct() {
      if (this.filterByAct.length) {
        this.setDefaultFilter();
      }
    },
  },
  created() {
    this.setDefaultFilter();
    this.emitter.on('allActsLoaded', () => {
      const params = this.$store.state.contractsData.actHighlightParams;
      this.$nextTick(() => {
        this.setHighlightAct(params);
      });
    });
  },
  mounted() {
    if (this.checkedPayments.length) {
      this.scrollToActive(this.checkedPayments[0]);
    }
  },
  methods: {
    setHighlightAct(params) {
      const currentActs = this.$store.getters['contractsData/getActs'];
      const needleAct = currentActs.find((act) => act.id === params.id);
      if (needleAct) {
        this.$emit('highlightAct', params);
      } else {
        this.$store.commit('contractsData/SET_ACT_HIGHLIGHT_PARAMS', params);
        this.showConfirm = true;
      }
    },
    setDefaultFilter() {
      this.selectedType = this.doneFilter.at(-1);
    },
    onSelect(index) {
      this.selectedType = this.doneFilter[index];
    },
    async refresh() {
      this.isRefreshing = true;
      this.$emit('resetActFilter');
      this.$emit('refresh');
      await this.$store.dispatch('contractsData/getPaymentList');
      this.isRefreshing = false;
    },
    setPayment(idPay) {
      if (this.checkedPayments.length) {
        this.checkedPayments = [idPay];
        this.$emit('showButton', true);
      } else if (!this.checkedActs.length && !this.checkedPayments.length) {
        this.$emit('showButton', false);
      }
    },
    transcriptColumnTitle(str) {
      return transcriptColumnTitle(str);
    },
    extractSums(acts) {
      return JSON.parse(acts);
    },
  },
};
</script>

<style lang="scss">
  .payments th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .icon_rotate {
    animation-name: rotation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .table__cell_notselect {
    user-select: none;
  }

  @keyframes rotation {
    0% {
        transform:rotate(0deg);
    }
    100% {
        transform:rotate(360deg);
    }
  }
</style>
