<template>
  <div class="abb">
    <mcw-icon-button class="abb_button" :disabled="!possibleToBind" @click="clickHandle()">
      <mcw-material-icon v-if="!disabled" icon="compress" :class="possibleToBind ? 'icon_base' : 'icon_grey'"/>
    </mcw-icon-button>
    <input
      class="abb_sum"
      :style="inputWidth"
      v-model="bindingSum"
      @input="checkSum" />
  </div>
</template>

<script>
export default {
  name: 'ActsBindingButton',
  props: {
    sum: {
      type: Number,
      default: () => 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'bind',
    'unbind',
  ],
  data() {
    return {
      bindingSum: null,
      possibleToBind: true,
    };
  },
  computed: {
    inputWidth() {
      if (this.bindingSum) {
        return `width: ${(this.bindingSum.toString().length * 10) + 35}px`;
      }
      return 'width: 75px';
    },
  },
  created() {
    this.bindingSum = this.sum;
  },
  watch: {
    sum(val) {
      this.bindingSum = val;
    },
  },
  methods: {
    clickHandle() {
      if (!this.disabled) {
        this.$emit('bind', this.bindingSum);
      }
    },
    checkSum() {
      if (this.bindingSum > this.sum) {
        this.emitter.emit('message', `Сумма привязки не может быть больше ${this.sum}р.`);
        this.possibleToBind = false;
      } else {
        this.possibleToBind = true;
      }
    },
  },
};
</script>

<style lang="scss" src="./style.scss">

</style>
