import { mapActions, mapState } from 'vuex';
import Tree from '../Tree/Tree.vue';
import TableWrapper from '../DataTables/TableWrapper.vue';
import LoadPatients from '../LoadPatientsDialog.vue';
import EmployeesLists from '../EmployeesLists.vue';
import Spinner from '../Spinner.vue';
import { utils } from '../../helpers/utils';
import { resizer } from '../../helpers/resizerMixin';

export default {
  name: 'AppMain',
  mixins: [
    utils,
    resizer,
  ],
  components: {
    Tree,
    TableWrapper,
    LoadPatients,
    EmployeesLists,
    Spinner,
  },
  props: {
    fromContract: Boolean,
  },
  data() {
    return {
      testData: '',
      treeKey: 0,
      isCreateDialogOpen: false,
      newTree: {
        name: '',
        tin: '',
      },
      isMenuOpen: false,
      docList: [],
      isLoading: false,
      visibleTable: [],
      isShowNotice: false,
      notice: '',
      showStatus: false,
      tableAmount: null,
      nameContract: null,
      showListNameModal: false,
      comment: '',
      param: null,
    };
  },
  created() {
    this.emitter.on('tableAmount', this.setTableAmount);
    this.emitter.on('showParticularTable', this.showParticular);
    this.$store.subscribeAction({
      before: (action) => {
        if (action.type === 'employeesData/loadPatients') {
          this.isLoading = true;
        }
      },
      after: (action) => {
        if (action.type === 'employeesData/loadPatients') {
          this.isLoading = false;
        }
      },
    });
  },
  mounted() {
    this.$refs.resizer.addEventListener('mousedown', this.startResize);
    if (this.selectedCompany) {
      this.emitter.emit('setTreeOnLoad', true);
    }
    this.emitter.on('setTreeOnLoad', this.incrementKey);
    if (this.allPatients.length && !this.isPatientsLoaded) {
      this.visibleTable = [];
      this.visibleTable.push(3);
    }
  },
  beforeUnmount() {
    this.$store.dispatch('treeData/saveTreeState');
  },
  computed: {
    routeFromContract() {
      return this.$route.params.fromContract;
    },
    isMobile() {
      return this.$store.getters.deviceWidth < 1200;
    },
    selectedItem() {
      return this.$store.state.treeData.selectedItem;
    },
    patients() {
      return this.$store.getters['employeesData/getPatients'];
    },
    employees() {
      return this.$store.getters['employeesData/getEmployees'];
    },
    selectedCompany() {
      return this.$store.state.user.selectedCompany;
    },
    highLightTable() {
      return this.$store.state.employeesData.highLightTable;
    },
    structVerb() {
      return this.showStatus ? 'скрыть' : 'показать';
    },
    allPatients() {
      return this.$store.getters['employeesData/getAllPatients'];
    },
    isPatientsLoaded() {
      return this.$store.state.employeesData.isPatientsLoaded;
    },
    actLoaded() {
      return this.$store.state.employeesData.actLoaded;
    },
    datesRange() {
      return this.$store.state.employeesData.patientsDatesRange;
    },
    currentContractId() {
      return this.$store.getters['contractsData/getCurrentContract'] || 0;
    },
    isValid() {
      return !!this.comment.length;
    },
    ...mapState({
      currentContract: (state) => state.contractsData.currentContract,
    }),
  },
  watch: {
    employees(val) {
      if (val.length) {
        this.isShowNotice = false;
        if (this.visibleTable.includes(1)) {
          this.visibleTable = [1, 2];
        } else {
          this.visibleTable = [2];
        }
      }
    },
    patients() {
      if (this.selectedItem) {
        this.isShowNotice = false;
        if (this.visibleTable.includes(2)) {
          this.visibleTable = [1, 2];
        } else {
          this.visibleTable = [1];
        }
      }
    },
    allPatients(val) {
      if (val.length) {
        this.isShowNotice = false;
      }
      if (this.allPatients.length && !this.isPatientsLoaded) {
        this.visibleTable = [];
        this.visibleTable.push(3);
      }
      this.$store.commit('employeesData/SET_ACT_TO_LOADED', null);
    },
    selectedCompany() {
      this.showParticular();
    },
  },
  methods: {
    handleUpload({ json, param }) {
      this.json = json;
      this.param = param;
      this.showListNameModal = true;
    },
    async sendList() {
      if (!this.comment) {
        return;
      }
      const params = {
        txt: JSON.stringify(this.json),
        param: this.param,
        comment: this.comment,
        id_contract: this.currentContractId,
      };
      const result = await this.$store.dispatch('employeesData/postEmployeeJsonFromXlsx', params);
      const message = result ? 'Файл успешно загружен' : 'Не удалось загрузить файл';
      this.emitter.emit('message', message);
      this.json = null;
      this.param = null;
      this.comment = '';
      this.showListNameModal = false;
    },
    setTableAmount(qty) {
      this.tableAmount = qty;
    },
    incrementKey() {
      this.treeKey += 1;
    },
    async showAllPatients() {
      if (this.isLoading) return;
      this.isShowNotice = false;
      this.isLoading = true;
      this.$store.commit('contractsData/RESET_CONTRACT_DATA');
      await this.$store.dispatch('employeesData/getLastPatients', { includeServices: false });
      this.$store.commit('employeesData/IS_PATIENTS_LOADED', true);
      this.isLoading = false;
      this.visibleTable = [];
      this.visibleTable.push(3);
      if (this.allPatients.length === 0) {
        this.emptyTableNotice('Нет пациентов');
      }
    },
    async showDocList() {
      const docList = await this.$store.dispatch('employeesData/getDocList');
      if (docList.length) {
        this.docList = this.$store.state.employeesData.docList;
      } else {
        this.docList = [];
      }
      this.isMenuOpen = true;
    },
    async createTree() {
      const tree = await this.$store.dispatch('treeData/createNewTree', this.newTree);
    },
    async showQuestioned() {
      if (this.isLoading) return;
      this.isShowNotice = false;
      this.isLoading = true;
      const questioned = await this.$store.dispatch('employeesData/getQuestioned');
      this.isLoading = false;
      this.visibleTable = [];
      this.visibleTable.push(4);
      if (!questioned) {
        this.emitter.emit('message', `Нет анкетированных по ${this.selectedCompany.org}`);
        this.emptyTableNotice('Нет анкетированных');
      }
    },
    async showStaff(type) {
      this.isMenuOpen = false;
      if (typeof type === 'number') {
        this.nameContract = 'Непривязанные сотрудники';
        this.$store.dispatch('employeesData/getUploads', type);
      } else {
        this.isLoading = true;
        this.nameContract = type.name_contract;
        const staffList = await this.$store.dispatch('employeesData/getStaffList', type);
        if (staffList && staffList.body.length) {
          this.$store.commit('employeesData/SET_EMPLOYEES', staffList.body);
        }
        this.isLoading = false;
      }
    },
    showParticular() {
      switch (this.visibleTable[0]) {
        case 2: this.showStaff(); break;
        case 3: this.showAllPatients(); break;
        case 4: this.showQuestioned(); break;
        default: this.showAllPatients();
      }
    },
    emptyTableNotice(str) {
      this.isShowNotice = true;
      this.notice = `${str} по ${this.$store.state.user.selectedCompany.org} за ${this.datesRange.start} ~ ${this.datesRange.end}`;
    },
    getTree() {
      this.loadTree()
        .then(() => this.getMOTypes());
    },
    ...mapActions('treeData', ['getTreeAccessList', 'loadTree', 'getMOTypes']),
  },
};
