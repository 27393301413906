<template>
  <div class="edit-contract__payer">
    <mcw-textfield
      v-show="!isEdit"
      required
      type="text"
      :label="label"
      v-model="visibleData"
      outline
      disabled
    />
    <mcw-textfield
      v-show="isEdit"
      ref="payerField"
      v-model.trim="searchString"
      label="ИНН или ОГРН"
      type="text"
      required
      outline
    />
    <mcw-icon-button @click="toggleEditMode">
      <mcw-material-icon class="icon_base" icon="edit_note" />
    </mcw-icon-button>
  </div>
</template>

<script>
export default {
  name: 'EditableInput',
  props: {
    label: String,
    initialData: String,
  },
  // emits: [
  //   'changeContractOrg',
  // ],
  data() {
    return {
      isEdit: false,
      searchString: '',
      visibleData: '',
    };
  },
  created() {
    this.visibleData = this.initialData;
  },
  watch: {
    searchString() {
      if (this.searchString) {
        this.searchOrg();
      }
    },
    initialData() {
      this.visibleData = this.initialData;
    },
  },
  methods: {
    async searchOrg() {
      const org = await this.$store.dispatch('orgsData/getExistedOrg', { tin: this.searchString });
      if (!org) {
        this.emitter.emit('message', 'Не удалось найти организацию');
      } else {
        this.$emit('update:modelValue', org);
        this.searchString = '';
        this.isEdit = false;
      }
    },
    toggleEditMode() {
      this.isEdit = !this.isEdit;
      if (this.isEdit) {
        const input = this.$refs.payerField.root.querySelector('input');
        this.$nextTick(() => {
          input.focus();
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
