export const networkErrorsNotifier = {
  setup(cb, param) {
    this.cb = cb;
    this.param = param;
  },
  test() {
    console.log(this.cb);
    this.cb(this.param);
  },
};
