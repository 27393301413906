<template>
    <div class="responsiblings-editor__header">
      <h2 class="page-heading">Контакты по {{companyName}}</h2>
      <div class="responsiblings-actions">
      <mcw-select
        class="select_dense"
        outlined
        v-model="selectedType">
        <mcw-list-item
          v-for="type in types"
          :key="type.value"
          :data-value="type.value"
          role="option"
          icon>
          {{ type.label }}
        </mcw-list-item>
      </mcw-select>
      <mcw-button
        v-if="companyName"
        @click="addContact"
        raised>
        <mcw-material-icon
          icon="person_add"
          class="icon_white">
        </mcw-material-icon>
        {{headingText}}
      </mcw-button>
              <mcw-menu-anchor
                class="myAnchor">
                <mcw-button @click="isMenuOpen = true" :disabled="!currentContact">
                  <mcw-material-icon icon="manage_accounts"
                    :class="currentContact ? 'icon_base' : 'icon_grey'">
                  </mcw-material-icon>
                  Действия
                </mcw-button>
                <mcw-menu v-model="isMenuOpen">
                  <mcw-list-item
                    v-for="button of correctButtons"
                    :key="button.typeCorrect"
                    @click="correctContact(button.typeCorrect)"
                  >
                    {{ button.label }}
                  </mcw-list-item>
                </mcw-menu>
              </mcw-menu-anchor>
          <mcw-button
            :disabled="userRank < 50 || !currentContact || currentContact?.type !== 'e-mail'"
            @click="openLoginCreate(currentContact)">
            <mcw-material-icon
              icon="person_add_alt"
              class="icon_base">
            </mcw-material-icon>
              Создать учетку
            </mcw-button>
      <mcw-dialog v-model="isLoginCreateShow" class='login-create-modal'>
        <LoginCreate
          v-if="isLoginCreateShow"
          @close="isLoginCreateShow = false"
          :contact="{ ...currentContact.fullContactInfo, id_contact: currentContact.id }"
        />
      </mcw-dialog>
      </div>
    </div>
    <div style="overflow: auto" class="mdc-data-table--sticky-header">
      <table class="mdc-data-table__table" v-if="responsiblings.length">
        <thead>
          <tr class="mdc-data-table__header-row">
          <th class="mdc-data-table__header-cell fio-cell">
            {{transcriptColumnTitle('fio')}}
          </th>
          <th class="mdc-data-table__header-cell action-cell">
            {{transcriptColumnTitle('contact_value')}}
          </th>
          <th class="mdc-data-table__header-cell action-cell">
            {{transcriptColumnTitle('value_type')}}
          </th>
          <th class="mdc-data-table__header-cell action-cell">
            {{transcriptColumnTitle('tree_title')}}
          </th>
          <th class="mdc-data-table__header-cell action-cell">
            {{transcriptColumnTitle('corporate_text')}}
          </th>
          <th class="mdc-data-table__header-cell action-cell">
            {{transcriptColumnTitle('note')}}
          </th>
          <th class="mdc-data-table__header-cell action-cell">
            {{transcriptColumnTitle('name_login')}}
          </th>
          <th class="mdc-data-table__header-cell action-cell">
            {{transcriptColumnTitle('group_login')}}
          </th>
          </tr>
        </thead>
        <tbody>
          <SinglePerson
            v-for="item in responsiblings"
            :key="item.N"
            :fio="item.fio"
            :contacts="item.contacts || []"
            :accounts="item.accounts || []"
            :currentContact="currentContact"
            @selectContact="selectContact"
          />
        </tbody>
      </table>
    </div>
    <Spinner v-if="isLoading"/>
</template>

<script>
import LoginCreate from './LoginCreate.vue';
import Spinner from './Spinner.vue';
import SinglePerson from './SinglePerson.vue';
import { transcriptColumnTitle } from '../helpers/transcriptor';

export default {
  name: 'ResponsiblingsList',
  components: {
    LoginCreate,
    Spinner,
    SinglePerson,
  },
  data() {
    return {
      selectedType: '1',
      isMenuOpen: false,
      types: [
        {
          label: 'Активные',
          value: '1',
        },
        {
          label: 'Все',
          value: '2',
        },
        {
          label: 'Неактивные',
          value: '3',
        },
      ],
      showInvalid: false,
      isLoginCreateShow: false,
      currentContact: null,
    };
  },
  computed: {
    viewStyle() {
      return 'display: flex; height: calc(100vh - 187px)';
    },
    correctButtons() {
      return [
        {
          label: 'неправильный',
          typeCorrect: 1,
        },
        {
          label: 'устарел',
          typeCorrect: 2,
        },
        {
          label: 'чужой',
          typeCorrect: 3,
        },
        {
          label: 'сделать главным',
          typeCorrect: 4,
        },
      ];
    },
    responsiblings() {
      const responsiblings = this.$store.getters['contractsData/getResponsiblings'];
      return responsiblings.map((item) => {
        let contacts = [];
        let accounts = [];
        try {
          contacts = JSON.parse(item.contacts);
        } catch (error) {
          console.log(error);
        }
        try {
          accounts = JSON.parse(item.accounts);
        } catch (error) {
          console.log(error);
        }
        return { ...item, accounts, contacts };
      });
    },
    companyName() {
      return this.$store.getters['user/getSelectedCompanyName'];
    },
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },
    headingText() {
      return 'Создать контакт';
    },
    isLoading() {
      return !this.responsiblings;
    },
    accounts() {
      return this.responsiblings.map((item) => item.accounts);
    },
    userRank() {
      return this.$store.getters['user/getUserRank'];
    },
  },
  methods: {
    transcriptColumnTitle(column) {
      return transcriptColumnTitle(column);
    },
    selectContact(payload) {
      this.currentContact = payload;
    },
    onTypeChanged(nv) {
      this.selectedType = nv;
    },
    filterresponsiblings(val) {
      this.selectedType = val;
    },
    addContact() {
      this.emitter.emit('editContact', {});
    },
    openLoginCreate(contact) {
      this.isLoginCreateShow = true;
    },
    async correctContact(type) {
      const result = await this.$store.dispatch('contractsData/correctContact', {
        id_contact: this.currentContact.id, type_correct: type,
      });
      if (result) {
        this.currentContact = null;
      }
    },
  },
};
</script>

<style lang="scss">
@use "@material/switch/mdc-switch";
  .responsiblings-editor{
    &__header{
      padding-bottom: 10px;
      width: 100%;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (min-width: $desktop) {
        flex-direction: row;
        align-items: center;
      }
    }
  }
  .responsiblings-actions {
    display: flex;
  }
  .row{
    cursor: pointer;
    &_active{
      background: rgba(0, 0, 0, 0.08)!important;
    }
    &_main{
      font-weight: bold;
    }
    &_invalid{
      background: rgba(red, 0.08)!important;
    }
  }
  .cell{
    font-weight: inherit!important;
  }
  .login-create-modal .mdc-dialog__surface {
    max-width: 495px !important;
  }
  .sticky-head{
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
  }
</style>
