<template>
    <div class="editable" :style="customWidth">
      <span class="editable__value" v-show="!isEdit">
        {{editable}}
      </span>
      <input
        type="text"
        :style="customWidth"
        v-show="isEdit" class="editable__input"
        v-model="editable"
        @keyup.enter="save"
        @focusout="save"
        ref="input">
      <mcw-icon-button @click.stop="edit" class="editable__button" v-show="!isEdit" v-if='item.type_val !== 4'>
        <mcw-material-icon icon="edit"/>
      </mcw-icon-button>
      <mcw-icon-button @click.stop="save" class="editable__button" v-show="isEdit" >
        <mcw-material-icon icon="save"/>
      </mcw-icon-button>
    </div>
</template>

<script>
export default {
  name: 'EditableCell',
  props: {
    item: Object,
    editableField: String,
    width: String,
  },
  emits: [
    'saveEditableCell',
  ],
  data() {
    return {
      isEdit: false,
      editable: this.item[this.editableField],
    };
  },
  computed: {
    customWidth() {
      return `width: ${this.width}px`;
    },
  },
  watch: {
    item() {
      this.editable = this.item[this.editableField];
    },
  },
  mounted() {
    document.addEventListener('click', (ev) => {
      if (!this.$el.contains(ev.target)) {
        this.isEdit = false;
      }
    });
  },
  methods: {
    edit() {
      this.isEdit = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    save() {
      this.isEdit = false;
      const data = {
        newText: this.editable,
        item: this.item,
      };
      this.$emit('saveEditableCell', data);
    },
  },
};
</script>

<style lang="scss">
  .editable{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    position: relative;
    &__value{
      overflow: hidden;
      margin-right: 10px;
      text-overflow: ellipsis;
      // &:hover{
      //   overflow: visible;
      //   width: calc(100% - 33px);
      //   white-space: pre-wrap;
      //   background: #eee;
      //   position: absolute;
      //   top: 0;
      //   padding: 6px 4px 4px 4px;
      //   left: -3px;
      //   min-height: 36px;
      //   z-index: 100;
      // }
    }
    &__input{
      width: 50px;
      margin-right: 10px;
      outline: none;
    }
    &__button{
      // margin: 0 0 0 auto;
      padding: 0!important;
      width: 30px!important;
      height: 30px!important;
    }
  }
</style>
