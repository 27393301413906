const PaginationMixin = {
  data() {
    return {
      currentPage: 1,
      itemsPerPage: '20',
    };
  },
  computed: {
    pages() {
      const pagesLength = Math.ceil(this.filteredData.length / this.itemsPerPage);
      return Array.from({ length: pagesLength }, (_, i) => i + 1);
    },
    paginatedData() {
      return this.filteredData.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage);
    },
  },
  methods: {
    selectPage(page) {
      this.currentPage = page;
    },
    changeNumber(number) {
      this.itemsPerPage = number;
    },
  },
};

export default PaginationMixin;
