<template>
  <div class="search-select">
    <div
      :style="{width: titleWidth, height: titleHeight}"
      class="search-select__title"
      ref="select"
      @click="open"
      >
      <i
        v-if="!selectedItem"
        :class="{invisible : isOpen || Object.keys(initial).length > 1 }">
        {{placeholder}}
      </i>
      <span class="search-select__selected">{{!isOpen ? selectedItemName : ' '}}</span>
      <input
        v-if="isOpen"
        ref="input"
        type="text"
        class="search-select__searchfield"
        :placeholder="selectedItemName"
        v-model.trim="_searchString">
      <mcw-material-icon
        class="search-select__icon"
        ref="icon"
        :icon="isOpen ? 'arrow_drop_up' : 'arrow_drop_down'"
        @click.stop="toggle"
        />
    </div>
    <mcw-list
      v-show="isOpen"
      class="search-select__list"
      single-selection
      :style="{maxHeight: listHeight}"
      dense>
      <mcw-list-item
        tabindex="0"
        v-if="!visibleOptions.length">
        Ничего не найдено
      </mcw-list-item>
      <mcw-list-item
        :class="{
          highlighted: option.temp,
          long: calcSize(option.name)
        }"
        @click="select(option)"
        tabindex="0"
        v-for="(option, index) in this.visibleOptions"
        :key="index">
          <span>{{option[label]}}</span>
          <span v-if='option.temp' class='search-select__delete-temp-btn'>
            <mcw-button v-if='!this._searchString' @click.stop='removeTempOrg(option)'>УДАЛИТЬ</mcw-button>
          </span>
      </mcw-list-item>
    </mcw-list>
    <span v-if='this.options && showCounter' class='visible-options-counter'>({{this.options.length}})</span>
  </div>
</template>

<script src="./SearchSelect.js">

</script>

<style lang="scss" src="./SearchSelect.scss">

</style>
