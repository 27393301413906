import Api from '../helpers/Api';

export const treeData = {
  namespaced: true,
  state: {
    selectedItem: null,
    tree: null,
    itemState: [],
    resetId: null,
    draggedId: null,
    constantParams: {},
    accessList: [],
    currentRedactor: null,
    isTakeAccess: false,
    nodeHazards: [],
    hazards: [],
    treeByContract: [],
    flatTree: [],
    dragPrevent: false,
    highLightLeafs: false,
    MOTypes: null,
    treeIsLoading: false,
  },
  mutations: {
    DRAG_PREVENT(state, value) {
      state.dragPrevent = value;
    },
    LOAD_TREE(state, { tree, name }) {
      state.tree = null;
      state.flatTree = tree;
      const customTree = [];
      const rootId = tree.find((item) => item.id_parent === '0').id_tree;
      tree.forEach((item) => {
        const newItem = {
          id: +item.id_tree,
          parentId: +item.id_parent,
          text: item.title,
          type: item.id_type === 2 ? 'leaf' : 'node',
          people_amount: item.people_amount,
          order: item.pos_int,
          notDistributed: item.not_distributed,
          cnt_hazard: item.cnt_hazard,
          has_children: item.has_children,
        };
        if (Object.prototype.hasOwnProperty.call(item, 'isOpened')) { newItem.isOpened = item.isOpened; }
        customTree.push(newItem);
      });
      customTree.sort((a, b) => {
        let order = 0;
        if (a.parentId > b.parentId) {
          order = 1;
        } else if (a.parentId < b.parentId) {
          order = -1;
        }
        return order;
      });
      state.tree = {
        id: 0,
        companyName: name,
        increment: 0,
        children: customTree,
      };
    },
    LOAD_TREE_NODE(state, node) {
      state.flatTree = [...state.flatTree, ...node];
    },
    SET_TREE_LOADING(state, payload) {
      state.treeIsLoading = payload;
    },
    SET_OPEN(state, parentId) {
      const index = state.flatTree.findIndex((i) => i.id_tree === `${parentId}`);
      state.flatTree[index].isOpened = true;
    },
    LOAD_TREE_STATE(state, treeState) {
      state.itemState = treeState;
    },
    SELECT_ITEM(state, item) {
      state.selectedItem = item;
    },
    REMOVE_ITEM(state, data) {
      const index = state.tree.children.findIndex((item) => item.id === data.id);
      state.tree.children.splice(index, 1);
      state.selectedItem = null;
    },
    PASTE_ITEM(state, data) {
      const curItem = state.tree.children.find((item) => item.id === data.itemId);
      if (data.targetObj === 'root') {
        curItem.parentId = 0;
      } else {
        curItem.parentId = data.targetObj.id;
      }
    },
    MOVE_ITEM(state, data) {
      const curItem = state.tree.children.find((item) => item.id === data.itemId);
      curItem.parentId = data.targetObj.parentId;
      const targetIndex = state.tree.children.findIndex((item) => item.id === data.targetObj.id);
      const children = state.tree.children.filter((item) => item.parentId === curItem.parentId);
      children.forEach((item, index) => {
        if (index > targetIndex) {
          item.order += index;
        }
      });
      if (data.direction === 'over') {
        curItem.order = data.targetObj.order;
        data.targetObj.order += 1;
      } else {
        curItem.order = data.targetObj.order + 1;
      }
    },
    SET_ITEM_STATE(state, data) {
      if (data.state) {
        state.itemState.push(data.id);
      } else {
        const index = state.itemState.findIndex((item) => item === data.id);
        state.itemState.splice(index, 1);
      }
    },
    RESET_BLOCK(state, id) {
      state.resetId = id;
    },
    SAVE_ITEM(state, text) {
      state.selectedItem.data.text = text;
    },
    ADD_ITEM(state, data) {
      state.tree.children.push(data);
    },
    SAVE_DRAGGED_ID(state, id) {
      state.draggedId = id;
    },
    CHANGE_TYPE(state, id) {
      const node = state.tree.children.find((item) => item.id === id);
      if (node) {
        if (node.type === 'leaf') {
          node.type = 'node';
        } else {
          node.type = 'leaf';
        }
      }
    },
    SET_ACCESS_LIST(state, list) {
      state.accessList = list;
    },
    SET_CURRENT_REDACTOR(state, redactor) {
      state.currentRedactor = redactor;
    },
    TAKE_ACCESS(state, val) {
      state.isTakeAccess = val;
    },
    SET_TREE_BY_CONTRACT(state, tree) {
      state.treeByContract = tree;
    },
    SET_AMOUNT(state, id) {
      const node = state.tree.children.find((item) => item.id === id);
      if (node) {
        node.people_amount += 1;
      }
    },
    DECREASE_HAZARD_COUNTER(state, data) {
      const node = state.tree.children.find((item) => item.id === data.id);
      node.cnt_hazard -= data.amount;
    },
    INCREASE_HAZARD_COUNTER(state, data) {
      const node = state.tree.children.find((item) => item.id === data.id);
      node.cnt_hazard += data.amount;
    },
    SET_NODE_HAZARDS(state, data) {
      state.nodeHazards = data;
    },
    MO_TYPES(state, data) {
      state.MOTypes = data;
    },
    SET_HAZARDS(state, data) {
      const sortedHazards = data.sort((a, b) => {
        let result = 0;
        if (a.short_name > b.short_name) {
          result = 1;
        } else if (b.short_name > a.short_name) {
          result = -1;
        }
        return result;
      });
      sortedHazards.forEach((i) => {
        i.concatName = `${i.service_code} ${i.short_name}`;
      });
      state.hazards = sortedHazards;
    },
    REMOVE_HAZARD(state, id) {
      const index = state.nodeHazards.findIndex((item) => item.id_tree_hazard === id);
      state.nodeHazards.splice(index, 1);
    },
    ATTACH_HAZARD(state, data) {
      const attachedHazard = state.hazards.find((item) => item.id_hazard === data.id);
      // const index = state.hazards.findIndex((item) => item.id_hazard === data.id);
      const newHazard = Object.assign(attachedHazard, { id_tree_hazard: data.id_tree_hazard });
      state.nodeHazards.push(newHazard);
      // state.hazards.splice(index, 1);
    },
    DESTROY_TREE(state) {
      state.tree = null;
    },
    EMPTY_TREE(state) {
      state.tree = null;
    },
    HIGHLIGHT_LEAFS(state, value) {
      state.highLightLeafs = value;
    },
    SET_HAZARDS_TYPES(state, payload) {
      state.hazardsTypes = payload;
    },
  },
  actions: {
    checkPermissions() {
      return true;
    },
    async loadTree({
      state, dispatch, commit, rootState,
    }) {
      const companyId = rootState.user.selectedCompany.id_org;
      const data = { ...rootState.user.constantParams };
      // data.Name_event = 'GET:tree';
      data.Name_event = 'GET:tree_v2';
      data.id_org = companyId;
      const tree = await Api.get(data);
      if (tree.body.length && !tree.body[0].error) {
        commit('LOAD_TREE', { tree: tree.body, name: rootState.user.selectedCompany.org });
        dispatch('getTreeAccessList');
        const key = state.tree.children.find((item) => item.parentId === 0).id;
        const treeState = JSON.parse(localStorage.getItem(`tree_state_${key}`));
        if (treeState) {
          commit('LOAD_TREE_STATE', treeState);
        }
      } else {
        commit('EMPTY_TREE');
      }
    },
    async loadTreeNode({ state, commit, rootState }, idTree) {
      commit('SET_TREE_LOADING', true);
      commit('SET_OPEN', idTree);
      const companyId = rootState.user.selectedCompany.id_org;
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:tree_v2';
      data.id_org = companyId;
      data.id_tree = idTree;
      const node = await Api.get(data);
      if (node.body.length && !node.body[0].error) {
        commit('LOAD_TREE_NODE', node.body);
        commit('LOAD_TREE', { tree: state.flatTree, name: rootState.user.selectedCompany.org });
      }
      commit('SET_TREE_LOADING', false);
    },
    saveTreeState({ state }) {
      if (state.tree) {
        const key = state.tree.children.find((item) => item.parentId === 0).id;
        if (key) {
          localStorage.setItem(`tree_state_${key}`, JSON.stringify(state.itemState));
        }
      }
    },
    async pasteItem({ state, commit, rootState }, pasteData) {
      if (state.currentRedactor.id_people !== rootState.user.user.id_people) {
        commit('TAKE_ACCESS', true);
        return;
      }
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:tree_node_sub_v2';
      data.id_tree_drag = pasteData.itemId;
      data.id_tree_drop = pasteData.targetObj.id;
      if (data.id_tree_drag === data.id_tree_drop) {
        return;
      }
      data.type_drop = 0;
      const paste = await Api.get(data);
      if (paste.errorType === 0) {
        commit('PASTE_ITEM', pasteData);
      }
    },
    async moveItem({ state, commit, rootState }, moveData) {
      if (state.currentRedactor.id_people !== rootState.user.user.id_people) {
        commit('TAKE_ACCESS', true);
        return;
      }
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:tree_node_move_v2';
      data.id_tree_drag = moveData.itemId;
      data.id_tree_drop = moveData.targetObj.id;
      data.type_drop = moveData.direction === 'under' ? -1 : 1;
      const move = await Api.get(data);
      if (move.errorType === 0) {
        commit('MOVE_ITEM', moveData);
      }
    },
    async addItem({ state, commit, rootState }, item) {
      if (state.currentRedactor.id_people !== rootState.user.user.id_people) {
        commit('TAKE_ACCESS', true);
        return;
      }
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:node_create_v2';
      // data.Name_event = 'POST:node_create';
      data.text = item.name;
      data.id_tree = state.selectedItem.data.id;
      data.type = item.type === 'leaf' ? 2 : 1;

      const create = await Api.post(data, true);
      if (create) {
        const itemData = {
          type: create.body[0].id_type === 1 ? 'node' : 'leaf',
          text: create.body[0].title,
          id: +create.body[0].id_tree,
          parentId: +create.body[0].id_parent,
          people_amount: 0,
          order: 0,
        };
        if (create.body[0].id_type === 2) {
          itemData.cnt_hazard = 0;
        }
        commit('ADD_ITEM', itemData);
        return true;
      }
      return false;
    },
    async editItem({ state, commit, rootState }, text) {
      if (state.currentRedactor.id_people !== rootState.user.user.id_people) {
        commit('TAKE_ACCESS', true);
        return;
      }
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:node_rename';
      data.text = text;
      data.id_tree = state.selectedItem.data.id;
      const rename = await Api.post(data, true);
      if (rename) {
        commit('SAVE_ITEM', text);
        return true;
      }
      return false;
    },
    async removeItem({ state, commit, rootState }, item) {
      if (state.currentRedactor.id_people !== rootState.user.user.id_people) {
        commit('TAKE_ACCESS', true);
        return;
      }
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:node_del_v2';
      data.id_tree = item.id;
      const remove = await Api.post(data, true);
      if (remove) {
        commit('REMOVE_ITEM', item);
        return true;
      }
      return false;
    },
    async createNewTree({ rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:new_tree';
      data.id_org = rootState.user.selectedCompany.id_org;
      const newTree = await Api.post(data, true);
      if (!newTree.errorType) {
        return newTree;
      }
      return false;
    },
    setItemState({ commit, dispatch }, data) {
      commit('SET_ITEM_STATE', data);
      dispatch('saveTreeState');
    },
    async changeType({ state, commit, rootState }, id) {
      if (state.currentRedactor.id_people !== rootState.user.user.id_people) {
        commit('TAKE_ACCESS', true);
        return;
      }
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:node_retype';
      data.id_tree = id;
      const retype = await Api.post(data, true);
      if (retype) {
        commit('CHANGE_TYPE', id);
      }
    },
    async getTreeAccessList({ state, commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:tree_access_list';
      data.id_org = rootState.user.selectedCompany.id_org;
      const list = await Api.get(data);
      commit('SET_ACCESS_LIST', list.body);
      let redactor = state.accessList.find((item) => item.time_start !== '');
      if (!redactor) {
        redactor = state.accessList.find((item) => item.id_people === rootState.user.user.id_people);
      }
      commit('SET_CURRENT_REDACTOR', redactor);
    },
    async setTreeAccess({ dispatch, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:tree_access_take';
      data.id_org = rootState.user.selectedCompany.id_org;
      await Api.post(data, true);
      dispatch('getTreeAccessList');
    },
    async getNodeHazards({ state, commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:hazard_node';
      data.id_tree = state.selectedItem.data.id;
      const hazards = await Api.get(data);
      if (hazards.errorType === 0) {
        commit('SET_NODE_HAZARDS', hazards.body);
      }
    },
    async showHazardsCatalog({ state, commit, rootState }, instr) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:hazard_catalogue_param';
      data.id_tree = state.selectedItem.data.id;
      data.legal_instrument = instr;
      const hazards = await Api.get(data);
      if (hazards.errorType === 0) {
        commit('SET_HAZARDS', hazards.body);
        return true;
      }
      return false;
    },
    async getHazardsTypes({ state, commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:hazards_types_spr';
      data.Name_module = 'module_patients';
      const types = await Api.get(data);
      if (types.errorType === 0) {
        commit('SET_HAZARDS_TYPES', types.body);
        return true;
      }
      return false;
    },
    async attachHazard({ state, commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:hazard_add_node';
      data.id_tree = state.selectedItem.data.id;
      data.id_hazard = id;
      const attach = await Api.post(data, true);
      if (attach.errorType === 0) {
        const newHazard = attach.body[0].id_tree_hazard;
        commit('ATTACH_HAZARD', { id, id_tree_hazard: newHazard });
      }
    },
    async removeHazard({ commit, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:hazard_del_node';
      data.id_tree_hazard = id;
      const remove = await Api.post(data, true);
      if (remove.errorType === 0) {
        commit('REMOVE_HAZARD', id);
      }
    },
    destroyTree({ commit }) {
      commit('DESTROY_TREE');
    },
    // async removeTree({ rootState }) {
    //   const data = { ...rootState.user.constantParams };
    //   data.Name_event = 'POST:delete_tree';
    //   data.id_org = rootState.user.selectedCompany.id_org;
    //   const remove = await Api.post(data, true);
    // },
    async getNotDistributedNode({ commit, rootState }) {
      const companyId = rootState.user.selectedCompany.id_org;
      const data = { ...rootState.user.constantParams };
      data.id_org = companyId;
      data.Name_event = 'GET:tree';
      data.text = 'нераспределенные';
      const result = await Api.get(data);
      if (result) {
        // return result.body[0];
        const newItem = {
          type: 'leaf',
          text: result.body[0].title,
          id: +result.body[0].id_tree,
          parentId: +result.body[0].id_parent,
          people_amount: 1,
          order: 1,
        };
        commit('ADD_ITEM', newItem);
        return true;
      }
      return false;
    },
    async getMOTypes({ commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = 'module_patients';
      data.Name_event = 'GET:type_MO_spr';
      data.id_org = rootState.user.selectedCompany.id_org;
      const mo = await Api.get(data);
      if (mo.errorType === 0) {
        commit('MO_TYPES', mo.body);
      }
    },
    async addPatient({ dispatch, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = 'module_patients';
      data.Name_event = 'POST:patient';
      // insert by Anton's request, I don't know why
      params.gen_napravl = 1;
      data.str_params = JSON.stringify(params);
      const patient = await Api.post(data, true);
      if (patient.errorType === 0) {
        // await dispatch('employeesData/getNapravl', patient.body[0].id_patient, { root: true });
        // await dispatch('employeesData/loadPatients', patient.body[0].id_patient, { root: true });
        return true;
      }
      return false;
    },

    async delPatient({ dispatch, rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = 'module_patients';
      data.Name_event = 'POST:patient_del';
      data.id_patient = id;
      const patient = await Api.post(data, true);
      if (patient.errorType === 0) {
        if (patient.body[0].err_text) {
          return false;
        }
        await dispatch('employeesData/getLastPatients', { includeServices: false }, { root: true });
        return true;
      }
    },

    async cloneNode({ dispatch, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:tree_clone';
      data.id_tree = params.id_tree;
      data.text = params.text;
      const patient = await Api.post(data, true);
      if (patient.errorType === 0) {
        if (patient.body[0].err_text) {
          return false;
        }
        return true;
      }
    },
    async getTreeByContract({ commit, rootState }) {
      const contractID = rootState.contractsData.currentContract;
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:tree_by_contract';
      data.id_contract = contractID;
      const tree = await Api.post(data, true);
      if (tree.errorType === 0) {
        if (tree.body[0].err_text) {
          return false;
        }
        commit('SET_TREE_BY_CONTRACT', tree.body);
        return true;
      }
    },
    async bindTreeToContract({ dispatch, rootState }, params) {
      const contractID = rootState.contractsData.currentContract;
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:tree_to_contract';
      data.id_contract = contractID;
      data.json_str = JSON.stringify([{ id_tree: params.id_tree }]);
      data.delete = params.del;
      const patient = await Api.post(data, true);
      if (patient.errorType === 0) {
        dispatch('getTreeByContract');
        return true;
      }
      return false;
    },
  },
  getters: {
    getSelectedItemType(state) {
      if (state.selectedItem) {
        return state.selectedItem.data.type;
      }
      return null;
    },
    getTargetObj: (state) => (id) => state.tree.children.find((item) => item.id === id),
    getData(state) {
      if (state.tree !== null) {
        return state.tree.children;
      }
      return false;
    },
    getToggleState: (state) => (id) => {
      if (state.itemState.includes(id)) {
        return true;
      }
      return false;
    },
    getItemById: (state) => (id) => state.tree.children.find((item) => item.id === id),
    getPositions(state) {
      if (state.tree && state.tree.children.length) {
        return state.tree.children.filter((item) => item.type === 'leaf');
      }
      return [];
    },
    getRoot(state) {
      if (state.tree !== null) {
        return state.tree.children.find((item) => item.parentId === 0);
      }
      return false;
    },
    getAccessList(state) {
      return state.accessList;
    },
    getCurrentRedactor(state) {
      return state.currentRedactor;
    },
    getPeopleAmountById: (state) => (id) => {
      const node = state.tree.children.find((item) => item.id === id);
      if (node) {
        return node.people_amount;
      }
      return 0;
    },
    getHazardAmountById: (state) => (id) => {
      const node = state.tree.children.find((item) => item.id === id);
      if (node) {
        return node.cnt_hazard;
      }
      return 0;
    },
    getRootId(state) {
      if (state.tree) {
        return state.tree.id;
      }
      return false;
    },
    getHazardList(state) {
      return state.hazards;
    },
    getHazardTypesList(state) {
      return state.hazardsTypes;
    },
    getTreeByContract(state) {
      return state.treeByContract;
    },
  },
};
