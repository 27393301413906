export function sortTableArr(arr, transformMap) {
  if (!arr) {
    return;
  }

  transformMap.forEach((elem) => {
    const index = arr.findIndex((item) => item === elem.title || item[0] === elem.title);
    if (index !== -1) {
      const element = arr.splice(index, 1);
      arr.splice(elem.newIndex, 0, element[0]);
    }
  });

  return arr;
}
