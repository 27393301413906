<template>
  <div class="add-employee">
    <mcw-dialog-title>
      {{title}}
    </mcw-dialog-title>
    <mcw-dialog-content>
      <div class="mdc-typography--headline6">
        Подразделение: {{divisionName}}
      </div>
      <div class="mdc-typography--headline6">
        Должность: {{positionName}}
      </div>
        <mcw-textfield
          v-model="fields.tabNumber"
          label="Табельный номер"
          required
        ></mcw-textfield>
        <mcw-textfield
          v-model="fields.lastName"
          label="Фамилия"
          required
        ></mcw-textfield>
        <mcw-textfield
          v-model="fields.firstName"
          label="Имя"
          required
        ></mcw-textfield>
        <mcw-textfield
          v-model="fields.patrName"
          label="Отчество"
          required
        ></mcw-textfield>
        <div class="radio-wrapper">
          <mcw-radio
            id="male"
            v-model="fields.gender"
            label="М"
            name="gender"
            value="м"
          ></mcw-radio>
          <mcw-radio
            id="female"
            v-model="fields.gender"
            label="Ж"
            name="gender"
            value="ж"
          ></mcw-radio>
        </div>
      </mcw-dialog-content>
      <mcw-dialog-footer>
        <mcw-dialog-button
          @click="saveEmployee"
          @keyup.enter="saveEmployee">
          сохранить
        </mcw-dialog-button>
        <mcw-dialog-button isDefault action="dismiss">отмена</mcw-dialog-button>
      </mcw-dialog-footer>
  </div>
</template>

<script>
export default {
  name: 'EditEmployee',
  emits: ['finishEditing'],
  props: {
    title: String,
    division: Number,
    position: Number,
    employee: Object,
    moveId: Number,
  },
  data() {
    return {
      fields: {
        gender: null,
        firstName: '',
        lastName: '',
        patrName: '',
        tabNumber: '',
      },
    };
  },
  mounted() {
    if (typeof this.employee !== 'undefined') {
      const fullName = this.employee.fio.split(' ');
      this.fields = {
        gender: this.employee.sex,
        lastName: fullName[0],
        firstName: fullName[1],
        patrName: fullName[2],
        tabNumber: this.employee.tab_number || '',
      };
    }
  },
  computed: {
    divisionName() {
      return this.$store.getters['treeData/getItemById'](this.division).text;
    },
    positionName() {
      return this.$store.getters['treeData/getItemById'](this.position).text;
    },
  },
  methods: {
    saveEmployee() {
      const data = { ...this.employee };
      data.fio = `${this.fields.lastName} ${this.fields.firstName} ${this.fields.patrName}`;
      data.tab_number = this.fields.tabNumber;
      data.sex = this.fields.gender;
      this.$store.dispatch('employeesData/saveEmployee', data);
      this.reset();
      this.$emit('finishEditing');
    },
    reset() {
      Object.keys(this.fields).forEach((key) => {
        this.fields[key] = '';
      });
    },
  },
};
</script>

<style lang="scss">
@use "@material/textfield/mdc-text-field";
@use "@material/button/mdc-button";
@use "@material/radio/mdc-radio";
.add-employee{
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__actions{
    display: flex;
  }
}
.radio-wrapper{
  display: flex;
  justify-content: space-evenly;
}
</style>
