<template>
  <div class="available-contracts">
    <SearchSelect
      placeholder="Договор"
      label="label"
      :options="availableContracts"
      :initial="{}"
      :addButton="false"
      :showCounter='false'
      @selectItem="onSelect"
    />
    <!-- <mcw-select
      class="select_dense"
      v-model="currentContract"
      outlined
      label="Договор"
      :value="currentContract"
      @update:modelValue="onSelect"
    >
      <mcw-list-item
        v-for="(contract, i) in availableContracts"
        :key="i"
        :data-value="contract.id_contract"
      >
        <span>{{ contract.name_contract }}</span>
      </mcw-list-item>
      <mcw-button @click.stop="loadMore">
        загрузить все
      </mcw-button>
    </mcw-select> -->
  </div>
</template>

<script>
import SearchSelect from './SearchSelect/SearchSelect.vue';

export default {
  name: 'AvailableContracts',
  emits: [
    'selectContract',
  ],
  components: {
    SearchSelect,
  },
  data() {
    return {
      // хз почему но с пустым массивом падает, НЕ УДАЛЯТЬ!
      availableContracts: [
        {
          N_contract: '',
          id_contract: 0,
          name_contract: '',
          label: '',
        },
      ],
      currentContract: null,
    };
  },
  computed: {
    selectedItem() {
      return this.$store.state.treeData.selectedItem;
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData(type = null) {
      const params = {
        id_tree: this.selectedItem.data.id,
      };
      if (type) {
        params.show_all = 1;
      }
      this.availableContracts = await this.$store.dispatch('contractsData/getContractsInTree', params) || [];
      if (this.availableContracts.length) {
        this.availableContracts.forEach((item) => {
          item.label = `${item.id_contract} | ${item.name_contract}`;
        });
      }
      if (this.availableContracts.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.currentContract = this.availableContracts[0];
        this.onSelect(this.currentContract);
      }
    },
    onSelect(contract) {
      this.$emit('selectContract', contract);
    },
    loadMore() {
      this.loadData('all');
    },
  },
};
</script>

<style>

</style>
