import { mapState, mapMutations } from 'vuex';
import { debounce } from 'debounce';
import { utils } from '../../helpers/utils';

export default {
  name: 'SearchSelect',
  mixins: [
    utils,
  ],
  props: {
    options: Array,
    needles: {
      type: Array,
      default: () => [],
    },
    label: String,
    universalMode: {
      type: Boolean,
      default: () => false,
    },
    opened: Boolean,
    initial: Object,
    placeholder: String,
    titleWidth: String,
    titleHeight: String,
    backendSearchEvent: String,
    showCounter: Boolean,
  },
  emits: ['filter', 'selectItem'],
  data() {
    return {
      selectedItem: this.initial ? this.initial[this.label] : null,
      isOpen: this.opened,
      searchString: '',
      visibleOptions: [],
      rerender: false,
      listHeight: '',
    };
  },
  computed: {
    selectedItemName() {
      return this.selectedItem ? this.selectedItem[this.label] : this.initial[this.label];
    },
    _searchString: {
      get() { return this.searchString; },
      set: debounce(function (val) {
        this.searchString = val;
        this.search(val);
      }, 500),
    },
    ...mapState({
      companiesList: (state) => state.user.companiesList,
    }),
  },
  watch: {
    initial(val) {
      this.reset();
    },
    options(val) {
      this.$nextTick(() => {
        this.visibleOptions = this.options.slice();
      });
    },
    companiesList(arr) {
      if (arr) {
        this.rerenderFunc();
      }
    },
    searchString(val) {
      if (!val) {
        this.setListHeight();
        this.visibleOptions = this.options.slice();
        this.rerenderFunc();
      }
    },
    isOpen() {
      this.visibleOptions = this.options.slice();
    },
  },
  mounted() {
    this.visibleOptions = this.options.slice();
    if (this.options.length === 1) {
      [this.selectedItem] = this.options;
    }
    document.addEventListener('click', (ev) => {
      if (!this.$el.contains(ev.target)) {
        this.close();
      }
    });
    this.setListHeight();
  },
  methods: {
    calcSize(label) {
      if (label) return label.length > 30;
    },
    setListHeight() {
      if (this.$refs.select) {
        const titleBottomPoint = this.$refs.select.getBoundingClientRect().bottom;
        const maxListHeight = document.documentElement.clientHeight - 100 - titleBottomPoint;
        this.listHeight = `${maxListHeight}px`;
      }
      return false;
    },
    open() {
      if (!this.isOpen) {
        this.isOpen = true;
      }
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    close() {
      if (this.isOpen) {
        this.isOpen = false;
      }
      this._searchString = '';
    },
    search() {
      this.$emit('filter', this.searchString);
      this.visibleOptions = this.options.slice();
      if (this._searchString.length >= 3 && this.backendSearchEvent) {
        this.searchFromBackend.call(this);
      }
      if (this.universalMode) {
        this.visibleOptions = this.arrayUnique(
          this.visibleOptions.filter((option) => {
            const entries = Object.entries(option);
            for (let i = 0; i < entries.length; i += 1) {
              const entry = entries[i];
              if (this.needles.includes(entry[0])) {
                return entry[1].toString().toLowerCase().includes(this._searchString);
              }
            }
            return false;
          }),
          this.findParamForFiltering(),
        );
      } else {
        this.visibleOptions = this.arrayUnique(
          this.visibleOptions.filter((item) => item[this.label].toLowerCase().includes(this._searchString)),
          this.findParamForFiltering(),
        );
      }
    },
    findParamForFiltering() {
      if (this.visibleOptions.length) {
        const element = this.visibleOptions[0];
        const arr = Object.keys(element);
        for (let i = 0; i < arr.length; i += 1) {
          if (arr[i].includes('id')) return arr[i];
        }
        return 'id';
      }
    },
    searchFromBackend() {
      const backendSearch = this.$store.dispatch('user/getAdditionalCompanies', this._searchString);
      backendSearch.then((res) => res.forEach((item) => {
        const orgExists = this.options.find((option) => option.id_org === item.id_org);
        if (!orgExists) {
          item.temp = true;
          this.visibleOptions.push(item);
        }
      }));
    },
    select(option) {
      if (option.temp && !this.companiesList.find((i) => i.id_org === option.id_org)) {
        this.$store.dispatch('user/addOrgIntoOrgsList', option.id_org)
          .then(() => {
            if (!this.companiesList.find((i) => i.id_org === option.id_org)) {
              this.selectedItem = null;
            } else {
              this.selectedItem = option;
              this.SET_NEW_COMPANY(option);
            }
          });
      }
      this.selectedItem = option;
      this.isOpen = false;
      this._searchString = '';
      this.$emit('selectItem', option);
    },
    removeTempOrg(option) {
      this.$store.dispatch('user/addOrgIntoOrgsList', option.id_org);
      this.selectedItem = null;
      this.SET_SELECTED_COMPANY(null);
    },
    toggle() {
      this.setListHeight();
      // eslint-disable-next-line no-unused-expressions
      this.isOpen ? this.close() : this.open();
    },
    reset() {
      this.selectedItem = null;
    },
    rerenderFunc() {
      this.rerender = !this.rerender;
      this.$nextTick(() => {
        this.rerender = !this.rerender;
        if (this.isOpen) this.open();
      });
    },
    ...mapMutations('user', ['SET_NEW_COMPANY', 'SET_SELECTED_COMPANY']),
  },
};
