<template>
  <div class="contacts-view view">
    <ResponsiblingsList />
  </div>
</template>

<script>
import ResponsiblingsList from '../components/ResponsiblingsList.vue';

export default {
  name: 'ContactsView',
  components: {
    ResponsiblingsList,
  },
};
</script>

<style>
.contacts-view {
  grid-template-rows: auto 1fr;
}
</style>
