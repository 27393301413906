<template>
  <section>
    <mcw-dialog-title>
      Редактирование {{ patient.fio }}
      <mcw-icon-button class="btn_close" @click="close">
        <mcw-material-icon icon="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content>
        <div class="form-group">
          <mcw-textfield
            v-model="form.people_lname"
            label="Фамилия"
            outline/>
          <mcw-textfield
            v-model="form.people_fname"
            required
            label="Имя"
            outline />
        </div>
        <div class="form-group">
          <mcw-textfield
            v-model="form.people_mname"
            label="Отчество"
            outline />
          <mcw-textfield
            ref='bdInput'
            v-model="form.people_bd"
            label="Дата рождения"
            v-date='true'
            outline />
        </div>
        <div class="form-group form-group_centered">
          <span>Пол:</span>
          <mcw-radio
            v-model="form.people_sex"
            label="м"
            name="gender"
            value="м"
          ></mcw-radio>
          <mcw-radio
            v-model="form.people_sex"
            label="ж"
            name="gender"
            value="ж"
          ></mcw-radio>
        </div>
        <mcw-dialog-button
        @click="save"
        :disabled="canISave"
        >Сохранить</mcw-dialog-button>
    </mcw-dialog-content>
  </section>
</template>

<script>
import moment from 'moment';

export default {
  name: 'EditPatientDialog',
  props: {
    patient: Object,
  },
  emites: ['stopEditPatient'],
  data() {
    return {
      form: {
        people_lname: '',
        people_fname: '',
        people_mname: '',
        people_bd: '',
        people_sex: '',
      },
      dateValid: null,
    };
  },
  computed: {
    canISave() {
      const dt = moment().diff(moment(this.form.people_bd, 'DD-MM-YYYY'), 'years', false);
      this.dateValid = null;
      if (!Number.isNaN(dt) && dt > 17 && dt < 120) {
        this.dateValid = true;
      } else this.dateValid = false;
      if (!this.dateValid) return true;
      if (!this.form.people_fname && !this.form.people_lname) return true;
      return false;
    },
  },
  mounted() {
    this.form.people_lname = this.patient.lastname;
    this.form.people_fname = this.patient.firstname;
    this.form.people_mname = this.patient.middlename;
    this.form.people_bd = this.patient.datebirth;
    this.form.people_sex = this.patient.sex;
  },
  methods: {
    async save() {
      const params = {
        lastname: this.form.people_lname,
        firstname: this.form.people_fname,
        middlename: this.form.people_mname,
        sex: this.form.people_sex,
        bd: this.form.people_bd,
        id_patient: this.patient.id_patient,
      };
      if (await this.$store.dispatch('employeesData/editPatient', params)) {
        this.$store.dispatch('employeesData/getLastPatients', { includeServices: false });
      }
      this.close();
    },
    close() {
      this.emitter.emit('stopEditPatient');
    },
  },
};
</script>
