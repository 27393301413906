<template>
<section class="tree-section">
  <div class="tree-actions">
  <mcw-button
    @click="selectedItem.data.type === 'leaf' ? tryToEdit('openAddPatientDialog') : tryToEdit('openAddDialog')"
    :disabled="!selectedItem">
    <mcw-material-icon icon="add"></mcw-material-icon>
    Добавить
  </mcw-button>
  <mcw-button @click="tryToEdit('openRemoveDialog')" :disabled="!isEditable">
    <mcw-material-icon icon="delete_forever"></mcw-material-icon>
    Удалить
  </mcw-button>
  <mcw-button @click="tryToEdit('openEditDialog')" :disabled="!isEditable">
    <mcw-material-icon icon="edit"></mcw-material-icon>
    редактировать
  </mcw-button>
  <mcw-button @click="tryToEdit('changeNodeType')" :disabled="!isEditable">
    <mcw-material-icon icon="loop"></mcw-material-icon>
    сменить тип
  </mcw-button>
  <mcw-button
    @click="showHazards"
    :disabled="!selectedItem || selectedItem.data.type !== 'leaf'">
    <mcw-material-icon icon="notification_important"></mcw-material-icon>
    факторы
  </mcw-button>
  <mcw-button
    @click="tryToEdit('showCloningDialog')"
    :disabled="!selectedItem || selectedItem.data.type !== 'node'">
    <mcw-material-icon icon="business"></mcw-material-icon>
    клонировать
  </mcw-button>
  <mcw-dialog
    v-model="isEditOpen"
    escape-key-action="close"
    scrim-click-action="close"
    :auto-stack-buttons="true">
    <mcw-dialog-title>Изменение</mcw-dialog-title>
    <mcw-dialog-content>
      <mcw-textfield v-model="text" outline required/>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button @click="saveItem">ок</mcw-dialog-button>
      <mcw-dialog-button isDefault action="dismiss">отмена</mcw-dialog-button>
    </mcw-dialog-footer>
  </mcw-dialog>
  <mcw-dialog
    v-model="isRemoveOpen"
    escape-key-action="close"
    scrim-click-action="close"
    :auto-stack-buttons="true">
      <mcw-dialog-title>Удаление</mcw-dialog-title>
      <mcw-dialog-content>
        Удалить {{removeName}}?
      </mcw-dialog-content>
      <mcw-dialog-footer>
        <mcw-dialog-button @click="removeItem">удалить</mcw-dialog-button>
        <mcw-dialog-button isDefault action="dismiss">отмена</mcw-dialog-button>
      </mcw-dialog-footer>
    </mcw-dialog>
    <mcw-dialog
      v-model="isAddOpen"
      escape-key-action="close"
      scrim-click-action="close"
      :auto-stack-buttons="true">
      <mcw-dialog-title>Новый элемент</mcw-dialog-title>
        <mcw-dialog-content>
          <mdc-select
            v-model="newItem.type"
            label="Тип"
            outlined
            required
            leading-icon="switch_account">
            <mdc-list>
            <mdc-list-item
              v-for="type in types"
              :key="type.type"
              :data-value="type.type"
              role="option">
              {{ type.name }}
            </mdc-list-item>
            </mdc-list>
          </mdc-select>
        <mcw-textfield
          v-model="newItem.name"
          label="Название"
          outline
          required/>
      </mcw-dialog-content>
      <mcw-dialog-footer>
        <mcw-dialog-button @click="addItem">ок</mcw-dialog-button>
        <mcw-dialog-button isDefault action="dismiss">отмена</mcw-dialog-button>
      </mcw-dialog-footer>
    </mcw-dialog>
        <mcw-dialog
          v-model="isAddPatientOpen"
          escape-key-action="close"
          scrim-click-action=""
          :auto-stack-buttons="true">
          <AddPatient
            v-if="isAddPatientOpen"
            :selectedItem="selectedItem"
            :MOTypes="MOTypes"
            @close="isAddPatientOpen=false"/>
    </mcw-dialog>
    <mcw-dialog v-model="isAccessDialogOpen">
      <component :is="dialogComponent"></component>
    </mcw-dialog>
    <mcw-dialog
      v-model="isHazardDialogOpen"
      class="big-modal">
      <HazardEditor
        :hazardsLoading="hazardsLoading"
        ref="hazardEditor"
        @close="isHazardDialogOpen = false"/>
    </mcw-dialog>
    <mcw-dialog
      v-model="isCloningDialogOpen">
      <CloningNode
        v-if='selectedItem'
        ref="cloningDialog"
        :selectedNode='selectedItem.data'
        @close="isCloningDialogOpen = false"/>
    </mcw-dialog>
  </div>
  <div class="tree-container" ref="container" @click="clearSelection($event)">
    <Spinner v-show="treeIsLoading"/>
    <mcw-list class="tree" v-show="!isEmptyTree">
        <tree-item
          class="item"
          :item="tree._root"
          :filterterm="filterterm"
          icon="home"
          @keyup.delete="openRemoveDialog"
        ></tree-item>
      </mcw-list>
      <div class="tree__message"
        v-if="isEmptyTree"
        v-show="isSelectedCompany && !isLoading">
        <mcw-button @click="createTree" class="tree__add-btn">
          <mcw-material-icon icon="add"></mcw-material-icon>
          Создать структуру
        </mcw-button>
        <span
          class="tree__not-tree">
          Здесь пока нет структуры
        </span>
      </div>
    <Spinner v-show="isLoading"/>
  </div>
</section>

</template>

<script src="./Tree.js">

</script>

<style lang="scss" src="./Tree.scss">

</style>
