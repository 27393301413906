<template>
  <div class="table__container" :style="height" ref="container">
    <div class="tab-area__actions">
      <mcw-select class="select_dense" outlined v-model="selectedType">
        <mcw-list-item
          v-for="type in types"
          :key="type.value"
          :data-value="type.value"
          role="option"
          icon
        >
          {{ type.label }}
        </mcw-list-item>
      </mcw-select>
      <mcw-textfield
        v-model.trim="searchString"
        placeholder="Поиск"
        class="table__filter"
        dense
        outline
      />
      <mcw-button disabled @click="isServiceEditorOpen = true">
        <mcw-material-icon icon="add" class="icon_grey"> </mcw-material-icon>
        добавить услугу
      </mcw-button>
      <mcw-button :disabled="!checkedItems.length" @click="showDeleteServiceModal()">
        удалить
      </mcw-button>
    </div>
    <div class="table">
      <div class="doc-field__notice" v-if="!items.length">
        Нет услуг по этому договору
      </div>
      <mdc-data-table sticky v-if="items.length">
        <table class="mdc-data-table__table">
          <thead>
            <tr class="mdc-data-table__header-row">
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--checkbox">
                <mcw-checkbox
                  ref="rowCheckbox"
                  class="mdc-data-table__header-row-checkbox"
                  value="on"
                  v-model="select"
                  @change="selectAll('id_service')"
                ></mcw-checkbox>
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort">
                ID
              </th>
              <th
                class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort"
                @click="sortTable('short_name')"
              >
                Название
              </th>
              <th class="mdc-data-table__header-cell table__cell_center">
                Код
              </th>
              <th
                class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort"
                @click="sortTable('max_date', 'date')"
              >
                Дата
              </th>
              <th
                class="mdc-data-table__header-cell
                mdc-data-table__header-cell--with-sort
                mdc-data-table__header-cell--numeric
                price-cell"
                @click="sortTable('price')"
              >
                Стоимость
              </th>
              <th class="mdc-data-table__header-cell"></th>
              <th
                class="mdc-data-table__header-cell
                mdc-data-table__header-cell--numeric
                mdc-data-table__header-cell--with-sort
                price-cell"
                @click="sortTable('prices_sub_doer')"
                v-html="thHtml"
              ></th>
            </tr>
          </thead>
          <tbody class="mdc-data-table__content">
            <tr
              class="mdc-data-table__row services__row"
              v-for="item in filteredData"
              :key="item.id_tree_people"
              v-show="!filteredData.length || filteredData.includes(item)"
              draggable="true"
              @dragstart="startDrag($event, item)"
            >
              <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
                <mcw-checkbox
                  ref="rowCheckbox"
                  class="mdc-data-table__row-checkbox"
                  :value="item.id_service"
                  v-model="checkedItems"
                ></mcw-checkbox>
              </td>
              <td class="table__cell">{{ item.id_service }}</td>
              <ExpandableCell :content="item.short_name" maxWidth="150" />
              <td class="mdc-data-table__cell mdc-data-table__cell--numeric table__cell_right">
                {{ item.code }}
              </td>
              <td class="table__cell">{{ item.max_date }}</td>
              <td class="mdc-data-table__cell">
                <EditableCell
                  :item="item"
                  width="90px"
                  editableField="price"
                  @saveEditableCell="savePrice"
                />
              </td>
              <td class="mdc-data-table__cell">
                <mcw-icon-button @click="showDeleteServiceModal(item.id_service)">
                  <mcw-material-icon icon="delete" />
                </mcw-icon-button>
              </td>
              <td class="mdc-data-table__cell table__cell_right">
                <span
                  :class="['single-price', {'single-price_highlighted': highlightServices(item) && price.binded}]"
                  v-for="(price, i) in extractPrices(item)"
                  :key="i"
                  @click="highlightSubdoer(price.id_contract_sub_doer)"
                  @dblclick="selectSubdoer(price.id_contract_sub_doer, item.id_service)"
                >
                  {{ price.price }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </mdc-data-table>
    </div>
  </div>
  <div class="doc-field__notice" v-if="!items.length && notice">
    Нет услуг по этому договору
  </div>
  <mcw-dialog v-model="isServiceEditorOpen">
    <ServiceEditor :contractId="contractId" @close="isServiceEditorOpen = false" />
  </mcw-dialog>
  <mcw-dialog id="confirm-dialog" v-model="confirmBox" @mdc-dialog-closed="clearSingleId">
    <mcw-dialog-title>Подтвердите действие</mcw-dialog-title>
    <mcw-dialog-content>
      <div>
        Уверены, что хотите удалить услугу из контракта?
      </div>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button action="dismiss" isDefault>Отмена</mcw-dialog-button>
      <mcw-dialog-button action="accept" @click="deleteServices">Удалить</mcw-dialog-button>
    </mcw-dialog-footer>
  </mcw-dialog>
  <Spinner v-if="isLoading || loading" />
</template>

<script>
import { mapActions } from 'vuex';
import CommonTable from '../DataTables/CommonTable.vue';
import ServiceEditor from '../ServiceEditor.vue';
import ExpandableCell from '../ExpandableCell.vue';
import EditableCell from '../EditableCell.vue';
import Spinner from '../Spinner.vue';

export default {
  name: 'Services',
  extends: CommonTable,
  props: {
    items: Array,
    contractId: Number,
    notice: Boolean,
    loading: Boolean,
  },
  emits: ['refresh'],
  components: {
    ExpandableCell,
    ServiceEditor,
    EditableCell,
    Spinner,
  },
  data() {
    return {
      selectedType: '0',
      types: [
        {
          label: 'Активные',
          value: '0',
        },
        {
          label: 'Все',
          value: '2',
        },
        {
          label: 'Архив',
          value: '1',
        },
      ],
      isServiceEditorOpen: false,
      thHtml: 'Цена<br>соисполнителя',
      confirmBox: false,
      singleRemove: false,
      isLoading: false,
      searchString: '',
    };
  },
  computed: {
    visibleServices() {
      if (this.selectedType === '2') {
        return this.items;
      }
      return this.items.filter((item) => Number(item.archive).toString() === this.selectedType);
    },
    subDoers() {
      return this.$store.state.contractsData.subDoers;
    },
    filteredData() {
      return this.visibleServices.filter((item) => (this.searchString.length
        ? item.short_name.toLowerCase().includes(this.searchString.toLowerCase())
        : item));
    },
    tempServices() {
      return this.$store.state.contractsData.subdoersBinding.tempServices;
    },
    currentSubdoer() {
      return this.$store.getters['contractsData/getCurrentSubdoer'];
    },
  },
  watch: {
    checkedItems(val) {
      this.$store.commit('contractsData/BIND_SERVICES', val);
      this.$store.commit('contractsData/SET_TEMP_SERVICES', val);
    },
    // currentSubdoer(val) {
    //   if (val) {
    //     this.highlightServices(val);
    //   }
    // },
  },
  created() {
    // eslint-disable-next-line no-return-assign
    this.emitter.on('loadServices', () => this.$emit('refresh'));
    this.emitter.on('changeSubdoer', this.saveSelected);
    if (this.tempServices.length) {
      this.checkedItems = Array.from(this.tempServices);
      this.$store.commit('contractsData/BIND_SERVICES', this.checkedItems);
    }
    this.$store.commit('contractsData/SET_TEMP_SERVICES', []);
  },
  methods: {
    highlightServices(service) {
      if (!this.currentSubdoer) {
        return;
      }
      try {
        const subdoers = JSON.parse(service.prices_sub_doer_json) || [];
        if (subdoers.length) {
          const result = subdoers.find(
            (subdoer) => subdoer.id_contract_sub_doer === this.currentSubdoer,
          );
          if (service.binded) {
            return result;
          }
          return false;
        }
        return false;
      } catch (error) {
        console.log(error);
      }
    },
    saveSelected() {
      this.$store.commit('contractsData/SET_TEMP_SERVICES', this.checkedItems);
    },
    extractPrices(item) {
      try {
        const result = JSON.parse(item.prices_sub_doer_json) || [];
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    clearSingleId() {
      if (this.singleRemove) {
        this.checkedItems = [];
        this.singleRemove = false;
      }
    },
    async savePrice(data) {
      const save = await this.$store.dispatch('contractsData/setServicePrice', data);
      if (!save) {
        this.emitter.emit('message', 'Не удалось сохранить цену!');
      }
      if (save.err) {
        this.emitter.emit('message', save.err_text);
      }
    },
    showDeleteServiceModal(id = null) {
      if (id) {
        this.checkedItems.push(id);
        this.singleRemove = true;
      }
      this.confirmBox = !this.confirmBox;
    },
    deleteServices() {
      const params = this.checkedItems.map(
        (item) => this.items.find((service) => service.id_service === item).id_service_contract,
      );
      this.deleteServiceFromContract(JSON.stringify(params));
    },
    highlightSubdoer(idContractSubdoer) {
      this.emitter.emit('highlightSubdoer', idContractSubdoer);
    },
    selectSubdoer(idContractSubdoer, idService) {
      this.$store.commit('contractsData/SET_TEMP_SERVICES', [idService]);
      this.emitter.emit('selectSubdoer', idContractSubdoer);
    },
    ...mapActions('contractsData', ['deleteServiceFromContract']),
  },
};
</script>

<style lang="scss">
.services {
  &__row td {
    height: 36px;
    & .editable {
      margin-left: auto;
    }
  }
}
.price-cell {
  text-align: left;
}
.single-price {
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  margin-right: 10px;
  color: var(--mdc-theme-primary);
  text-decoration: underline;
  &::after {
    content: " ";
  }
  &_highlighted {
    background: rgba(green, 0.3);
  }
}
.row_highlighted {
  font-weight: bold;
  color: green;
}
</style>
