<template>
  <div class="add-patient">
    <mcw-dialog-title>
      Добавить сотрудника
      <mcw-icon-button class="btn_close" @click="close">
        <mcw-material-icon icon="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content style="overflow: visible!important">
      <h2 v-if="form.create_patient === '1' && !localContractId" class="attention">Необходимо выбрать договор!</h2>
      <mcw-layout-grid>
        <mcw-layout-cell desktop="6">
        <mcw-textfield v-model.trim="fullName" label="ФИО" outline />
        <div class="form-group">
          <mcw-textfield v-model="form.lastname" label="Фамилия*" outline required />
          <mcw-textfield v-model="form.firstname" label="Имя*" outline required />
        </div>
        <div class="form-group">
          <mcw-textfield v-model="form.middlename" label="Отчество" outline />
          <mcw-textfield
            ref="bdInput"
            v-model="form.birthdate"
            label="Дата рождения*"
            v-date="true"
            outline
            :valid="isDateValid"
          />
        </div>
        <div class="form-group form-group_centered">
          <span>Пол*:</span>
          <mcw-radio v-model="form.people_sex" label="М" name="gender" value="1"></mcw-radio>
          <mcw-radio v-model="form.people_sex" label="Ж" name="gender" value="2"></mcw-radio>
        </div>
        </mcw-layout-cell>
        <mcw-layout-cell desktop="6">
        <div class="form-group">
          <mcw-textfield v-model="form.snils" label="СНИЛС" outline v-snils="true" />
          <mcw-textfield v-model="form.tabNumber" label="Табельный номер" outline />
        </div>
        <div class="form-group">
          <mcw-textfield v-model="form.phone" label="Телефон" outline v-phone="true"/>
          <mcw-textfield v-model="form.email" label="E-mail" outline />
        </div>
        <div class="form-group form-group_centered">
          <span><b>Добавить пациента:</b></span>
          <mcw-radio v-model="form.create_patient" label="Да" name="create" value="1"></mcw-radio>
          <mcw-radio v-model="form.create_patient" label="Нет" name="create" value="0"></mcw-radio>
        </div>
        <div class="form-group">
          <mcw-select
            :class="
              form.create_patient === '' || form.create_patient === '0' ? 'mdc-select--disabled' : ''
            "
            class="select_dense"
            v-model="selectedMOType"
            v-if="MOTypes.length"
            outlined
            label="Тип МО"
          >
            <mcw-list-item
              v-for="type of MOTypes"
              :key="type.id_reference"
              :data-value="type.id_reference"
              >{{ type.name_reference }}</mcw-list-item
            >
          </mcw-select>
          <AvailableContracts @selectContract="setContract"/>
        </div>
        <div v-if="askPayType" class="form-group">
          <mcw-select
            class="select_dense"
            label="Тип оплаты"
            outlined
            v-model="selectedPaymentType"
          >
            <mcw-list-item
              v-for="type of paymentTypes"
              :key="type.pay_type"
              :data-value="type.pay_type"
              >{{ type.pay_name }}</mcw-list-item
            >
          </mcw-select>
      </div>
        </mcw-layout-cell>
      </mcw-layout-grid>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button @click="addPatient" :disabled="canISave">Создать</mcw-dialog-button>
      <mcw-dialog-button isDefault action="dismiss" @click="close">отмена</mcw-dialog-button>
    </mcw-dialog-footer>
  </div>
</template>

<script>
import moment from 'moment';
import { utils } from '../helpers/utils';
import AvailableContracts from './AvailableContracts.vue';

export default {
  name: 'AddPatient',
  components: {
    AvailableContracts,
  },
  mixins: [
    utils,
  ],
  props: {
    selectedItem: {
      type: Object,
    },
    MOTypes: {
      type: Array,
    },
  },
  emits: ['close'],
  data() {
    return {
      localContractId: null,
      selectedMOType: null,
      askPayType: false,
      paymentTypes: [],
      selectedPaymentType: null,
      form: {
        lastname: '',
        firstname: '',
        middlename: '',
        birthdate: '',
        people_sex: '',
        create_patient: '0',
        snils: '',
        tabNumber: '',
        phone: '',
        email: '',
      },
      fullName: '',
    };
  },
  computed: {
    canISave() {
      if (!this.form.firstname || !this.form.lastname || !this.isDateValid) return true;
      if (this.form.create_patient === '1' && !this.localContractId) return true;
      if (this.askPayType && !this.selectedPaymentType) return true;
      return false;
    },
    currentContract() {
      return this.$store.state.contractsData.currentContract;
    },
    isDateValid() {
      return this.validateDate(this.form.birthdate);
    },
  },
  watch: {
    fullName(val) {
      if (!val.length) {
        return false;
      }

      let rest = [];
      [
        this.form.lastname = '',
        this.form.firstname = '',
        ...rest
      ] = this.fullName.split(' ');

      if (rest.length) {
        this.form.middlename = rest.join(' ');
      }
    },
  },
  async created() {
    if (this.currentContract) {
      this.localContractId = this.currentContract;
    }
    await this.loadPaymentTypes();
  },
  methods: {
    setContract(contract) {
      this.localContractId = contract.id_contract;
      if (contract.ask_pay_type) {
        this.askPayType = true;
      }
    },
    close() {
      this.$emit('close');
    },
    validateDate(date) {
      if (!date.length) {
        return true;
      }
      return moment(date, 'DD-MM-YYYY')._isValid && date.length === 10 && !date.includes('_');
    },
    async addPatient() {
      if (this.form.snils.length) {
        const validSnils = this.validateSnils(this.form.snils);
        if (!validSnils) {
          this.emitter.emit('message', 'Некорректный СНИЛС');
          return;
        }
      }

      const params = { ...this.form };
      params.id_contract = this.form.create_patient === '1' && this.localContractId ? this.localContractId : 0;
      params.type_mo = this.selectedMOType ? this.selectedMOType : 0;
      params.id_tree = this.selectedItem.data.id;
      if (this.askPayType) {
        params.pay_type = this.selectedPaymentType;
      }
      const result = this.$store.dispatch('treeData/addPatient', params);
      if (result) {
        this.emitter.emit('globalLoading', true);
        this.$store.commit('treeData/SET_AMOUNT', params.id_tree);
        await this.$store.dispatch('employeesData/getLastPatients', { includeServices: false }, { root: true });
        this.emitter.emit('globalLoading', false);
        this.close();
      } else {
        this.emitter.emit('message', 'Не удалось добавить пациента');
      }
    },
    async loadPaymentTypes() {
      const result = await this.$store.dispatch('employeesData/loadPaymentTypes');
      if (result) {
        this.paymentTypes = result;
      }
    },
  },
};
</script>

<style>
</style>
