<template>
    <mcw-dialog-title>
      Подразделения доступные для данного контракта
      <mcw-icon-button class="btn_close" @click="$emit('close')">
        <mcw-material-icon icon="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content class="tree-by-contract__field" >
      <SingleTreeItemByContract
        :item="selectAllItem"
        @selectAll="selectAllItem.selected = 1"
        :idContract="id" />
      <SingleTreeItemByContract
        v-for="node in treeByContract"
        :key="node.id_tree"
        :item="node"
        :idContract="id" />
    </mcw-dialog-content>
    <mcw-dialog-footer>
    </mcw-dialog-footer>
</template>

<script>
import SingleTreeItemByContract from './SingleTreeItemByContract.vue';

export default {
  name: 'TreeByContract',
  props: {
    id: Number,
  },
  emits: [
    'close',
  ],
  components: {
    SingleTreeItemByContract,
  },
  data() {
    return {
      selectAllItem: {
        id_tree: 0,
        title: 'Выбрать все',
        selected: 0,
      },
    };
  },
  computed: {
    treeByContract() {
      return this.$store.getters['treeData/getTreeByContract'];
    },
  },
};
</script>

<style lang="scss">
  .available-branches{
    height: 100%;
    &__field{
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
</style>
