import { mapMutations, mapState } from 'vuex';
import TreeItem from '../TreeItem/TreeItem.vue';
import Tree from '../../Tree/Tree';
import Spinner from '../Spinner.vue';
import GetAccess from '../GetAccess.vue';
import HazardEditor from '../HazardEditor.vue';
import CloningNode from '../CloningNode.vue';
import AddPatient from '../AddPatient.vue';
import { DESKTOP_BREAKPOINT } from '../../constants';

export default {
  components: {
    TreeItem,
    Spinner,
    GetAccess,
    HazardEditor,
    CloningNode,
    AddPatient,
  },
  data() {
    return {
      id: 0,
      containerHeight: 0,
      isLoading: true,
      tree: null,
      filterterm: '',
      isEditOpen: false,
      isRemoveOpen: false,
      isAddOpen: false,
      isAddPatientOpen: false,
      isCloningDialogOpen: false,
      text: '',
      removeName: '',
      types: [
        { name: 'Подразделение', type: 'node' },
        { name: 'Должность', type: 'leaf' },
      ],
      newItem: {
        type: '',
        name: '',
      },
      tempItems: [],
      dialogComponent: 'GetAccess',
      isHazardDialogOpen: false,
      nodesToMove: [],
      hazardsLoading: false,
      isEmptyTree: false,
      dateValid: null,
      DESKTOP_BREAKPOINT,
    };
  },
  computed: {
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },
    isSelectedCompany() {
      return this.$store.getters['user/getSelectedCompanyName'];
    },
    isEditable() {
      return Boolean(this.selectedItem && this.selectedItem.data.parentId);
    },
    selectedItem() {
      return this.$store.state.treeData.selectedItem;
    },
    treeData() {
      return this.$store.getters['treeData/getData'];
    },
    rootId() {
      return this.$store.getters['treeData/getRootId'];
    },
    getRoot() {
      return this.$store.getters['treeData/getRoot'];
    },
    currentRedactor() {
      const redactor = this.$store.state.treeData.currentRedactor;
      if (redactor) {
        return redactor;
      }
      return false;
    },
    isAccessDialogOpen: {
      get() {
        return this.$store.state.treeData.isTakeAccess;
      },
      set(val) {
        this.$store.commit('treeData/TAKE_ACCESS', val);
      },
    },
    ...mapState({
      selectedCompany: (state) => state.user.selectedCompany,
      MOTypes: (state) => state.treeData.MOTypes,
      selectedContract: (state) => `${state.contractsData.currentContract}`,
      contracts: (state) => state.contractsData.contracts,
      treeIsLoading: (state) => state.treeData.treeIsLoading,
    }),
    currentContract: {
      get() { return this.selectedContract; },
      set(value) { this.SET_CURRENT_CONTRACT(`${value}`); },
    },
    userId() {
      return this.$store.getters['user/userId'];
    },
    hasAccess() {
      return this.$store.getters['treeData/getAccessList'].find((item) => item.id_people === this.userId);
    },
  },
  created() {
    this.emptyTreeInit();
  },
  watch: {
    selectedCompany(val) {
      if (val && this.tree) {
        this.emptyTreeInit();
      }
    },
  },
  mounted() {
    this.isLoading = true;
    this.emitter.on('closeCloningDialog', this.closeCloningDialog);
    this.emitter.on('openHazards', (item) => {
      this.$store.commit('treeData/SELECT_ITEM', item);
      this.showHazards();
    });
    this.emitter.on('setTreeOnLoad', (val) => {
      this.isLoading = val;
      if (this.treeData.length && this.rootId !== false) {
        this.buildTree();
      } else {
        this.isLoading = false;
        return false;
      }
    });
    if (this.deviceWidtgh >= DESKTOP_BREAKPOINT) {
      this.setContainerHeight();
    }
    this.$store.subscribe((mutation) => {
      const mutationType = mutation.type.replace('treeData/', '');
      switch (mutationType) {
        case 'EMPTY_TREE':
          this.emptyTreeInit();
          this.isLoading = false;
          this.isEmptyTree = true;
          break;
        case 'LOAD_TREE':
          this.isLoading = true;
          this.buildTree();
          break;
        case 'ADD_ITEM':
          const data = mutation.payload;
          this.tree.add(data, data.parentId, this.tree.traverseBF);
          if (data.type === 'leaf') {
            setTimeout(() => {
              const createdItem = this.tree
                .search(data.id, data.parentId, this.tree.traverseBF);
              this.$store.commit('treeData/SELECT_ITEM', createdItem);
            }, 300);
          }
          break;
        case 'REMOVE_ITEM':
          this.tree.remove(mutation.payload.id, mutation.payload.parentId, this.tree.traverseBF);
          break;
        case 'CHANGE_TYPE':
          const changingItem = Object.assign(this.$store.getters['treeData/getItemById'](mutation.payload), {});
          this.tree.remove(mutation.payload, changingItem.parentId, this.tree.traverseBF);
          this.tree.add(changingItem, changingItem.parentId, this.tree.traverseBF);
          break;
        case 'MOVE_ITEM':
          let newParentId;
          const { oldParentId, itemId, direction } = mutation.payload;
          const nodeToMove = this.tree.search(itemId,
            oldParentId, this.tree.traverseDF);
          if (oldParentId === mutation.payload.targetObj.parentId) {
            newParentId = oldParentId;
          } else {
            newParentId = mutation.payload.targetObj.parentId;
          }
          const parent = this.$store.getters['treeData/getItemById'](newParentId);
          let arr;
          if (parent.parentId) {
            arr = this.tree
              .search(newParentId, parent.parentId, this.tree.traverseBF).children;
          } else {
            this.$store.dispatch('treeData/loadTree');
            return;
          }

          let targetIndex = arr.findIndex((el) => el.data.id === mutation.payload.targetObj.id);
          const initialItemIndex = arr.findIndex((el) => el.data.id === itemId);
          if (newParentId === oldParentId) {
            if (initialItemIndex > targetIndex && direction === 'under') {
              targetIndex += 1;
            } else if (initialItemIndex < targetIndex && direction === 'over') {
              targetIndex -= 1;
            }
          } else if (newParentId !== oldParentId && direction === 'under') {
            targetIndex += 1;
          }

          if (nodeToMove.children.length) {
            this.findInnerItems(nodeToMove);
          }
          const remove = this.tree.remove(itemId, oldParentId, this.tree.traverseBF);
          console.log(remove);
          this.tree.add(nodeToMove.data, newParentId, this.tree.traverseBF, targetIndex);
          if (this.nodesToMove.length) {
            this.nodesToMove
              .filter((el) => el.id !== itemId)
              .forEach((item) => {
                this.tree.add(item,
                  item.parentId, this.tree.traverseDF);
              });
            this.nodesToMove = [];
          }
          break;
        case 'PASTE_ITEM':
          const newItem = this.$store.getters['treeData/getItemById'](mutation.payload.itemId);
          const node = this.tree.search(mutation.payload.itemId,
            mutation.payload.oldParentId, this.tree.traverseDF);
          if (node.children.length) {
            this.findInnerItems(node);
          }
          this.tree.remove(mutation.payload.itemId,
            mutation.payload.oldParentId, this.tree.traverseBF);
          this.tree.add(newItem,
            mutation.payload.targetObj.id, this.tree.traverseDF);
          this.nodesToMove
            .filter((el) => el.id !== mutation.payload.itemId)
            .forEach((item) => {
              this.tree.add(item,
                item.parentId, this.tree.traverseDF);
            });
          this.nodesToMove = [];
          break;
        case 'REMOVE_HAZARD':
          this.$store.commit('treeData/DECREASE_HAZARD_COUNTER', { id: this.selectedItem.data.id, amount: 1 });
          break;
        case 'ATTACH_HAZARD':
          this.$store.commit('treeData/INCREASE_HAZARD_COUNTER', { id: this.selectedItem.data.id, amount: 1 });
          break;
        default:
          return false;
      }
    }, { prepend: true });
    if (!this.isSelectedCompany) {
      this.isLoading = false;
    }
  },
  updated() {
    this.isLoading = false;
  },
  methods: {
    emptyTreeInit() {
      this.tree = new Tree({
        id: 0,
        text: '',
        parentId: null,
      });
      // this.isLoading = false;
    },
    isSelectedByContract(contract) {
      let result;
      if (contract.archive === 0
          && contract.for_medsafe === 1
          && contract.medsafe_locked === 0
          && contract.id_tree?.length) {
        result = contract.id_tree.some((i) => {
          function matchTree(node, id) {
            if (node.data.id === id) {
              return true;
            }
            if (node.parent) return matchTree(node.parent, id);
          }
          return matchTree(this.selectedItem, i.id_tree);
        });
      }
      return result;
    },
    buildTree() {
      this.isEmptyTree = false;
      this.tree = new Tree({
        id: this.getRoot.id,
        text: this.getRoot.text,
        parentId: null,
      });
      this.render(this.treeData);
    },
    findInnerItems(node) {
      if (node.children.length) {
        this.nodesToMove.push(node.data);
        return node.children.map((child) => this.findInnerItems(child));
      }
      this.nodesToMove.push(node.data);
    },
    clearSelection(ev) {
      if (!ev.target.classList.contains('tree__text') && !ev.target.parentElement.classList.contains('tree__text')) {
        this.$store.commit('treeData/SELECT_ITEM', null);
      }
    },
    setContainerHeight() {
      let height = this.$refs.container.offsetHeight;
      const maxHeight = document.documentElement.clientHeight - 324;
      if (height > maxHeight) {
        height = maxHeight;
      }
      this.$refs.container.style.height = `${height}px`;
    },
    render(arr) {
      this.isLoading = true;
      if (arr) {
        const dataForRendering = JSON.parse(JSON.stringify(arr.filter((item) => item.parentId)));
        dataForRendering.forEach((item) => {
          const added = this.tree.add(item, item.parentId, this.tree.traverseBF);
          if (!added) {
            this.tempItems.push(item);
          }
        });
      }
      if (this.tempItems.length) {
        const temp = JSON.parse(JSON.stringify(this.tempItems));
        this.tempItems = [];
        this.render(temp);
      }
      this.isLoading = false;
    },
    async addItem() {
      const add = await this.$store.dispatch('treeData/addItem', this.newItem);
      if (add) {
        this.newItem = {
          type: '',
          name: '',
        };
        this.$store.commit('treeData/SELECT_ITEM', null);
        this.isAddOpen = false;
      }
    },
    async removeItem() {
      if (!this.selectedItem) {
        return;
      }
      const remove = await this.$store.dispatch('treeData/removeItem', this.selectedItem.data);
      if (remove) {
        this.isRemoveOpen = false;
      }
    },
    tryToEdit(action) {
      if (!this.hasAccess) {
        this.showNotHasAccessMessage();
        return;
      }
      this[action]();
    },
    openRemoveDialog() {
      if (!this.selectedItem) {
        return;
      }
      if (this.selectedItem.data.people_amount > 0 || this.selectedItem.data.has_children === 1) {
        this.emitter.emit('message', 'Нельзя удалить непустой элемент!');
        return;
      }
      this.isRemoveOpen = true;
      this.removeName = this.selectedItem.data.text;
    },
    showCloningDialog() {
      this.isCloningDialogOpen = true;
    },
    closeCloningDialog() {
      this.isCloningDialogOpen = false;
    },
    openEditDialog() {
      this.isEditOpen = true;
      this.text = this.selectedItem.data.text;
    },
    openAddDialog() {
      this.isAddOpen = true;
    },
    openAddPatientDialog() {
      this.isAddPatientOpen = true;
    },
    showNotHasAccessMessage() {
      // eslint-disable-next-line max-len
      const message = 'У вас нет прав на редактирование структуры данной организации. Необходимо обратиться к менеджеру организации или добавить/запросить добавить себя в контакты';
      this.emitter.emit('message', message);
    },
    async saveItem() {
      const save = await this.$store.dispatch('treeData/editItem', this.text);
      if (save) {
        this.$store.commit('treeData/SELECT_ITEM', null);
        this.text = '';
        this.isEditOpen = false;
      }
    },
    async changeNodeType() {
      if (!this.checkChangeTypePossibility(this.selectedItem)) {
        this.emitter.emit('message', 'Нельзя изменить тип непустого элемента!');
        return false;
      }
      await this.$store.dispatch('treeData/changeType', this.selectedItem.data.id);
    },
    checkChangeTypePossibility(nodeItem) {
      if (nodeItem.data.type === 'leaf') {
        return nodeItem.data.people_amount === 0;
      }
      if (nodeItem.data.type === 'node') {
        return nodeItem.data.has_children === 0;
      }
      return false;
    },
    async showHazards() {
      this.isHazardDialogOpen = true;
      this.$store.dispatch('treeData/getNodeHazards');
      await this.$store.dispatch('treeData/getHazardsTypes');
    },
    async createTree() {
      const newTree = await this.$store.dispatch('treeData/createNewTree');
      if (newTree) {
        this.$store.dispatch('treeData/loadTree');
      }
    },
    ...mapMutations('contractsData', ['SET_CURRENT_CONTRACT']),
  },
};
