import Node from './Node';
import Queue from './Queue';

export default class Tree {
  _root = null;

  id = 0;

  constructor(data) {
    this._root = new Node(data);
  }

  traverseDF(callback) {
    (function recurse(currentNode) {
      for (let i = 0, { length } = currentNode.children; i < length; i += 1) {
        recurse(currentNode.children[i]);
      }

      callback(currentNode);
    }(this._root));
  }

  traverseBF(callback) {
    const queue = new Queue();

    queue.enqueue(this._root);

    let currentNode = queue.dequeue();

    while (currentNode) {
      for (let i = 0, { length } = currentNode.children; i < length; i += 1) {
        queue.enqueue(currentNode.children[i]);
      }

      callback(currentNode);
      currentNode = queue.dequeue();
    }
  }

  contains(callback, traversal) {
    traversal.call(this, callback);
  }

  add(data, parentId, traversal, index = null) {
    const child = new Node(data);
    let parent = null;
    const callback = function (node) {
      if (node.data.id === parentId) {
        parent = node;
      }
    };

    this.contains(callback, traversal);

    if (parent) {
      if (index === null) {
        parent.children.push(child);
        parent.children.sort((a, b) => {
          let result = 0;
          if (a.data.order > b.data.order) {
            result = 1;
          } else if (b.data.order > a.data.order) {
            result = -1;
          }
          return result;
        });
      } else {
        parent.children.splice(index, 0, child);
      }

      child.parent = parent;
      return data.id;
    }
    return false;
  }

  search(id, parentId, traversal) {
    let parent = null;
    let foundChild = null;

    const callback = function (node) {
      if (node.data.id === parentId) {
        parent = node;
      }
    };

    this.contains(callback, traversal);

    const index = parent?.children.findIndex((item) => item.data.id === id);
    if (!index || index === -1) {
      throw new Error('Node does not exist.');
    } else {
      foundChild = parent.children[index];
    }

    return foundChild;
  }

  remove(id, parentId, traversal) {
    let parent = null;
    let childToRemove = null;
    let index;

    const callback = function (node) {
      if (node.data.id === parentId) {
        parent = node;
      }
    };

    this.contains(callback, traversal);

    if (parent) {
      index = parent.children.findIndex((item) => item.data.id === id);
      if (index === -1) {
        throw new Error('Node to remove does not exist.');
      } else {
        childToRemove = parent.children.splice(index, 1);
      }
    } else {
      throw new Error('Parent does not exist.');
    }

    return childToRemove;
  }
}
