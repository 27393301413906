<template>
        <mcw-dialog-title>
        Сведения об иностранном гражданине
        <mcw-icon-button class="btn_close" @click="$emit('close')">
          <mcw-material-icon icon="close"/>
        </mcw-icon-button>
      </mcw-dialog-title>
      <mcw-dialog-content style="overflow: visible!important">
        <div class='foreign-data-modal'>
          <div class="form-group">
            <mcw-textfield
              v-model="foreignForm.lastnamel"
              label="Фамилия"
              outline/>
            <mcw-textfield
              v-model="foreignForm.firstnamel"
              label="Имя"
              outline />
          </div>
          <div class="form-group">
            <section>
              <mcw-textfield
                v-model="foreignForm.birth_place"
                label="Место рождения"
                outline />
            </section>
            <section>
              <span>Гражданство:</span>
              <SearchSelect
                v-if='countries'
                titleWidth="100%"
                titleHeight='56px'
                label="country"
                placeholder="Гражданство"
                @selectItem="setCitizen"
                :options="countries"
                :initial="selectedCitizen"
                :addButton="false"
                :showCounter='false'/>
            </section>
          </div>
          <div class="form-group">
            <section>
              <span>Откуда прибыл:</span>
              <SearchSelect
                v-if='countries'
                titleWidth="100%"
                label="country"
                placeholder="Откуда прибыл"
                @selectItem="setComeFrom"
                titleHeight='56px'
                :options="countries"
                :initial="selectedComeFrom"
                :addButton="false"
                :showCounter='false'/>
              </section>
              <mcw-textfield
                ref='bdInput'
                class='foreign-data-modal__text'
                v-model="foreignForm.date_stay"
                label="В России до"
                v-date='true'
                outline />
          </div>
          <div class="form-group">
            <mcw-select
              v-if="missions"
              class="select-patient-mission"
              v-model="foreignForm.mission"
              outlined
              label="Цель прибывания">
              <mcw-list-item
                v-for="mission of missions"
                :key="mission.id"
                :data-value="mission.purpose"
                >{{ mission.purpose }}</mcw-list-item>
            </mcw-select>
            <mcw-textfield
              v-if='foreignForm.mission.includes("Иное")'
              v-model="foreignForm.otherMission"
              label="Другая цель"
              outline />
          </div>
        </div>
      </mcw-dialog-content>
      <mcw-dialog-footer>
        <mcw-dialog-button
          class="all-patient-table-btn"
          @click="tryToSaveForeignData()">
          Внести сведения
        </mcw-dialog-button>
        <mcw-dialog-button action="dismiss">отмена</mcw-dialog-button>
      </mcw-dialog-footer>
</template>

<script>
import SearchSelect from './SearchSelect/SearchSelect.vue';

export default {
  name: 'ForeignDataModal',
  components: {
    SearchSelect,
  },
  emits: [
    'close',
  ],
  props: {
    countries: {
      type: Array,
    },
    item: {
      type: null || Object,
    },
    selectedPatient: {
      type: null || Number,
    },
  },
  data() {
    return {
      foreignForm: {
        lastnamel: '',
        firstnamel: '',
        citizen: null,
        where_came_from: null,
        birth_place: '',
        date_stay: '',
        mission: '',
        otherMission: '',
      },
      selectedCitizen: {},
      selectedComeFrom: {},
    };
  },
  computed: {
    missions() {
      return this.$store.state.employeesData.missions;
    },
  },
  created() {
    if (this.item) {
      this.selectedCitizen = this.countries.find((i) => i.id === this.item.citizen);
      this.selectedComeFrom = this.countries.find((i) => i.id === this.item.where_came_from);
      this.foreignForm.birth_place = this.item.birth_place;
      this.foreignForm.lastnamel = this.item.lastnamel;
      this.foreignForm.firstnamel = this.item.firstnamel;
      this.foreignForm.date_stay = this.item.date_stay;
      this.foreignForm.citizen = this.item.citizen;
      this.foreignForm.where_came_from = this.item.where_came_from;
      const found = this.missions.find((i) => i.purpose === this.item.mission);
      if (found) {
        this.foreignForm.mission = this.item.mission;
      } else {
        this.foreignForm.mission = 'Иное (указать...)';
        this.foreignForm.otherMission = this.item.mission;
      }
    }
  },
  methods: {
    setCitizen(val) {
      this.selectedCitizen = val;
    },
    setComeFrom(val) {
      this.selectedComeFrom = val;
    },
    tryToSaveForeignData() {
      const params = {
        id_patient: this.selectedPatient,
        lname: this.foreignForm.lastnamel,
        fname: this.foreignForm.firstnamel,
        birth_place: this.foreignForm.birth_place,
        citizen: this.selectedCitizen.id ? this.selectedCitizen.id : 0,
        where_came_from: this.selectedComeFrom.id ? this.selectedComeFrom.id : 0,
        mission: !this.foreignForm.mission.includes('Иное') ? this.foreignForm.mission : this.foreignForm.otherMission,
        date_stay: this.foreignForm.date_stay,
      };
      this.$store.dispatch('employeesData/postForeingData', params).then(() => {
        this.$emit('close');
        // this.selectPatient(this.selectedPatient);
      });
    },
  },
};
</script>

<style>
  .foreign-data-modal .form-group > * {
    width: 45%;
  }
  .foreign-data-modal__text {
    margin-left: auto;
    margin-top: 1.5rem !important;
  }
</style>
