import axios from 'axios';
import { TREE_URL } from '../constants';
import { networkErrorsNotifier } from './networkErrorsNotifier';

export const BASE_URL = 'https://host2.medsafe.tech/';
export const ADD_URL = 'https://host4.medsafe.tech/';
export const POSTFIX = 'api/';
const headers = {
  'Content-Type': 'application/json',
};

class Api {
  async get(payload) {
    const toReturn = await this.request(`${BASE_URL}${POSTFIX}${TREE_URL}?json=${JSON.stringify(payload)}`, 'GET');
    console.log(payload, toReturn);
    return toReturn;
  }

  async post(payload, type = null) {
    const method = 'POST';
    let toReturn;
    if (type) {
      toReturn = await this.request(`${BASE_URL}${POSTFIX}${TREE_URL}?json=${JSON.stringify(payload)}`, method);
    } else {
      const body = `json=${JSON.stringify(payload)}`;
      toReturn = await this.request(`${BASE_URL}${POSTFIX}${TREE_URL}`, method, body);
    }
    console.log(payload, toReturn);
    return toReturn;
  }

  async request(url, method, body = null) {
    try {
      const response = await fetch(url, {
        method,
        headers,
        body,
      });
      const result = await response.json();
      return result;
    } catch (error) {
      networkErrorsNotifier.test();
      return error;
    }
  }

  async sendLargeJson(payload) {
    console.log(payload);
    try {
      const response = await axios.post(`${ADD_URL}${POSTFIX}${TREE_URL}`, {
        json: JSON.stringify(payload),
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getFile(hash) {
    try {
      const response = await fetch(`${BASE_URL}${hash}`, {
        responseType: 'blob',
      });
      if (response.ok) {
        const result = await response.blob();
        return result;
      }
      return false;
    } catch (error) {
      return error.response.status;
    }
  }

  async uploadFile(formData, url) {
    try {
      const upload = await axios.post(`https://host2.medsafe.tech/api/${url}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return upload;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new Api();
