<template>
  <td ref="cell" class="expandable-cell" :style="{maxWidth: `${maxWidth}px`}">
    <div class="expandable-cell__short">
      {{content}}
    </div>
    <div
      class="expandable-cell__full"
      v-show="isVisible">
      {{content}}
    </div>
  </td>

</template>

<script>
export default {
  name: 'ExpandableCell',
  props: {
    content: String,
    maxWidth: {
      type: String,
      default: () => '50',
    },
  },
  data() {
    return {
      isVisible: false,
      offset: 0,
    };
  },
  mounted() {
    const { cell } = this.$refs;
    const offset = cell.offsetLeft;
    this.offset = offset;
    cell.addEventListener('mouseover', this.showContent);
    cell.addEventListener('mouseout', this.hideContent);
  },
  beforeUnmount() {
    const { cell } = this.$refs;
    cell.removeEventListener('mouseover', this.showContent);
    cell.removeEventListener('mouseout', this.hideContent);
  },
  methods: {
    showContent() {
      this.isVisible = true;
    },
    hideContent() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.expandable-cell{
  position: relative;
  &__short{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__full{
    position: absolute;
    top: 0;
    left: -3px;
    padding: 7px 4px 4px 4px;
    white-space: pre-wrap;
    background: #eee;
    z-index: 50;
    max-width: 100%;
    min-height: 36px;
  }
}
</style>
