<template>
    <mcw-button
      class="app-main__show-btn"
      @click="showStatus = !showStatus">
      {{structVerb}} структуру
    </mcw-button>
  <div
    class="section section_left"
    :class="{section_hidden: !showStatus && isMobile}"
    ref="leftSection">
          <div class="section__header">
      <h2 class="page-heading">
        Штатное расписание
        <mcw-button @click='getTree' :disabled='!selectedCompany'>
          <mcw-material-icon class="resize__icon" icon="refresh"></mcw-material-icon>Обновить
        </mcw-button>
      </h2>
      <mdc-button
        v-if="false"
        @click="isCreateDialogOpen = true">
        Создать новую
      </mdc-button>
    </div>
    <Tree :key="treeKey"/>
  </div>
    <div class="resizer resizer_vert" ref="resizer">
    </div>
  <div class="section section_right" ref="rightSection">
      <div class="notice__container" v-if="isShowNotice && !routeFromContract">
        <div class="notice">
          {{notice}}
        </div>
      </div>
      <mcw-tab-bar v-if="selectedCompany" class='main-tab-bar'>
        <mcw-tab icon="people" class="icon_base" @click="showAllPatients" >
          пациенты
        </mcw-tab>
        <mdc-menu-anchor class="myAnchor">
          <mcw-tab
            icon="format_list_bulleted"
            class="icon_base"
            @click="showDocList" >
            списки
          </mcw-tab>
          <mdc-menu
            v-model="isMenuOpen"
            @select="isMenuOpen = false"
            class="employees-list"
            style="left: -300px"
          >
            <EmployeesLists
              :items="docList"
              v-if="isMenuOpen"
              @close="isMenuOpen = false"
              @uploadList="handleUpload"
              @showStaff="showStaff"/>
          </mdc-menu>
        </mdc-menu-anchor>
        <mcw-tab icon="playlist_add_check" class="icon_base" @click="showQuestioned" >
          анкеты
        </mcw-tab>
      </mcw-tab-bar>
      <Spinner v-show="isLoading"/>
      <TableWrapper
        :visibleTable="visibleTable"
        @closeAll="visibleTable = []"
        :highLightTable="highLightTable"
        :title='nameContract'/>
  </div>
  <mcw-dialog
    v-model="isCreateDialogOpen"
    escape-key-action="close"
    scrim-click-action="close"
    :auto-stack-buttons="true">
    <mcw-dialog-title>Новая структура</mcw-dialog-title>
      <mcw-dialog-content>
      <mcw-textfield
        v-model="newTree.name"
        label="Название"
        outline
        required/>
      <mcw-textfield
        v-model="newTree.tin"
        label="ИНН"
        outline
        required/>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button @click="createTree">ок</mcw-dialog-button>
      <mcw-dialog-button isDefault action="dismiss">отмена</mcw-dialog-button>
    </mcw-dialog-footer>
  </mcw-dialog>
  <mcw-dialog
    v-model="showListNameModal"
    escape-key-action="close"
  >
    <mcw-dialog-title>
      Укажите название списка
      <mcw-icon-button class="btn_close" @click="showListNameModal = false">
        <mcw-material-icon icon="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>

    <mcw-dialog-content>
      <span>Например: Петрова, от 16.06.2022</span>
      <mcw-textfield
        v-model="comment"
        label="Название списка"
        :valid="isValid"
        ref="input"
      />
    </mcw-dialog-content>

    <mcw-dialog-footer>
      <mcw-dialog-button action="accept" @click="sendList">загрузить</mcw-dialog-button>
      <mcw-dialog-button action="dismiss" @click="showListNameModal = false">отмена</mcw-dialog-button>
    </mcw-dialog-footer>
  </mcw-dialog>
</template>

<script src="./AppMain.js">

</script>

<style lang="scss" src="./AppMain.scss">

</style>
