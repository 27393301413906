<template>
<div class="edit-contract">
  <mcw-dialog-title>
    {{title}}
    <mcw-icon-button class="btn_close" @click="$emit('close')">
      <mcw-material-icon icon="close"/>
    </mcw-icon-button>
  </mcw-dialog-title>
  <mcw-dialog-content>
    <mcw-layout-grid>
      <mcw-layout-cell desktop="6" tablet="6">
      <mcw-textfield
        tabindex="0"
        v-model.trim="contract.n_contract"
        label="Номер"
        type="text"
        required
        outline/>
      <mcw-textfield
        v-model.trim="contract.name_contract"
        label="Название"
        type="text"
        required
        outline/>
      <mcw-select
        class="params-select select_full-width"
        v-model="contract.id_org_doer"
        outlined
        :value="contract.id_org_doer"
        label="Исполнитель"
        leading-icon="business_center">
    <mcw-list-item
      class="mcw-list-item--no-wrap"
      v-for="doer in doers"
      :key="doer.id_org"
      :data-value="doer.id_org"
      role="option"
      icon>
      {{ doer.org_name }}
    </mcw-list-item>
  </mcw-select>
      <EditableInput
        label="Заказчик"
        :initialData="contract.org_beneficial"
        @update:modelValue="setNewBeneficial"/>
      <mcw-button
        @click="swap"
        :disabled="contract.id_org_beneficial === contract.id_org_customer">
        <mcw-material-icon
          class="icon_base"
          icon="swap_vert"/>
        Поменять местами
      </mcw-button>
      <mcw-button
        @click='equate'
        :disabled="contract.id_org_beneficial === contract.id_org_customer">
        <mcw-material-icon
          class="icon_base"
          icon="swap_vert"/>
        Заказчика в плательщики
      </mcw-button>
      <EditableInput
        label="Плательщик"
        :initialData="contract.org_customer"
        @update:modelValue="setNewCustomer"/>
      <div class="self-save-block">
        <mcw-textfield
          outline
          v-model="contract.note_for_patient"
          label="Инструкции в направлении для пациентов"
          multiline
          rows="4"
          cols="40" />
        <mcw-icon-button @click="saveNote('note_for_patient')" class="self-save-block__button">
          <mcw-material-icon icon="save" />
        </mcw-icon-button>
      </div>
      <div class="self-save-block">
        <mcw-textfield
          outline
          v-model="contract.note_for_adm"
          label="Инструкции для администраторов мед. центра"
          multiline
          rows="4"
          cols="40" />
        <mcw-icon-button @click="saveNote('note_for_adm')" class="self-save-block__button">
          <mcw-material-icon icon="save" />
        </mcw-icon-button>
      </div>
      </mcw-layout-cell>
      <mcw-layout-cell desktop="6" tablet="6">
        <mcw-textfield
          v-date='true'
          v-model.trim="contract.date_contract"
          label="Дата"
          type="text"
          outline/>
        <mcw-select
          class="params-select select_full-width"
          v-model="contract.type_contract"
          outlined
          :value="contract.type_contract"
          @update:modelValue="getTemplate"
          label="Тип контракта"
          leading-icon="article">
          <mcw-list-item
            class="mcw-list-item--no-wrap"
            v-for="type in contractTypes"
            :key="type.id"
            :data-value="type.id"
            role="option"
            icon>
            {{ type.type_contract }}
          </mcw-list-item>
        </mcw-select>
        <div class="edit-contract__checkbox-field">
          <mcw-checkbox
            v-for="param in boolParams"
            :key="param"
            :label="transcriptColumnTitle(param)"
            v-model="contract[param]"
            @change="setBoolParams(param)">
          </mcw-checkbox>
        </div>
        <mcw-menu-anchor class="myAnchor">
        <div class="templates"
          v-if="templates.length">
          <h3>Возможные шаблоны для услуг</h3>
          <table
            class="mdc-data-table__table">
            <thead>
              <tr class="mdc-data-table__header-row">
                <th class="mdc-data-table__header-cell">
                  ID
                </th>
                <th class="mdc-data-table__header-cell">
                  Название
                </th>
                <th class="mdc-data-table__header-cell">
                </th>
              </tr>
            </thead>

              <tbody class="mdc-data-table__content">
                <tr
                  class="mdc-data-table__row templates__row"
                  :class="{
                    templates__row_selected: template.id_contract_template === selectedTemplate
                  }"
                  v-for="template in templates"
                  @click="selectTemplate(template.id_contract_template)"
                  :key="template.id">
                  <td class="mdc-data-table__cell">
                    {{template.id_contract_template}}
                  </td>
                  <td class="mdc-data-table__cell">
                    {{template.name_contract}}
                  </td>
                  <td class="mdc-data-table__cell">
                    {{template.shortName}}
                  </td>
                </tr>
              </tbody>

          </table>
        </div>
        <div class="template__selected-param">
          {{selectedParameter?.text}}
        </div>
              <mcw-menu v-model="isMenuOpened" @select="setParameter">
                <mcw-list-item
                  v-for="parameter in parameters"
                  :key="parameter.id">
                  {{capitalize(parameter.text)}}
                </mcw-list-item>
              </mcw-menu>
              </mcw-menu-anchor>
      </mcw-layout-cell>
    </mcw-layout-grid>
  </mcw-dialog-content>

  <mcw-dialog-footer>
    <mcw-dialog-button
      @click="saveContract"
      tabindex="1"
      :disabled="!isPossibleToSave">
      Сохранить
    </mcw-dialog-button>
    <mcw-dialog-button
      action="dismiss"
      tabindex="0"
      isDefault>
      отмена
    </mcw-dialog-button>
  </mcw-dialog-footer>
  <Spinner v-if="isLoading"/>
</div>
</template>

<script src="./EditContract.js">

</script>

<style src="./EditContract.scss" lang="scss">

</style>
