<template>
      <li class="tree__item"
        tabindex="0"
        :class="{tree__item_selected: isSelected}">
        <div class="tree__content"
          ref="target"
          :draggable="item.data.type === 'leaf' || item.data.type === 'node'"
          :data-id="item.data.id"
          :data-type="item.data.type"
          :data-blocked="isBlocked"
          :data-direction="pasteDirection"
          @dragstart="startDrag($event), isDragging = true"
          @dragend="isDragging = false"
          @dragover.prevent="onDragOver($event), isHovered = true"
          @dragenter.stop.prevent
          @dragleave="isHovered = false"
          @drop.stop.prevent="onDrop($event), isHovered = false"
          :class="{
                  tree__content_folder: isFolder,
                  tree__content_dragging: isDragging,
                  tree__content_hovered: isHovered && !isBlocked,
                  tree__content_blocked: isBlocked,
                  tree__content_selected: isSelected,
                  tree__content_droppable: highLightLeafs,
                  vanish: canIShow
                }">
          <mcw-material-icon icon="east" class="paste__icon"/>
          <mcw-material-icon
            @click="toggle"
            v-if="isFolder"
            :icon="isOpen ? 'remove_circle_outline' : 'add_circle_outline'"
            class="tree__sign"/>
          <div
            class="tree__text"
            :class="{tree__text_draggable : item.parent}"
            @click.stop="select"
            @dblclick="selectAll">
            <span :class="{badge: item.data.type === 'leaf' || item.data.type === 'uncategorized'}">
              <mcw-material-icon :icon="icon" class="tree__icon"/>
              <span v-if="item.data.type === 'leaf' || item.data.type === 'uncategorized'" class="tree__amount">{{peopleAmount}}</span>
            </span>
            <span
              v-if="item.data.type === 'leaf'"
              @click.stop="emitter.emit('openHazards', item)"
              :class="{badge: item.data.type === 'leaf' || item.data.type === 'uncategorized'}">
                <mcw-material-icon icon="notification_important" class="tree__icon"/>
                <span v-if="item.data.type === 'leaf' " class="tree__amount">{{hazardAmount}}</span>
            </span>
            <span class='leaf-text'>{{ item.data.text }}</span>
          </div>
        </div>
        <transition name="slide"
          @before-enter="onOpenStart"
          @after-enter="onTransitionEnd"
          @before-leave="onCloseStart"
          @after-leave="onTransitionEnd">
          <ul v-show="isOpen" v-if="isFolder" >
            <tree-item
              v-for="(child, index) in filterlist"
              :key="index"
              :item="child"
              :filterterm="filterterm"
              :isBlocked="isChildrenBlocked"
              :icon="child.data.type === 'node' ? 'business' : 'people'"
            ></tree-item>
          </ul>
        </transition>
      </li>
</template>

<script src="./TreeItem.js">

</script>

<style lang="scss" src="./TreeItem.scss">

</style>
