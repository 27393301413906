export default class Node {
  data = null;

  parent = null;

  children = [];

  constructor(data) {
    this.data = data;
  }

  get parent() {
    return this.parent;
  }

  set parent(parent) {
    this.parent = parent;
  }
}
