<template>
  <div
  id="questioned-table"
  class="table__container"
  :style="height"
  @dragover.prevent
  @dragenter.prevent
  @dragend="clearHighLight"
  @drop.capture="onDrop($event)">
  <mcw-icon-button
    class="table__close-btn"
    @click="closeTable">
    <mcw-material-icon icon="close" />
  </mcw-icon-button>
  <div
    class="table"
    ref="table">
    <mcw-data-table
      >
      <div class="table__header">
        <div
          class="mdc-typography--subtitle1">
          <h2 class="table__title">{{title}}
            <span class='table-summary' v-if='items.length'>
            | Записей: {{items.length}} |
          </span></h2> <mcw-button @click="isPeriodsOpened = true">{{datesRange.start}} ~ {{datesRange.end}}</mcw-button>
        </div>
      </div>
      <div class="table__caption-top">
        <mcw-textfield
            v-model.trim="searchString"
            placeholder="Поиск (ФИО)"
            class="table__filter"
            dense
            outline/>
          <mcw-select
            class="select_dense"
            v-model="selectedOption"
            outlined>
            <mcw-list-item
              v-for="option of options"
              :key="option.id"
              :data-value="option.id"
              role="option">
              {{ option.label }}
            </mcw-list-item>
          </mcw-select>

          <mcw-button class="table__export-btn" @click="exportToExcel">
            <mcw-material-icon
              class="icon_base"
              icon="download">
            </mcw-material-icon>
            Экпорт в excel
          </mcw-button>
      </div>
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            <!-- <th class="mdc-data-table__header-cell mdc-data-table__header-cell--checkbox"
              role="columnheader"
              scope="col">
              <mcw-checkbox
                v-model="select"
                @change="selectAll"
                ref="rowCheckbox"
                class="mdc-data-table__header-row-checkbox">
              </mcw-checkbox>
            </th> -->
            <th class="mdc-data-table__header-cell">
              Подтвердить<br>
              <span class='with-wrap important-info' v-if='!nodes.length'>Необходимо штатное расписание</span>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('fio')">
              {{transcriptColumnTitle('fio')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort with-wrap" @click="sortTable('birthdate', 'date')">
              {{transcriptColumnTitle('birthdate')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('snils')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('foreigner')">
              {{transcriptColumnTitle('foreigner')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sor with-wrapt" @click="sortTable('poll_date_add', 'date')">
              {{transcriptColumnTitle('poll_date_add')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('poll_filled')">
              {{transcriptColumnTitle('poll_filled')}}
            </th>
            <th class="mdc-data-table__header-cell with-wrap">
              {{transcriptColumnTitle('poll_errors')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort with-wrap" @click="sortTable('date_vacc')">
              {{transcriptColumnTitle('date_vacc')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('name_vacc')">
              {{transcriptColumnTitle('name_vacc')}}
            </th>
          </tr>
        </thead>
        <tbody
          class="mdc-data-table__content">
          <tr class="mdc-data-table__row row_draggable"
            v-for="item in filteredItems"
            :key="item.id_self_record"
            v-show="!filteredData.length || filteredData.includes(item)"
            draggable="true"
            @dragstart="startDrag($event, item)">
            <!-- <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
              <mdc-checkbox
                class="mdc-data-table__row-checkbox"
                :value="item.id_people"
                ref="rowCheckbox"
                v-model="checkedItems">
              </mdc-checkbox>
            </td> -->
            <td class="table__cell">
              <mcw-button
                @click="approve(item.id_self_record, item.PrM_found)"
                :disabled="!nodes.length">
                <mcw-material-icon
                  :class="{icon_base: nodes.length}"
                  :icon="item.PrM_found ? 'task_alt' : 'radio_button_unchecked'">
                </mcw-material-icon>
              </mcw-button>
            </td>
            <td class="table__cell">
              {{joinFio(item.firstname, item.middlename, item.lastname)}}
            </td>
            <td class="table__cell">
              {{readableDate(item.birthdate)}}
            </td>
            <td class="table__cell">
              {{item.snils}}
            </td>
            <td class="table__cell">
              {{item.foreigner ? 'не РФ' : 'РФ'}}
            </td>
            <td class="table__cell">
              {{readableDate(item.poll_date_add)}}
            </td>
            <td class="table__cell">
              {{item.poll_filled}}
            </td>
            <td class="table__cell table__cell_slim">
                <ExpandableCell :content="item.poll_errors" maxWidth="80"/>
            </td>
            <td class="table__cell">
              {{readableDate(item.date_vacc)}}
            </td>
            <td class="table__cell">
              {{item.name_vacc}}
            </td>
          </tr>
        </tbody>
      </table>
    </mcw-data-table>
    <mcw-dialog
      v-model="isPeriodsOpened"
      escape-key-action="close">
        <SetPeriod
          @close="isPeriodsOpened = false"
          :initialRange="datesRange"
          @setRange="setQuestionedPeriod"/>
    </mcw-dialog>
  </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CommonTable from './CommonTable.vue';
import SetPeriod from '../SetPeriod.vue';
import ExpandableCell from '../ExpandableCell.vue';

export default {
  name: 'QuestionedTable.vue',
  extends: CommonTable,
  props: {
    items: Array,
    tableHeight: Number,
  },
  components: { SetPeriod, ExpandableCell },
  data() {
    return {
      title: 'Анкетированные',
      checkedItems: [],
      select: [],
      sortIndex: null,
      sortOrder: true,
      moveId: null,
      currentPatient: {},
      currentPatientInfo: [],
      isPeriodsOpened: false,
      searchString: '',
      visibleItems: [],
      step: 1,
      selectedOption: '1',
      options: [
        {
          label: 'Нераспределенные',
          id: '0',
        },
        {
          label: 'Все',
          id: '1',
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.visibleItems
        .filter((item) => {
          if (this.selectedOption === '0') {
            return !item.PrM_found;
          }
          return item;
        });
    },
    nodes() {
      return this.$store.getters['treeData/getData'];
    },
    datesRange: {
      get() {
        return this.$store.state.employeesData.patientsDatesRange;
      },
      set(range) {
        this.$store.commit('employeesData/SET_DATES_RANGE', range);
      },
    },
  },
  methods: {
    async approve(id, status) {
      if (!this.nodes.length) {
        return;
      }
      // const nodes = this.$store.getters['treeData/getData'];
      const notDistributedNode = this.nodes.find((item) => item.notDistributed);
      let cancel = 0;
      let amount = 1;
      if (status) {
        cancel = 1;
        amount = -1;
      }
      const params = {
        id_self_record: id,
        cancel,
      };
      const approve = await this.$store.dispatch('employeesData/approveSelfRecord', { params, source: 1 });
      if (approve) {
        this.$store.dispatch('employeesData/getQuestioned');
        if (notDistributedNode) {
          this.$store.commit('treeData/SET_AMOUNT', {
            id: notDistributedNode.id,
            amount,
          });
        } else {
          await this.$store.dispatch('treeData/getNotDistributedNode');
        }
      } else {
        this.emitter.emit('message', 'Не удалось подтвердить принадлежность анкетированного');
      }
    },
    joinFio(first, middle, last) {
      return `${last} ${first} ${middle}`;
    },
    closeTable() {
      this.$emit('hideQuestioned');
    },
    setQuestionedPeriod(val) {
      const period = {
        date_from: val.start,
        date_till: val.end,
      };
      this.getQuestioned(period);
    },
    ...mapActions('employeesData', ['getQuestioned']),
  },
};
</script>

<style lang="scss">
.table__cell_slim{
  max-width: 160px;
  overflow: hidden;
  height: 36.5px;
  &:hover{
    overflow: visible;
  }
}
.poll-errors{
  width: 100%;
  white-space: normal;
}
.table__title {
  margin: 0;
  margin-right: 1rem;
}
.mdc-typography--subtitle1 {
  display: flex;
}
.table__caption-top {
  display: flex;
}
.table__filter {
  margin-left: 0;
}
.select_dense {
  margin-top: 0 !important;
}
.with-wrap {
  width: 100px;
  white-space: break-spaces !important;
}
#questioned-table .mdc-data-table__header-cell {
  text-align: center;
}
.important-info {
  color: #cf1919;
}
</style>
