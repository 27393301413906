<template>
  <mcw-dialog-title style="white-space: no-wrap">{{title}} {{name}}:</mcw-dialog-title>
  <mcw-dialog-footer>
    <mcw-dialog-button
      action="accept"
      isDefault
      @click="move(0)">
      переместить
    </mcw-dialog-button>
    <mcw-dialog-button
      action="accept"
      isDefault
      @click="move(1)">
      скопировать
    </mcw-dialog-button>
    <mcw-dialog-button action="dismiss" @click="$emit('finishEditing')">отмена</mcw-dialog-button>
  </mcw-dialog-footer>
</template>

<script>
import { utils } from '../helpers/utils';

export default {
  name: 'AttachPatient',
  mixins: [
    utils,
  ],
  emits: ['finishEditing'],
  props: {
    title: String,
    patients: Array,
    moveId: Number,
    tableType: String,
    division: Number,
    position: Number,
  },
  computed: {
    name() {
      if (this.patients.length) {
        let name = `${this.patients.length} сотрудниками`;
        if (this.patients.length === 1) {
          name = this.patient(this.patients[0]) ? this.trimName(this.patient(this.patients[0]).fio) : '';
        }
        return name;
      }
      return null;
    },
  },
  methods: {
    async move(type) {
      const existedStaff = await this.$store.dispatch('employeesData/loadPatients', { id: this.moveId, type: 0, commit: true });
      const uniquePatients = this.patients.map((patient) => {
        if (!existedStaff.body.some((item) => item.id_people === patient)) {
          let fullDataPatient;
          if (this.tableType === 'allPatients') {
            fullDataPatient = this.$store.getters['employeesData/getPatientFromAll'](patient);
          } else {
            fullDataPatient = this.$store.getters['employeesData/getPatientById'](patient);
          }
          return fullDataPatient.id_tree_people;
        }
        return false;
      });
      const patients = uniquePatients.filter((item) => item !== false);
      if (!patients.length) {
        return;
      }
      const params = {
        type,
        patients,
        newNode: this.moveId,
      };
      const result = await this.$store.dispatch('employeesData/movePatients', params);
      if (result.every((item) => item.value.errorType === 0)) {
        this.$store.commit('treeData/SET_AMOUNT', { id: this.moveId, amount: result.length });
        if (type === 0) {
          this.$store.commit('treeData/SET_AMOUNT', { id: this.position, amount: -result.length });
          this.$store.commit('employeesData/REMOVE_CURRENT_PATIENTS', patients);
        }
      }
      this.$emit('finishEditing');
    },
    patient(id) {
      if (this.tableType === 'allPatients') {
        return this.$store.getters['employeesData/getPatientFromAll'](id);
      }
      return this.$store.getters['employeesData/getPatientById'](id);
    },
  },
};
</script>

<style>
  .mdc-dialog__title{
    white-space: nowrap;
  }
</style>
