<template>
  <div class="calculations">
    <mdc-data-table
      sticky
      v-if="items.length">
      <div class="table__header">
      </div>
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('default_calculation')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('date_add')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
              {{transcriptColumnTitle('total_price')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
              {{transcriptColumnTitle('avg_price')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('author')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('type_source')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('therapist')}}
            </th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content">
          <tr
            v-for="calc in items"
            :key="calc.id_calc"
            :class="{calculation_default: calc.default_calculation}">
            <td class="mdc-data-table__cell">
              <mcw-button
                @click="setDefault(calc.id_calc)">
                <mcw-material-icon
                  class="icon_base"
                  :icon="calc.default_calculation ? 'task_alt' : 'radio_button_unchecked'">
                </mcw-material-icon>
              </mcw-button>
            </td>
            <td class="mdc-data-table__cell">
              {{calc.date_add}}
            </td>
            <td
              class="mdc-data-table__cell mdc-data-table__cell--numeric"
              @dblclick="editCalc(calc.id_calc, 'total_price')">
              {{calc.total_price}}
            </td>
            <td
              class="mdc-data-table__cell mdc-data-table__cell--numeric"
              @dblclick="editCalc(calc.id_calc, 'avg_price')">
              {{calc.avg_price}}
            </td>
            <td class="mdc-data-table__cell">
              {{calc.author}}
            </td>
            <td class="mdc-data-table__cell">
              {{calc.type_source}}
            </td>
            <td class="mdc-data-table__cell">
              {{calc.therapist}}
            </td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <div class="doc-field__notice" v-if="!items.length && notice">
      Нет расчетов по этому договору
    </div>
  </div>
  <mcw-dialog
    v-model="editCalcModal"
    escape-key-action="close">
    <mcw-dialog-title>Новая {{label}} цена</mcw-dialog-title>
    <mcw-dialog-content>
      <mcw-textfield
        v-model="newPrice"
        label="Новая цена"
        type="number"
      ></mcw-textfield>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button
        action="dismiss"
        @click="closeModal()">
        отмена
      </mcw-dialog-button>
      <mcw-dialog-button
        action="accept"
        :disabled="!newPrice"
        @click="saveNewPrice()">
          сохранить
        </mcw-dialog-button>
    </mcw-dialog-footer>
  </mcw-dialog>
</template>

<script>
import { transcriptColumnTitle } from '../../helpers/transcriptor';

const typesMap = {
  total_price: 'итоговая',
  avg_price: 'средняя',
};
export default {
  name: 'Calculations',
  props: {
    contractId: Number,
    notice: Boolean,
    items: Array,
    tableHeight: Number,
    loading: Boolean,
  },
  emits: [
    'refresh',
  ],
  data() {
    return {
      editCalcModal: false,
      newPrice: 0,
      changeId: null,
      changeType: null,
      label: '',
    };
  },
  methods: {
    transcriptColumnTitle(column) {
      return transcriptColumnTitle(column);
    },
    async setDefault(id) {
      const { contractId } = this;
      const refreshedData = await this.$store.dispatch('contractsData/setDefaultCalculation', { id, contractId });
      if (refreshedData) {
        this.$emit('refresh');
      }
    },
    editCalc(id, type) {
      this.editCalcModal = true;
      this.changeId = id;
      this.changeType = type;
      this.label = typesMap[this.changeType];
    },
    closeModal() {
      this.editCalcModal = false;
      this.changeId = null;
      this.changeType = null;
      this.newPrice = 0;
    },
    async saveNewPrice() {
      const params = {
        id_calc: this.changeId,
      };
      params[this.changeType] = this.newPrice;
      const result = await this.$store.dispatch('contractsData/changeCalculation', params);
      if (result) {
        this.closeModal();
        this.emitter.emit('message', 'Новая цена сохранена');
        this.$emit('refresh');
      } else {
        this.emitter.emit('message', 'Не удалось сохранить новую цену');
      }
    },
  },
};
</script>

<style scoped>
.calculation_default{
  color: var(--mdc-theme-primary);
  font-weight: 700;
}
</style>
