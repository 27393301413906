<template>
  <div class="doc-send" onselectstart="return false" onclick="event.stopPropagation()">
    <mcw-dialog-title>
      Выберите адресата
      <router-link to="/contacts">
        <mcw-button @click="$emit('close')">
          добавить контакт
        </mcw-button>
      </router-link>
      <mcw-icon-button class="btn_close" @click="$emit('close')">
        <mcw-material-icon icon="close" />
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content>
    <div
      class="doc-send__notice"
      v-if="!contacts.length">
      Нет контактов, которым можно было бы отправить документ
    </div>
    <div class="doc-send" v-else>
      <div class="doc-send__copy">
        <mcw-checkbox
          class="doc-send__checkbox"
          v-model="copyMe"
          label="Отправить копию себе:" />
      </div>
      <mcw-data-table>
        <table class="mdc-data-table__table">
          <thead>
            <tr class="mdc-data-table__header-row">
              <th class="mdc-data-table__header-cell" v-if="!sendToPatient">ФИО</th>
              <th class="mdc-data-table__header-cell">Контакт</th>
              <th class="mdc-data-table__header-cell">Примечание</th>
            </tr>
          </thead>
          <tbody class="mdc-data-table__content">
            <template
              v-for="contact in contacts"
              :key="contact.id_contact">
              <tr
                class="mdc-data-table__row doc-send__contact"
                @click.stop="selectRow(contact.id_contact)"
                >
                <td class="mdc-data-table__cell" v-if="!sendToPatient">{{contact.fio}}</td>
                <td class="mdc-data-table__cell">{{contact.val}}</td>
                <td class="mdc-data-table__cell">{{contact.note}}</td>
              </tr>
              <tr v-if="selected === contact.id_contact">
                <td colspan="2">
                  <mcw-textfield
                    v-model="txtNote"
                    label="Сообщение"
                    outline
                    multiline rows="4" cols="20" />
                </td>
                <td>
                  <mcw-button
                    @click="send(contact.id_contact)">
                    <mcw-material-icon icon="send" class="icon_base"></mcw-material-icon>
                    отправить
                  </mcw-button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </mcw-data-table>
      <mcw-button
        :disabled="!sendLog.length"
        @click="showLog = !showLog">
        история отправок
      </mcw-button>
        <table class="mdc-data-table__table"
          v-if="sendLog.length"
          v-show="showLog">
          <thead>
            <tr class="mdc-data-table__header-row">
              <th class="mdc-data-table__header-cell">
                Дата
              </th>
              <th class="mdc-data-table__header-cell">
                Кому
              </th>
              <th class="mdc-data-table__header-cell">
                Кем
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="mdc-data-table__row"
              v-for="(item, index) in sendLog"
              :key="index">
              <td class="mdc-data-table__cell">
                {{item.date_sent}}
              </td>
              <td class="mdc-data-table__cell">
                {{item.destination}}
              </td>
              <td class="mdc-data-table__cell">
                {{item.login_sent}}
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button @click="$emit('close')" isDefault>отмена</mcw-dialog-button>
    </mcw-dialog-footer>
  </div>
</template>

<script>

export default {
  name: 'DocSend',
  emits: [
    'close',
  ],
  props: {
    docId: Number,
    sendToPatient: Boolean,
    idPatient: Number,
    idPeople: Number,
  },
  data() {
    return {
      copyMe: false,
      selected: null,
      txtNote: '',
      contacts: [],
      sendLog: [],
      showLog: false,
    };
  },
  async mounted() {
    if (this.sendToPatient) {
      const params = {
        id_people: this.idPeople,
        id_patient: this.idPatient,
      };
      this.contacts = await this.$store.dispatch('orgsData/getEmail', params);
    } else {
      this.contacts = this.$store.getters['contractsData/getEmails'];
    }
    this.sendLog = await this.$store.dispatch('contractsData/getDocSendLog', this.docId);
  },
  methods: {
    selectRow(id) {
      this.selected = id;
    },
    async send(contactId) {
      const success = await this.$store.dispatch('contractsData/sendDocToContact', {
        contactId, txtNote: this.txtNote, docId: this.docId, copyMe: this.copyMe,
      });
      if (success) {
        this.emitter.emit('message', 'Документ отправлен');
        this.$emit('close');
      } else {
        this.emitter.emit('message', 'Не удалось отправить документ');
      }
    },
  },
};
</script>

<style lang="scss">
  .doc-send{
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    &__contact{
      cursor: pointer;
    }
    &__info{
      margin-right: 10px;
      display: flex;
    }
    &__checkbox{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
  }
</style>
