<template>
  <div class="available-branches__branch"
    :class="{
      'available-branches__branch_on': availability,
      'available-branches__branch_off': !availability
    }">
    <mcw-checkbox
      v-model="availability"
      @change="save"/>
    {{branch.name_branch}} / {{branch.adress_branch}}
  </div>
</template>

<script>
export default {
  name: 'SingleBranch',
  props: {
    branch: Object,
    idContract: Number,
  },
  data() {
    return {
      availability: false,
    };
  },
  created() {
    this.availability = !!this.branch.available;
  },
  methods: {
    async save() {
      const params = {
        param: this.availability ? 1 : 0,
        id_contract: this.idContract,
        id_branch: this.branch.id_branch,
      };
      const saveBranch = await this.$store.dispatch('testData/saveBranchAvailability', params);
      if (saveBranch) {
        this.$store.dispatch('testData/getContractBranches', this.idContract);
      }
    },
  },
};
</script>

<style lang="scss">
.available-branches{
  &__branch{
    border: 1px solid #aaa;
    margin: 5px;
    padding-right: 5px;
    border-radius: 10px;
    &_on{
      background: rgba(green, 0.12);
    }
    &_off{
      background: rgba(red, 0.12);
    }
  }
}
</style>
