<template>
  <div class="view contracts-view">
    <Contracts />
  </div>
</template>

<script>
import Contracts from '../components/Contracts/Contracts.vue';

export default {
  name: 'ContractsView',
  components: {
    Contracts,
  },
};
</script>

<style>
.contracts-view {
  display: flex!important;
}
</style>
