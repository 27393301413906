<template>
  <div class="page-not-found">
    <h1>Неверный адрес страницы</h1>
    <router-link to="/">
      На главную
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style lang="scss">
  .page-not-found{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
</style>
