<template>
  <mcw-select
    class="params-select select_full-width"
    v-model="selectedContractType"
    outlined
    :value="selectedContractType"
    @update:modelValue="onTypeChanged"
    label="Тип контракта"
    leading-icon="article">
    <mcw-list-item
      class="mcw-list-item--no-wrap"
      v-for="type in contractTypes"
      :key="type.id"
      :data-value="type.id"
      role="option"
      icon>
      {{ type.type_contract }}
    </mcw-list-item>
  </mcw-select>
  <mcw-select
    class="params-select select_full-width"
    v-model="selectedDoer"
    outlined
    :value="selectedDoer"
    @update:modelValue="onDoerChanged"
    label="Исполнитель"
    leading-icon="business_center">
    <mcw-list-item
      class="mcw-list-item--no-wrap"
      v-for="doer in doers"
      :key="doer.id_org"
      :data-value="doer.id_org"
      role="option"
      icon>
      {{ doer.org_name }}
    </mcw-list-item>
  </mcw-select>
</template>

<script>
export default {
  name: 'NewContractParams',
  emits: [
    'selectType',
    'selectDoer',
  ],
  props: {
    contractType: String,
    doer: String,
  },
  data() {
    return {
      selectedContractType: null,
      selectedDoer: null,
    };
  },
  created() {
    if (this.contractType) {
      this.selectedContractType = this.contractType;
    }
    if (this.doer) {
      this.selectedDoer = this.doer;
    }
  },
  computed: {
    contractTypes() {
      return this.$store.getters['orgsData/contractTypes'];
    },
    doers() {
      return this.$store.getters['orgsData/doers'];
    },
  },
  methods: {
    onTypeChanged() {
      this.$emit('selectType', this.selectedContractType);
    },
    onDoerChanged() {
      this.$emit('selectDoer', this.selectedDoer);
    },
  },
};
</script>

<style lang="scss">
.params-select{
  margin-top: 20px;
}
</style>
