import { mapState } from 'vuex';
import ContractDocs from '../ContractDocs.vue';
import EditContract from '../EditContract/EditContract.vue';
import Documents from '../Tabs/Documents.vue';
import Services from '../Tabs/Services.vue';
import Acts from '../Tabs/Acts.vue';
import Managers from '../Tabs/Managers.vue';
import ExpandableCell from '../ExpandableCell.vue';
import Spinner from '../Spinner.vue';
import Conclusions from '../Tabs/Conclusions.vue';
import Calculations from '../Tabs/Calculations.vue';
import { utils } from '../../helpers/utils';
import { resizer } from '../../helpers/resizerMixin';
import { transcriptColumnTitle } from '../../helpers/transcriptor';

export default {
  name: 'Contracts',
  mixins: [utils, resizer],
  components: {
    ContractDocs,
    Documents,
    Services,
    Acts,
    Managers,
    EditContract,
    ExpandableCell,
    Spinner,
    Conclusions,
    Calculations,
  },
  data() {
    return {
      tabsHeight: 0,
      selectedType: '2',
      showConfirmDialog: false,
      orgToFollow: null,
      types: [
        {
          label: 'Активные',
          value: '2',
        },
        {
          label: 'Все',
          value: '0',
        },
        {
          label: 'Архив',
          value: '1',
        },
      ],
      isLoading: false,
      showTooltipP: false,
      showTooltipD: false,
      showTooltipU: false,
      showTooltipA: false,
      showTooltipZA: false,
      currentContractName: '',
      isActionsShown: 0,
      isEditContractOpen: false,
      tabs: [
        {
          id: 1, // 4
          label: 'Заключения',
          component: 'Conclusions',
        },
        {
          id: 2, // 1
          label: 'Документы',
          component: 'Documents',
        },
        {
          id: 3, // 2
          label: 'Услуги',
          component: 'Services',
        },
        {
          id: 6,
          label: 'расчеты',
          component: 'Calculations',
        },
        {
          id: 4, // 3
          label: 'Оплаты/Акты',
          component: 'Acts',
        },
        {
          id: 5,
          label: 'Менеджеры',
          component: 'Managers',
        },
      ],
      activeTab: {
        id: 1,
        label: 'Документы',
        component: 'Documents',
      },
      tableItems: [],
      rerender: false,
      tabLoading: false,
      searchString: '',
    };
  },
  computed: {
    userRank() {
      return this.$store.getters['user/getUserRank'];
    },
    firstTabsRow() {
      return this.tabs.slice(0, 3);
    },
    secondTabsRow() {
      return this.tabs.slice(3, 6);
    },
    companyName() {
      return this.$store.getters['user/getSelectedCompanyName'];
    },
    contracts() {
      return this.$store.getters['contractsData/getContracts'];
    },
    filteredContracts() {
      if (!this.searchString.length) {
        return this.contracts;
      }
      return this.contracts.filter((contract) => contract.id_contract.toString().includes(this.searchString)
        || contract.N_contract.includes(this.searchString)
        || contract.name_contract.includes(this.searchString));
    },
    currentContract: {
      get() {
        return this.$store.state.contractsData.currentContract;
      },
      set(id) {
        let newId = id;
        if (id === 0) {
          newId = '0';
        }
        this.$store.commit('contractsData/SET_CURRENT_CONTRACT', `${newId}`);
      },
    },
    services() {
      return this.$store.getters['contractsData/getServices'];
    },
    acts() {
      return this.$store.getters['contractsData/getActs'];
    },
    managers() {
      return this.$store.getters['contractsData/getManagers'];
    },
    ...mapState({
      actsProcessing: (state) => state.contractsData.actsProcessing,
      prcessing: (state) => state.contractsData.processing,
    }),
    calculations() {
      return this.$store.getters['contractsData/getCalculations'];
    },
    selectedSubdoer() {
      return this.$store.state.contractsData.subdoersBinding.subdoer;
    },
    orgsList() {
      return this.$store.getters['user/companiesList'];
    },
    currentOrg() {
      return this.$store.getters['user/getSelectedCompanyId'];
    },
  },
  watch: {
    companyName(val) {
      this.$nextTick(() => {
        this.setTabsHeight();
      });
    },
    prcessing(bool) {
      if (bool) {
        this.isLoading = true;
      } else this.isLoading = false;
    },
  },
  mounted() {
    this.setTabsHeight();
    this.$refs.resizer.addEventListener('mousedown', this.startResize);
    // this.emitter.on('loadAllActs', this.loadAllActs);
  },
  methods: {
    setContractType() {
      this.$store.commit('contractsData/SET_CONTRACT_TYPE_FOR_LOAD', this.selectedType);
      this.$store.dispatch('contractsData/getContractsList');
    },
    loadContracts() {
      this.$store.dispatch('contractsData/getContractsList');
    },
    followToOrg() {
      this.emitter.emit('selectNewOrg', this.orgToFollow);
    },
    async showChangeOrgDialog(org) {
      if (this.currentOrg === org.id_org) {
        return;
      }
      const orgExistsInList = this.orgsList.find((organization) => organization.id_org === org.id_org);
      if (!orgExistsInList) {
        if (this.userRank > 100) {
          const backendSearch = await this.$store.dispatch('user/getAdditionalCompanies', org.org);
          const newOrg = backendSearch.find((item) => item.id_org === org.id_org);
          if (newOrg) {
            await this.$store.dispatch('user/addOrgIntoOrgsList', newOrg.id_org);
            this.$store.commit('user/SET_NEW_COMPANY', newOrg);
            this.emitter.emit('selectNewOrg', newOrg);
          }
        } else {
          this.emitter.emit('message', `Нет доступа к ${org.org}`);
          return;
        }
      }
      this.showConfirmDialog = true;
      this.orgToFollow = org;
    },
    extractDealsides(dealsides) {
      try {
        return JSON.parse(dealsides) || [];
      } catch (error) {
        console.log(error);
      }
    },
    async loadAllActs() {
      await this.$store.dispatch('contractsData/getActs', 0);
      this.tableItems = this[this.activeTab.component.toLowerCase()];
      this.emitter.emit('allActsLoaded');
    },
    transcriptColumnTitle(str) {
      return transcriptColumnTitle(str);
    },
    async getComponentsData(id) {
      if (this.currentContract) {
        switch (id) {
          case 1:
            await this.$store.dispatch('contractsData/getConclusions', this.currentContract);
            break;
          case 2:
            await this.$store.dispatch('contractsData/getDocuments', this.currentContract);
            break;
          case 3:
            const params = {
              id_contract: this.currentContract,
            };
            if (this.selectedSubdoer) {
              params.id_contract_sub_doer = this.selectedSubdoer;
            }
            await this.$store.dispatch('contractsData/getServices', params);
            break;
          case 4:
            await this.$store.dispatch('contractsData/getActs', this.currentContract);
            break;
          case 5:
            await this.$store.dispatch('contractsData/getAgents', this.currentContract);
            break;
          case 6:
            await this.$store.dispatch('contractsData/getCalculations', this.currentContract);
            break;
          default:
            break;
        }
      }
    },
    setTabsHeight() {
      this.$nextTick(() => {
        const tableHeader = this.$refs.header;
        // if (tableHeader.clientHeight < 50) {
        //   tableHeader.style.height = '50px';
        // }
        const headerHeight = tableHeader.clientHeight;
        this.tabsHeight = headerHeight - 8;
      });
    },
    async onTabSelect(idx) {
      this.rerender = true;
      this.activeTab = this.tabs[idx];
      await this.getComponentsData(this.activeTab.id);
      this.tableItems = this[this.activeTab.component.toLowerCase()];
      this.rerender = false;
    },
    async refresh() {
      this.tabLoading = true;
      await this.getComponentsData(this.activeTab.id);
      this.tableItems = this[this.activeTab.component.toLowerCase()];
      this.tabLoading = false;
    },
    date(str) {
      if (!str || str.indexOf('1900-01-01') !== -1) {
        return '';
      }
      return this.readableDate(str);
    },
    toggleActions(id) {
      this.isActionsShown = id;
    },
    async selectContract(id) {
      if (this.currentContract === `${id}`) {
        this.currentContract = '';
        this.$store.commit('contractsData/RESET_CONTRACT_DATA');
        await this.$store.dispatch('employeesData/getLastPatients', { includeServices: false });
        return false;
      }
      this.isLoading = true;
      if (!this.currentContract) {
        this.currentContract = `${id}`;
        await this.getComponentsData(1);
      } else {
        this.currentContract = `${id}`;
      }
      await this.getComponentsData(this.activeTab.id);
      this.tableItems = this[this.activeTab.component.toLowerCase()];
      this.isLoading = false;
    },
    addContract() {
      this.currentContract = '';
      this.isEditContractOpen = true;
    },
    editContract(id) {
      this.isEditContractOpen = true;
    },
  },
};
