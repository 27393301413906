import { createRouter, createWebHistory } from 'vue-router';
import Structure from '../views/HomeView.vue';
import Contracts from '../views/ContractsView.vue';
import Contacts from '../views/ContactsView.vue';
import Orgs from '../views/OrgsView.vue';
import PageNotFound from '../views/PageNotFound.vue';
import store from '../store/index';

const base = process.env.VUE_APP_BASE_URL;

const routes = [
  {
    path: '/',
    name: 'Contracts',
    component: Contracts,
  },
  {
    path: '/structure',
    name: 'Structure',
    component: Structure,
    props: true,
    beforeEnter(to, from, next) {
      if (!store.state.employeesData.allPatients.length
        && !store.state.employeesData.employees.length
        && !store.state.employeesData.questioned.length) {
        store.dispatch('employeesData/getLastPatients', { includeServices: false });
      }
      next();
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: '/orgs',
    name: 'Orgs',
    component: Orgs,
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(base),
  routes,
});

export default router;
