import exceljs from 'exceljs';

export async function createJsonFromExcel(file) {
  const cellValueToDict2 = (keys, row) => {
    const data = {};
    row.eachCell((cell, colNumber) => {
      let { value } = cell;
      // eslint-disable-next-line no-prototype-builtins
      if (typeof value === 'object' && value.hasOwnProperty('text')) {
        value = value.text;
      }
      data[colNumber] = value;
    });
    return data;
  };
  const changeRowsToDict = (worksheet) => {
    const dataArray = [];
    let keys = [];
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) {
        keys = [...Array(row.values.length).keys()];
      } else {
        const rowDict = cellValueToDict2(keys, row);
        dataArray.push(rowDict);
      }
    });
    return dataArray;
  };
  return new Promise((resolve, reject) => {
    const workbook = new exceljs.Workbook();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const buffer = e.target.result;
      workbook.xlsx.load(buffer)
        .then(async (wb) => {
          const worksheet = wb.getWorksheet(1);
          const dataArray = changeRowsToDict(worksheet);
          resolve(dataArray);
        })
        .catch((error) => {
          console.log(error);
        });
    };
  });
}
