<template>
  <section class='cloning-node__modal'>
    <mcw-dialog-title class="modal__title">
      Клонирование подразделения: <b>{{selectedNode.text}}</b>
      <mcw-icon-button>
        <mcw-material-icon icon="close" @click="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content>
      <mcw-textfield v-model="text" label="Название" helptext='Укажите, как будет называться клонированное подразделение ' />
      <mcw-button v-if='!loading' @click="tryToCloneNode" :disabled="!text">
        <mcw-material-icon icon="account_tree"></mcw-material-icon>
        клонировать
      </mcw-button>
      <Spinner v-show="loading"/>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button isDefault action="dismiss">выйти</mcw-dialog-button>
    </mcw-dialog-footer>
  </section>
</template>

<script>
import Spinner from './Spinner.vue';

export default {
  name: 'CloningNode',
  components: { Spinner },
  props: {
    selectedNode: Object,
  },
  emits: [
    'closeCloningDialog',
  ],
  data() {
    return {
      text: '',
      loading: false,
    };
  },
  computed: {},
  methods: {
    close() {
      this.emitter.emit('closeCloningDialog');
    },
    async tryToCloneNode() {
      this.loading = true;
      const result = await this.$store.dispatch('treeData/cloneNode', { id_tree: this.selectedNode.id, text: this.text });
      if (result) {
        await this.$store.dispatch('treeData/loadTree');
        this.text = '';
        this.close();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.cloning-node {
  &__modal {
    min-width: 400px !important;
  }
}
</style>
