<template>
  <mcw-data-table v-if="documents.length">
  <table class="mdc-data-table__table" aria-label="Dessert calories">
    <thead>
      <tr class="mdc-data-table__header-row">
        <th class="mdc-data-table__header-cell" role="columnheader" scope="col">
        </th>
        <th class="mdc-data-table__header-cell" role="columnheader" scope="col">
          Название
        </th>
        <th class="mdc-data-table__header-cell" role="columnheader" scope="col">
          Автор
        </th>
        <th class="mdc-data-table__header-cell" role="columnheader" scope="col">
          Дата
        </th>
      </tr>
    </thead>
    <tbody class="mdc-data-table__content">
      <template v-for="doc in documents" :key="doc.id_doc">
        <tr class="mdc-data-table__row doc-field" @click="activeId = doc.id_doc">
          <td class="mdc-data-table__cell">
            <mcw-material-icon
              :icon="doc.is_attached ? 'attach_file' : 'note_add'"
              class="icon_base">
            </mcw-material-icon>
          </td>
          <td class="mdc-data-table__cell">{{doc.doc_name}}</td>
          <td class="mdc-data-table__cell">{{doc.staff}}</td>
          <td class="mdc-data-table__cell">{{doc.d}}</td>
        </tr>
        <tr>
          <td colspan="3">
            <div
              class="doc-field__actions"
              v-if="activeId === doc.id_doc">
              <mcw-button
                class="doc-field__btn"
                @click="downloadDoc(doc)">
                <mcw-material-icon icon="download" class="icon_base"></mcw-material-icon>
                Скачать
              </mcw-button>
                <mcw-button
                  class="doc-field__btn"
                  @click="isSendVisible = true">
                  <mcw-material-icon icon="send" class="icon_base"></mcw-material-icon>
                  Отправить
                </mcw-button>
                <mcw-dialog v-model="isSendVisible" scrollable>
                  <DocSend  v-if="isSendVisible" :docId="doc.id_doc" @close="isSendVisible = false"/>
                </mcw-dialog>
              <mcw-button
                class="doc-field__btn"
                @click="removeDoc(doc)">
                <mcw-material-icon
                  icon="delete_forever"
                  class="icon_base">
                </mcw-material-icon>
                Удалить
              </mcw-button>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</mcw-data-table>
<div class="doc-field__notice" v-if="!documents.length && notice">
  Нет документов по этому договору
</div>
</template>

<script>
import { BASE_URL } from '../../helpers/Api';
import DocSend from '../DocSend.vue';

export default {
  name: 'Documents',
  props: {
    contractId: Number,
    notice: Boolean,
    items: Array,
    tableHeight: Number,
    loading: Boolean,
  },
  emits: [
    'refresh',
  ],
  components: {
    DocSend,
  },
  data() {
    return {
      activeId: null,
      isSendVisible: false,
    };
  },
  computed: {
    documents() {
      return this.$store.getters['contractsData/getDocuments'];
    },
  },
  methods: {
    async downloadDoc(doc) {
      const path = await this.$store.dispatch('getDocPath', doc.id_doc);
      const file = await this.$store.dispatch('getFile', path.hash);
      console.log(file);
      if (file) {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([file]));
        link.href = url;
        link.setAttribute('download', `${doc.doc_name}${path.extension}`);
        document.body.append(link);
        link.click();
        link.remove();
      } else {
        this.emitter.emit('message', 'Файл не найден!');
      }
    },
    async removeDoc(doc) {
      const remove = await this.$store.dispatch('contractsData/removeDocument', { id: doc.id_doc, contractId: this.contractId });
      if (remove) {
        this.emitter.emit('message', 'Файл удален успешно!');
      } else {
        this.emitter.emit('message', 'Вы не можете удалить этот файл');
      }
    },
  },
};
</script>

<style lang="scss">
  .doc-field{
    cursor: pointer;
    &__notice{
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
</style>
