<template>
  <div class="spinner-wrapper">
    <mcw-circular-progress indeterminate class="spinner"/>
  </div>
</template>

<script>

</script>

<style lang="scss">
  .spinner-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211,211,211, 0.3);
    z-index: 100;
    & .mdc-circular-progress{
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 24px);
      z-index: 200;
    }
  }
</style>
