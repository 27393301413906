<template>
  <div class="home-view view">
    <AppMain/>
  </div>
</template>

<script>
import AppMain from '../components/AppMain/AppMain.vue';

export default {
  name: 'HomeView',
  components: {
    AppMain,
  },
};
</script>

<style>
.home-view {
  display: flex!important;
}
</style>
