<template>
  <mcw-dialog-title>Получение доступа</mcw-dialog-title>
  <mcw-dialog-content>
    <div>
      В настоящий момент структуру {{companyName}} редактирует {{currentRedactor}}.
      Нужно получить монопольные права на редактирование?
    </div>
  </mcw-dialog-content>

  <mcw-dialog-footer>
    <mcw-dialog-button action="dismiss" tab-index="0">отмена</mcw-dialog-button>
    <mcw-dialog-button
      action="accept"
      isDefault
      @click="getAccess">
      получить доступ
    </mcw-dialog-button>
  </mcw-dialog-footer>
</template>

<script>
export default {
  name: 'GetAccess',
  computed: {
    companyName() {
      const company = this.$store.state.user.selectedCompany;
      if (company) {
        return company.org;
      }
      return null;
    },
    currentRedactor() {
      const redactor = this.$store.getters['treeData/getCurrentRedactor'];
      if (redactor) {
        return redactor.persons;
      }
      return null;
    },
  },
  methods: {
    getAccess() {
      this.$store.dispatch('treeData/setTreeAccess');
    },
  },
};
</script>

<style lang="scss">
@use "@material/button/mdc-button";
@use "@material/dialog/mdc-dialog";
</style>
