<template>
  <div class="interview-link">
    <mcw-dialog-title>
      Создать QR для анкеты
      <mcw-icon-button class="btn_close" @click="$emit('close')">
        <mcw-material-icon icon="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content>
      <div class="interview-link_existed"
        v-if="links.length">
        <span v-for="link in links" :key="link">{{link}}<br/></span>
      </div>
      <mcw-select
        v-if='!rerender'
        class="select_dense interview-link__select"
        v-model="selectedBranch"
        outlined
        label="Филиал">
        <mcw-list-item
          v-for="option in branchList"
          :key="option.id_branch"
          :data-value="option.id_branch"
          role="option"
          >{{ option.name_branch }}
        </mcw-list-item>
      </mcw-select>
      <mcw-select
        v-if='selectedPollType !== "3"'
        class="select_dense interview-link__select"
        v-model="selectedPollType"
        outlined
        label="Тип анкеты">
        <mcw-list-item
          v-for="option in pollTypes"
          :key="option.id_poll_type"
          :data-value="option.id_poll_type"
          role="option"
          >{{ option.poll_type }}
        </mcw-list-item>
      </mcw-select>
      <mcw-select
        v-if='selectedPollType !== "3"'
        class="select_dense interview-link__select"
        v-model="selectedVaccine"
        label="Вакцина"
        outlined>
        <mcw-list-item
          v-for="option in vaccines"
          :key="option.link_type"
          :data-value="option.link_type"
          role="option"
          >{{ option.name_vacc }}
        </mcw-list-item>
      </mcw-select>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button action="dismiss">Отмена</mcw-dialog-button>
      <mcw-dialog-button
        :disabled="!sendButtonEnable"
        isDefault
        @click="saveLink">
        создать
      </mcw-dialog-button>
    </mcw-dialog-footer>
  </div>
</template>

<script>

export default {
  name: 'Interviewlink',
  props: {
    contractId: Number,
  },
  emits: [
    'close',
  ],
  data() {
    return {
      selectedBranch: null,
      rerender: false,
      selectedPollType: null,
      selectedVaccine: null,
      links: [],
      pollTypes: null,
    };
  },
  async mounted() {
    const links = [this.contract.QR_RF, this.contract.QR_foreign];
    this.links = links.map((item) => {
      if (!item) {
        return '';
      }
      return item.split(', ');
    }).flat()
      .filter((item) => item !== '');
    this.pollTypes = await this.$store.dispatch('testData/getPollTypes', this.contractId);
    if (this.pollTypes.length === 1) {
      this.rerender = true;
      this.selectedPollType = `${this.pollTypes[0].id_poll_type}`;
      this.$nextTick(() => {
        this.rerender = false;
      });
    }
  },
  computed: {
    vaccines() {
      return this.$store.getters['testData/getVaccines'];
    },
    branchList() {
      const list = this.$store.getters['testData/getBranchList'].filter((item) => item.available);
      if (!list.length) {
        list.push({
          id_branch: '0',
          name_branch: 'Нет доступных филиалов',
        });
      }
      return list;
    },
    sendButtonEnable() {
      if (this.selectedPollType === '3') {
        return !!this.selectedPollType;
      }
      return (!!this.selectedBranch && !!this.selectedPollType && !!this.selectedVaccine);
    },
    contract() {
      return this.$store.getters['contractsData/getContractById'](this.contractId);
    },
  },
  methods: {
    selectBranch(branch) {
      this.branch = branch;
    },
    async saveLink() {
      const params = {
        id_contract: this.contractId,
        id_branch: this.selectedBranch,
        id_poll_type: +this.selectedPollType,
        Name_event: 'interview_link',
      };
      if (this.selectedVaccine) {
        params.link_type = this.selectedVaccine;
      }
      const link = await this.$store.dispatch('testData/getLink', params);
      if (link) {
        this.emitter.emit('message', 'QR успешно создан');
        this.links.push(link);
        // this.$emit('close');
      } else {
        this.emitter.emit('message', 'Не удалось создать QR');
      }
    },
  },
};
</script>

<style lang="scss">
  .interview-link{
    &__select{
      margin-top: 20px;
    }
    & .mdc-dialog__content{
      overflow: initial;
    }
    &_existed{
      white-space: pre-wrap;
    }
  }
</style>
