export function daysInMonth(month) {
  return 32 - new Date(new Date().getFullYear(), month, 32).getDate();
}

export function setInitialDatesRange() {
  let datesRange = {};
  const date = new Date();
  const today = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  console.log(month);
  if (today <= 5) {
    if (month === 0) {
      datesRange = {
        start: new Date(year - 1, 11, 1),
        end: new Date(year, month, today),
      };
    } else {
      datesRange = {
        start: new Date(year, month - 1, 1),
        end: new Date(year, month, today),
      };
    }
  } else {
    datesRange = {
      start: new Date(year, month, 1),
      end: new Date(year, month, today),
    };
  }
  return {
    start: datesRange.start.toLocaleDateString('ru-RU'),
    end: datesRange.end.toLocaleDateString('ru-RU'),
  };
}
