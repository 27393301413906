export const TREE_URL = 'test';
export const NAME_APP = 'WEB_managers';
export const modules = {
  calculation: 'module_calculation',
  contacts: 'module_contacts',
  medAdmin: 'medAdmin',
  covid: 'Covid',
  patients: 'module_patients',
};
export const DESKTOP_BREAKPOINT = 1200;
export const transformAllpatientsTable = [
  {
    title: 'id_patient',
    newIndex: 0,
  },
  {
    title: 'date_vizit',
    newIndex: 1,
  },
  {
    title: 'fio',
    newIndex: 2,
  },
  {
    title: 'sum_pat',
    newIndex: 3,
  },
  {
    title: 'datebirth',
    newIndex: 4,
  },
];
export const transformServicesSubtable = [
  {
    title: 'id_service',
    newIndex: 0,
  },
  {
    title: 'date_add',
    newIndex: 1,
  },
  {
    title: 'short_name',
    newIndex: 2,
  },
  {
    title: 'price',
    newIndex: 3,
  },
];
export const RESIZER_SIZE = 13;

export const ACTS_STATUSES_MAP = {
  0: '',
  1: 'color: orange',
  2: 'color: green',
  3: 'color: green; font-weight: bold',
};

export const RECHECK_INT = 15000;

export const ORG_FIELDS_TO_EDIT = [
  'email_public',
  'tel',
  'okved',
];
