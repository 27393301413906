<template>
  <div class="doc-list__wrapper">
    <mcw-button
      :disabled="!docsAmount"
      raised
      unelevated
      @click.stop="showDocsList()"
      class="doc-list__button">
      <mcw-material-icon
        class="doc-list__icon"
        :icon="inProgress ? 'hourglass_empty' : 'feed'"/>
        <span class="doc-list__amount">{{ docsAmount }}</span>
    </mcw-button>
  </div>
  <mcw-menu v-model="isOpen" class="doc-list" style="overflow: initial">
    <PatientDocListItem
      v-for="(item, index) in items"
      :key="index"
      :document="item"
      @showSendDoc="showSendDoc"
      @docSigned="handleDocSigned"
    />
  </mcw-menu>
    <mcw-dialog v-model="isSendVisible" scrollable>
      <DocSend
        v-if="isSendVisible"
        :docId="doc"
        :idPatient="id"
        :idPeople="patientData.id_people"
        sendToPatient
        @close="isSendVisible = false"/>
    </mcw-dialog>
</template>

<script>
import { RECHECK_INT } from '@/constants';
import DocSend from './DocSend.vue';
import PatientDocListItem from './PatientDocListItem.vue';

export default {
  name: 'PatientDocList',
  props: {
    patientData: Object,
  },
  emits: [
    'refresh',
    'closeOthers',
  ],
  components: {
    DocSend,
    PatientDocListItem,
  },
  data() {
    return {
      items: [],
      isOpen: false,
      isSendVisible: false,
      doc: null,
      nonExistedItems: [],
      signingId: null,
      signs: [],
      signsMenuOpen: false,
    };
  },
  computed: {
    id() {
      return this.patientData.id_patient;
    },
    docsAmount() {
      return this.patientData.n_docs;
    },
    inProgress() {
      return !this.items.every((item) => !!item.isExist);
    },
  },
  watch: {
    nonExistedItems() {
      if (!this.nonExistedItems.length) {
        return false;
      }
      this.recheckFiles();
    },
  },
  async created() {
    await this.loadDocList();

    const checkPromises = this.items.map((item) => {
      const promise = Promise.resolve(this.checkFile(item.id_document));
      return promise.then((res) => {
        const bool = Boolean(res);
        item.isExist = bool;
        if (!bool) {
          this.nonExistedItems.push(item.id_document);
        }
      });
    });
    Promise.all(checkPromises).then(() => {
      if (this.nonExistedItems.length) {
        this.recheckFiles();
      }
    });
    this.emitter.on('closeDocLists', this.closeIfInactive);
  },
  methods: {
    recheckFiles() {
      const interval = setInterval(async () => {
        this.nonExistedItems.forEach((id) => {
          const promise = Promise.resolve(this.checkFile(id));
          promise.then((res) => {
            const bool = Boolean(res);
            if (bool) {
              this.items.find((item) => item.id_document === id).isExist = bool;
              const index = this.nonExistedItems.indexOf(id);
              this.nonExistedItems.splice(index, 1);
            }
          });
        });
        if (!this.nonExistedItems.length) {
          clearInterval(interval);
        }
      }, RECHECK_INT);
    },
    async checkFile(id) {
      const path = await this.getFilePath(id);
      const result = await this.$store.dispatch('checkIfDone', { str_param: JSON.stringify(path.hash) });
      if (result) {
        return result.all_done;
      }
      return false;
    },
    async loadDocList() {
      const docsList = await this.$store.dispatch('employeesData/getDocsList', this.id);
      if (docsList.length) {
        this.items = docsList;
      }
    },
    async showDocsList() {
      this.$emit('closeOthers', this.id);
      this.isOpen = true;
    },
    closeIfInactive(id) {
      if (id !== this.id) {
        this.isOpen = false;
      }
    },
    async getFilePath(id) {
      const path = await this.$store.dispatch('getDocPath', id);
      if (path) {
        return path;
      }
      return null;
    },
    showSendDoc(item) {
      this.getPatientDoc(item);
      this.isOpen = false;
    },
    getPatientDoc(item) {
      this.doc = item.id_document;
      this.isSendVisible = true;
    },
    fileNotFound() {
      this.emitter.emit('message', 'Файл не найден!');
    },
    handleDocSigned() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
@use '@material/list/mdc-list';
@use '@material/menu/mdc-menu';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/tooltip/styles';
@use '@material/button/mdc-button';
.doc-list{
  overflow-x: initial !important;
  &__cell{
    margin-left: 10px;
  }
  &__button{
    --mdc-ripple-left: 10px!important;
    --mdc-ripple-top: 10px!important;
    position: relative;
    padding: 0!important;
    &_active{
      background: var(--mdc-theme-primary)!important;
    }
  }
  &__wrapper{
    display: flex;
    align-items: center;
  }
  &__amount{
    margin-left: 5px;
    font-size: 14px;
    color: #fff;
  }
  &__icon{
    color: #fff!important;
  }
}
.doc-list .mdc-list{
  padding: 0;
}
</style>
