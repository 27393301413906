<template>
  <tr
    v-for="i in maxLength"
    :key="i"
    class="mdc-data-table__row row"
  >
    <td :rowspan="maxLength" v-if="i === 1">
      {{fio}}
    </td>
    <template v-if="contacts[i - 1]">
      <td
        :class="[
          'mdc-data-table__cell',
          'cell',
          { 'single-contact_selected': contacts[i - 1]?.id_contact === currentContact?.id }
        ]"
        @click="selectContact(contacts[i - 1])">
        {{contacts[i - 1]?.contact_value}}
      </td>
      <td
        :class="[
          'mdc-data-table__cell',
          'cell',
          { 'single-contact_selected': contacts[i - 1]?.id_contact === currentContact?.id }
        ]"
        @click="selectContact(contacts[i - 1])">
        {{contacts[i - 1]?.value_type}}
      </td>
      <td
        :class="[
          'mdc-data-table__cell',
          'cell',
          { 'single-contact_selected': contacts[i - 1]?.id_contact === currentContact?.id }
        ]"
        @click="selectContact(contacts[i - 1])">
        {{contacts[i - 1]?.tree_title}}
      </td>
      <td
        :class="[
          'mdc-data-table__cell',
          'cell',
          { 'single-contact_selected': contacts[i - 1]?.id_contact === currentContact?.id }
        ]"
        @click="selectContact(contacts[i - 1])">
        {{contacts[i - 1]?.corporate_text}}
      </td>
      <EditableCell
        :item="contacts[i - 1]"
        editableField="note"
        width="150px"
        @saveEditableCell="saveNote"
      />
    </template>
    <td colspan="4" v-else></td>
    <td class="mdc-data-table__cell cell">{{accounts[i - 1]?.name_login}}</td>
    <td class="mdc-data-table__cell cell">{{accounts[i - 1]?.group_login}}</td>
  </tr>
</template>

<script>
import EditableCell from './EditableCell.vue';

export default {
  name: 'SinglePerson',
  components: {
    EditableCell,
  },
  props: {
    fio: {
      type: String,
      default: () => '',
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      default: () => [],
    },
    currentContact: Object || null,
  },
  emits: [
    'newLogin',
    'selectContact',
  ],
  computed: {
    maxLength() {
      return Math.max(
        this.contacts ? this.contacts.length : 0, this.accounts ? this.accounts.length : 0,
      );
    },
  },
  methods: {
    async saveNote(data) {
      const params = {
        contactId: data.item.id_contact,
        text: data.newText,
      };
      await this.$store.dispatch('contractsData/saveContactNote', params);
    },
    selectContact(contact) {
      const selectedContact = {
        id: contact.id_contact,
        type: contact.value_type,
        fullContactInfo: contact,
      };
      this.$emit('selectContact', selectedContact);
    },
  },
};
</script>

<style lang="scss">
.single-contact {
  padding: 3px;
  border-radius: 2px;
  & td {
    overflow: visible;
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &_main {
    font-weight: bold;
  }
  &_invalid {
    text-decoration: line-through;
  }
  &_selected {
    background: rgba(green, 0.3)!important;
  }
}

</style>
