import store from '@/store/index';

export function transcriptColumnTitle(column) {
  const { tableColumns } = store.getters;
  const title = tableColumns.find((item) => item.name_variable === column);
  if (title) {
    if (title.field_title) {
      return title.field_title;
    }
    return 'add transcription';
  }
  return column;
}
