import Api from '../helpers/Api';

export const loginData = {
  namespaced: true,
  state: {
    loginGroups: [],
    processing: false,
  },
  actions: {
    async getLoginGroups({ rootState, commit }) {
      const data = { ...rootState.user.constantParams };
      data.id_org = rootState.user.selectedCompany.id_org;
      data.Name_event = 'GET:logins_groups';
      const groupList = await Api.get(data);
      if (groupList.body) {
        commit('SET_LOGIN_GROUPS', groupList.body);
      }
    },
    async saveLoginGroup({ rootState }, params) {
      let data = { ...rootState.user.constantParams };
      data.id_org = rootState.user.selectedCompany.id_org;
      data = Object.assign(data, params);
      data.Name_event = 'POST:logins_group_create';
      const newGroup = await Api.post(data, true);
      if (newGroup.body && newGroup.body[0].err === 0) {
        return newGroup;
      }
      return false;
    },
    async createLogin({ state, commit, rootState }, params) {
      if (state.processing) return;
      try {
        commit('PROCESSING', true);
        const data = { ...rootState.user.constantParams, ...params };
        data.Name_event = 'POST:login_create';
        const result = await Api.post(data, true);
        if (result && result.body[0].err === 0) {
          return true;
        }
        return false;
      } catch (err) {
        console.error(err);
      } finally {
        commit('PROCESSING', false);
      }
    },
    async getLoginVariant({ rootState }, params) {
      let data = { ...rootState.user.constantParams };
      data = Object.assign(data, params);
      data.Name_event = 'GET:login_variant';
      const res = await Api.get(data);
      if (res && res.body) {
        return res.body;
      }
      return false;
    },
  },
  mutations: {
    SET_LOGIN_GROUPS(state, groups) {
      state.loginGroups = groups;
    },
    PROCESSING(state, payload) {
      state.processing = payload;
    },
  },
  getters: {
    getLoginGroups(state) {
      return state.loginGroups;
    },
  },
};
