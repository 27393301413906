<template>
  <div class="abb">
    <mcw-icon-button class="abb_button" @click="$emit('unbind')">
      <mcw-material-icon icon="expand" class="icon_base"/>
    </mcw-icon-button>
  </div>
</template>

<script>
export default {
  name: 'ActsUnbindingButton',
  props: {
    sum: {
      type: Number,
      default: () => 0,
    },
  },
  emits: [
    'unbind',
  ],
};
</script>

<style lang="scss" src="./style.scss">

</style>
