<template>
  <div tabindex="0" class="available-branches">
    <mcw-dialog-title>
      Филиалы доступные для данного контракта
      <mcw-icon-button class="btn_close" @click="$emit('close')">
        <mcw-material-icon icon="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content class="available-branches__field">
      <SingleBranch
        v-for="branch in branchList"
        :key="branch.id_branch"
        :branch="branch"
        :idContract="id" />
    </mcw-dialog-content>

    <mcw-dialog-footer>
      <mcw-dialog-button action="dismiss">выйти</mcw-dialog-button>
    </mcw-dialog-footer>
  </div>
</template>

<script>
import SingleBranch from './SingleBranch.vue';

export default {
  name: 'AvailableBranches',
  props: {
    id: Number,
  },
  emits: [
    'close',
  ],
  components: {
    SingleBranch,
  },
  computed: {
    branchList() {
      return this.$store.getters['testData/getBranchList'];
    },
  },
};
</script>

<style lang="scss">
  .available-branches{
    height: 100%;
    &__field{
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 106px);
      overflow: auto;
    }
  }
</style>
