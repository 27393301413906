<template>
    <div class="contracts__panel" ref="leftSection">
        <div class="contracts__header" ref="header">
              <h2 class="page-heading">Договоры по {{companyName}}
                <span class='table-summary' v-if='contracts.length'>
                | Записей:{{contracts.length - 1}} |
              </span>
              </h2>
          <div class="contracts__actions" v-show="currentOrg">
              <mcw-select
                class="select_dense"
                outlined
                v-model="selectedType"
                @update:modelValue="setContractType">
                <mcw-list-item
                  v-for="type in types"
                  :key="type.value"
                  :data-value="type.value"
                  role="option"
                  icon>
                  {{ type.label }}
                </mcw-list-item>
              </mcw-select>
              <mcw-textfield
                class="table__filter"
                outline
                label="Поиск"
                v-model="searchString"
              />
              <mcw-button
                v-if="companyName"
                class="contracts__btn"
                raised
                @click="addContract()">
                <mcw-material-icon
                  icon="add"
                  class="icon_white">
                </mcw-material-icon>
                Создать
              </mcw-button>
          </div>
              <mcw-dialog
                class="big-modal"
                v-model="isEditContractOpen">
                <EditContract
                  v-if="isEditContractOpen"
                  :id="currentContract"
                  @close="isEditContractOpen = false"/>
              </mcw-dialog>
        </div>
    <div class="contracts__content">
      <Spinner v-show="isLoading"/>
      <template v-if="!contracts.length">
        <div class="contracts__no-contracts" v-if="!currentOrg">
          <router-link to="/orgs">Для просмотра договоров сначала выберите организацию</router-link>
        </div>
        <div class="contracts__no-contracts" v-else>
          По данному контрагенту нет доступных контрактов
        </div>
      </template>
        <div class="table-container_contracts scrollable-content" v-else>
      <mcw-data-table sticky>
        <table class="mdc-data-table__table contracts__table mdc-data-table--sticky-header">
          <thead class="contracts__table-head">
            <tr
              class="mdc-data-table__header-row">
              <th class="mdc-data-table__header-cell">
                ID
              </th>
              <th class="mdc-data-table__header-cell">
                {{transcriptColumnTitle('N_contract')}}
              </th>
              <th class="mdc-data-table__header-cell">
                {{transcriptColumnTitle('name_contract')}}
              </th>
              <th class="mdc-data-table__header-cell">
                {{transcriptColumnTitle('date_contract')}}
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric"
                v-tooltip="{ content: 'Пациенты', pos: 'left' }">
                {{transcriptColumnTitle('n_p')}}
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric"
                v-tooltip="{ content: 'Закл. акты', pos: 'left' }">
                {{transcriptColumnTitle('n_za')}}
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric"
                v-tooltip="{ content: 'Документы', pos: 'left' }">
                {{transcriptColumnTitle('n_d')}}
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric"
                v-tooltip="{ content: 'Услуги', pos: 'left' }">
                {{transcriptColumnTitle('n_u')}}
              </th>
              <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric"
                v-tooltip="{ content: 'Акты', pos: 'left' }">
                {{transcriptColumnTitle('n_a')}}
              </th>
              <th class="mdc-data-table__header-cell">
                {{transcriptColumnTitle('dealsides')}}
              </th>
            </tr>
          </thead>
          <tbody class="mdc-data-table__content">
            <template
              v-for="(contract, index) in filteredContracts"
              :key="contract.id_contract">
              <tr class="mdc-data-table__row contracts__row"
                @click="selectContract(contract.id_contract)"
                 :class="{
                    contracts__row_selected: currentContract == contract.id_contract,
                    contracts__row_archive: contract.archive,
                    contracts__row_for_medsafe: contract.for_medsafe,
                    contracts__row_medsafe_locked: contract.medsafe_locked,
                    contracts__row_medsafe_half_locked: contract.medsafe_half_locked,
                    contracts__row_signed: contract.signed}">
                <td class="mdc-data-table__cell contracts__cell">{{contract.id_contract}}</td>
                <ExpandableCell :content="contract.N_contract" maxWidth="80"/>
                <ExpandableCell :content="contract.name_contract" maxWidth="150"/>
                <td class="mdc-data-table__cell contracts__cell">
                  {{contract.date_contract}}
                </td>
                <td class="mdc-data-table__cell contracts__cell mdc-data-table__cell--numeric">
                  {{contract.n_p}}
                </td>
                <td class="mdc-data-table__cell contracts__cell mdc-data-table__cell--numeric">
                  {{contract.n_za}}
                </td>
                <td class="mdc-data-table__cell contracts__cell mdc-data-table__cell--numeric">
                  {{contract.n_d}}
                </td>
                <td class="mdc-data-table__cell contracts__cell mdc-data-table__cell--numeric">
                  {{contract.n_u}}
                </td>
                <td class="mdc-data-table__cell contracts__cell mdc-data-table__cell--numeric">
                  {{contract.n_a}}
                </td>
                <td class="mdc-data-table__cell contracts__cell">
                  <span
                    class="single-price single-price_cropped"
                    v-for="(dealside, i) in extractDealsides(contract.dealsides)"
                    :key="i"
                    v-tooltip="{ content: `${dealside.org} - ${dealside.side}`, pos: 'left' }"
                    @click.stop="showChangeOrgDialog(dealside)">
                    {{cropString(dealside.org, 1)}}
                  </span>
                </td>
              </tr>
              <tr :class="{contracts__row_selected: currentContract === contract.id_contract}">
                <td colspan="10">
                  <ContractDocs
                    @openEditor="editContract"
                    :id="contract.id_contract"
                    v-if="currentContract && currentContract === `${contract.id_contract}` && index > 0"/>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </mcw-data-table>
      </div>
      </div>
    </div>
    <div class="resizer resizer_vert" ref="resizer"></div>
    <div class="contracts__panel" ref="rightSection">
        <div class="contract-details">
          <mcw-tab-bar
            active-tab-index="1"
            @update:modelValue="onTabSelect">
            <div class="contract-details__tabs-group">
              <mcw-tab
                v-for="tab in firstTabsRow"
                :key="tab.id">
                {{tab.label}}
              </mcw-tab>
            </div>
            <div class="contract-details__tabs-group">
              <mcw-tab
                v-for="tab in secondTabsRow"
                :key="tab.id">
                {{tab.label}}
              </mcw-tab>
            </div>
          </mcw-tab-bar>
        </div>
        <div class="tab-area">
          <Spinner v-if="rerender"/>
          <!-- <div
            class="table-container_contracts scrollable-content"> -->
          <div
            class="tab-area__notice"
            v-if="!currentContract">
            <h3>
              Договор не выбран
            </h3>
          </div>
          <component
            v-if="currentContract && !rerender && !actsProcessing"
            :is="activeTab.component"
            :items="tableItems"
            :tableHeight="100"
            :notice="!!currentContract"
            :contractId="+currentContract"
            :loading="tabLoading"
            @refresh="refresh">
          </component>
          </div>
        </div>
    <!-- </div> -->
  <mcw-dialog
    v-model="showConfirmDialog"
    escape-key-action="close"
    v-if="orgToFollow"
  >
  <mcw-dialog-title>Подтвердите действие</mcw-dialog-title>
  <mcw-dialog-content>
    <div>
      Переключить выбранную организацию на {{orgToFollow.org}}?
    </div>
  </mcw-dialog-content>
  <mcw-dialog-footer>
    <mcw-dialog-button action="dismiss" isDefault>Отмена</mcw-dialog-button>
    <mcw-dialog-button
      action="accept"
      @click="followToOrg()">
      Перейти
    </mcw-dialog-button>
  </mcw-dialog-footer>
</mcw-dialog>
</template>

<script src="./Contracts.js"></script>
<style lang="scss" src="./Contracts.scss"></style>
