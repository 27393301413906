<template>
  <div class="multiselect table__select">
    <mcw-select
      outlined
      :label="dynamicLabel + ' (' + dynamicAmount + ')'">
      <li
        class="multiselect__option"
        v-for="(option, index) in options"
        :key="index"
        :data-value="option"
        role="option">
        <mcw-checkbox
        @change="change"
        v-model="selectedOptions"
        :value="option" />
        {{ option }}
      </li>
    </mcw-select>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    options: Array,
    label: String,
  },
  data() {
    return {
      selectedOptions: [],
      dynamicLabel: this.label,
    };
  },
  computed: {
    dynamicAmount() {
      return !this.selectedOptions.length ? 'все' : this.selectedOptions.length;
    },
  },
  methods: {
    change() {
      this.$emit('update:modelValue', this.selectedOptions);
    },
  },
};
</script>

<style>

</style>
