<template>
  <mcw-menu-anchor class="myAnchor">
    <mcw-button @click="showMenu = true" class="button_min">
      <mcw-material-icon
        :icon="selected.icon"
        class="icon_base"
      />
    </mcw-button>
    <mcw-menu
      v-model="showMenu"
      @select="onSelect"
    >
      <mcw-list-item
        v-for="option in options"
        :key="option.value"
      >
        {{option.name}}</mcw-list-item
      >
    </mcw-menu>
  </mcw-menu-anchor>
</template>

<script>
export default {
  name: 'IconFilter',
  props: {
    options: Array,
    selected: Object,
  },
  emits: [
    'select',
  ],
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    onSelect(el) {
      this.$emit('select', el.index);
    },
  },
};
</script>

<style>

</style>
