<template>
  <div class="pagination">
    <mcw-button
      class="pagination__button"
      :disabled="currentPage === 1"
      @click="selectPage(1)">
      <mcw-material-icon
        icon="keyboard_double_arrow_left"
        :class="currentPage === 1 ? 'icon_grey' : 'icon_base'"/>
    </mcw-button>
    <span class="pagination__ellipsis" v-if="!beginPosition">...</span>
    <mcw-button
      class="pagination__button"
      v-for="page in visiblePages"
      :key="page"
      :unelevated="page === currentPage"
      @click="selectPage(page)">
      {{page}}
    </mcw-button>
    <span class="pagination__ellipsis" v-if="!endPosition">...</span>
    <mcw-button
      class="pagination__button"
      :disabled="currentPage === pages.length"
      @click="selectPage(pages.length)">
      <mcw-material-icon
        icon="keyboard_double_arrow_right"
        :class="currentPage === pages.length ? 'icon_grey' : 'icon_base'"/>
    </mcw-button>
    <span class="pagination__label">Показывать по:</span>
    <mcw-select
      class="select_dense pagination__select"
      outlined
      :value="selectedNumber"
      v-model="selectedNumber"
      @update:modelValue="changeNumber">
      <mcw-list-item
        v-for="number in numbers"
        :key="number"
        :data-value="number">
        {{number}}
      </mcw-list-item>
    </mcw-select>
  </div>
</template>

<script>
const visiblePagesNumber = 5;

export default {
  name: 'Pagination',
  props: {
    pages: {
      type: Array,
    },
    itemsPerPage: {
      type: String,
      default: () => '20',
    },
  },
  emits: [
    'selectPage',
    'changeNumber',
  ],
  data() {
    return {
      currentPage: 1,
      selectedNumber: null,
    };
  },
  computed: {
    numbers() {
      return ['10', '20', '50', '100'];
    },
    half() {
      return Math.floor((visiblePagesNumber / 2) + 1);
    },
    beginPosition() {
      return this.currentPage <= this.half;
    },
    endPosition() {
      return this.currentPage > this.pages.length - this.half;
    },
    visiblePages() {
      if (this.beginPosition) {
        return this.pages.slice(0, visiblePagesNumber);
      }
      if (this.endPosition) {
        return this.pages.slice(this.pages.length - visiblePagesNumber);
      }
      return this.pages.slice(this.currentPage - this.half, this.currentPage + this.half - 1);
    },
  },
  created() {
    this.selectedNumber = this.itemsPerPage;
  },
  methods: {
    selectPage(page) {
      this.currentPage = page;
      this.$emit('selectPage', page);
    },
    changeNumber() {
      this.$emit('changeNumber', this.selectedNumber);
    },
  },
};
</script>

<style lang="scss">
.pagination{
  display: flex;
  align-items: center;
  &__button{
    min-width: auto;
    padding: 0 8px;
  }
  &__select{
    width: 100px;
  }
  &__label{
    margin: 0 10px;
  }
}
</style>
