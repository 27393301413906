<template>
  <div class="services-table">
     <mcw-data-table>
                  <table class="mdc-data-table__table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      <tr class="mdc-data-table__row" v-for="item in items" :key="item.id_service">
                        <td class="mdc-data-table__cell">{{item.id_service}}</td>
                        <ExpandableCell :content="item.short_name" maxWidth="150"/>
                        <!-- <td class="mdc-data-table__cell">{{item.short_name}}</td> -->
                        <td class="mdc-data-table__cell">{{item.date_add}}</td>
                        <td class="mdc-data-table__cell">{{item.price}}</td>
                      </tr>
                    </tbody>
                  </table>
                </mcw-data-table>
  </div>
</template>

<script>
import ExpandableCell from './ExpandableCell.vue';

export default {
  name: 'ServicesTable',
  props: {
    services: {
      type: String,
    },
  },
  components: {
    ExpandableCell,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.items = JSON.parse(this.services);
  },
};
</script>

<style>
.services-table .mdc-data-table{
  top: -14px;
}
</style>
