<template>
  <div class="hazard-editor">
    <mcw-dialog-title class="big-modal__title" style="padding-right: 0">
      Управление вредными факторами для должности {{selectedItem ? selectedItem.data.text : ''}}
      <mcw-icon-button>
        <mcw-material-icon icon="close" @click="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>
    <mcw-dialog-content>
      <mcw-layout-grid class="my-grid">
        <mcw-layout-cell class="cell" desktop="6">
          <div class="hazards-subtitle">
            Факторы, привязанные к должности
          </div>
          <div class="hazards-sort">
            Сортировка по:
            <div class="hazards-sort-buttons">
              <mcw-radio
                v-for="param in paramsForSorting"
                :key="param.key"
                v-model="hazardsSortParam"
                :label="param.label"
                name="hazardsSortParam"
                :value="param.key"
              ></mcw-radio>
            </div>
          </div>
          <div class="mdc-typeography--body1">
            <mcw-data-table v-if="nodeHazards">
              <table
                class="mdc-data-table__table"
                style="width: 100%">
                <thead>
                  <tr class="mdc-data-table__header-row">
                    <th class="mdc-data-table__header-cell" role="columnheader" scope="col">
                      Фактор
                    </th>
                    <th
                      class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric"
                      role="columnheader"
                      scope="col"
                    >
                      Удалить
                    </th>
                  </tr>
                </thead>
                <tbody class="mdc-data-table__content">
                  <tr
                    class="mdc-data-table__row"
                    v-for="(hazard, index) in nodeHazards"
                    :key="hazard.id_tree_hazard">
                    <td class="mdc-data-table__cell">
                      {{ hazard.pp_plus_short || hazard.concatName || hazard.factor}}
                    </td>
                    <td class="mdc-data-table__cell mdc-data-table__cell--numeric">
                      <mcw-icon-button
                        action="dismiss"
                        @click="remove(index)">
                        <mcw-material-icon icon="delete"/>
                      </mcw-icon-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mcw-data-table>
          </div>
        </mcw-layout-cell>
        <mcw-layout-cell class="cell" desktop="6">
          <div class="hazards-subtitle">
            Каталог факторов
          </div>
          <div class="hazards-sort">
            Сортировка по:
            <div class="hazards-sort-buttons">
              <mcw-radio
                v-for="param in paramsForSorting"
                :key="param.key"
                v-model="catalogSortParam"
                :label="param.label"
                name="catalogSortParam"
                :value="param.key"
              ></mcw-radio>
            </div>
          </div>
          <div class="hazard-select-wrapper">
          <mcw-select
            :value="selectedType"
            class='hazard__select'
            label="Приказы и приложения"
            outlined
            helptext="Укажите приказ для получения факторов вредности"
            leading-icon="assignment_late"
            @update:modelValue="getHazardsCatalog">
            <mcw-list-item
              v-for="type in hazardsTypes"
              :key="type.id"
              :data-value="type.legal_instrument"
              role="option">
                {{ type.prikaz }}
              </mcw-list-item>
          </mcw-select>
        </div>
        <div class="hazard-select-wrapper">
          <SearchSelect
            v-if='hazardsCatalog.length'
            titleWidth="100%"
            :placeholder="isLoading ? 'Загрузка...' : 'Поиск фактора'"
            @selectItem="setItem"
            :options="hazardsCatalog"
            label="concatName"
            :initial="selectDefaultValue"
            :showCounter='true'/>
          </div>
          <mcw-fab
            class="hazard-editor__fab"
            icon="arrow_back"
            :disabled="!selectedHazard"
            @click="attach"
            ></mcw-fab>
            <div v-if='selectedHazard'>Выбран фактор: {{ selectedHazard.concatName }}</div>
        </mcw-layout-cell>
      </mcw-layout-grid>
      <Spinner v-if="isLoading" />
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button isDefault @click="close">выйти</mcw-dialog-button>
    </mcw-dialog-footer>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import SearchSelect from './SearchSelect/SearchSelect.vue';

export default {
  name: 'HazardEditor',
  components: {
    SearchSelect,
    Spinner,
  },
  props: {
    hazardsLoading: Boolean,
  },
  emits: [
    'close',
  ],
  data() {
    return {
      selectedHazard: null,
      selectDefaultValue: { name: '' },
      isLoading: false,
      selectedType: null,
      catalogSortParam: 'rang',
      hazardsSortParam: 'rang',
    };
  },
  watch: {
    hazardsLoading() {
      this.isLoading = this.hazardsLoading;
    },
  },
  computed: {
    selectedItem() {
      return this.$store.state.treeData.selectedItem;
    },
    paramsForSorting() {
      return [
        {
          key: 'rang',
          label: 'Код',
        },
        {
          key: 'factor',
          label: 'Название',
        },
      ];
    },
    nodeHazards() {
      return this.$store.state.treeData.nodeHazards
        .sort((a, b) => this.sortCallback(a, b, this.hazardsSortParam));
    },
    hazardsCatalog() {
      return this.$store.getters['treeData/getHazardList']
        .sort((a, b) => this.sortCallback(a, b, this.catalogSortParam));
    },
    hazardsTypes() {
      return this.$store.getters['treeData/getHazardTypesList'];
    },
  },
  methods: {
    sortCallback(a, b, param) {
      let first = parseInt(a[param], 10);
      if (Number.isNaN(first)) {
        first = a[param];
      }
      let second = parseInt(b[param], 10);
      if (Number.isNaN(second)) {
        second = b[param];
      }
      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    },
    close() {
      this.$emit('close');
      this.selectedHazard = null;
      this.selectDefaultValue = { name: '' };
    },
    setItem(option) {
      this.selectedHazard = option;
    },
    remove(index) {
      const hazard = this.nodeHazards[index];
      this.$store.dispatch('treeData/removeHazard', hazard.id_tree_hazard);
    },
    attach() {
      const existedHazard = this.nodeHazards
        .find((item) => item.id_hazard === this.selectedHazard.id_hazard);
      if (existedHazard) {
        this.emitter.emit('message', `Фактор ${this.selectedHazard.short_name} уже прикреплен к должности!`);
        return;
      }
      this.$store.dispatch('treeData/attachHazard', this.selectedHazard.id_hazard);
      this.selectedHazard = null;
      this.selectDefaultValue = { name: '' };
    },
    async getHazardsCatalog(type) {
      this.isLoading = true;
      await this.$store.dispatch('treeData/showHazardsCatalog', type);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
  @use "@material/typography/mdc-typography";
  @use "@material/button/mdc-button";
  @use "@material/fab/mdc-fab";
  @use '@material/tooltip/styles';
  .hazard-editor{
    height: 100%;
    display: flex;
    flex-direction: column;
    &__fab{
      background-color: var(--mdc-theme-primary)!important;
      top: 300px;
      left: -27px;
    }
  }
  .hazard {
    &__select {
      width: 100%;
    }
  }
  .hazards-sort-buttons {
    display: flex;
  }
  .hazards-subtitle {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    margin-bottom: 10px;
  }
  .hazard-select-wrapper {
    margin: 20px 0;
  }
</style>
