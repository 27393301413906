<template>
  <div class="tree-by-contract__item"
    :class="{
    'tree-by-contract__item--on': selected,
    'tree-by-contract__item--off': !selected,
  }">
  <mcw-checkbox
    v-model="selected"
    @change="save"/>
  {{item.title}} / ID {{item.id_tree}}
  </div>
</template>

<script>
export default {
  name: 'SingleTreeItemByContract',
  props: {
    item: Object,
    idContract: Number,
  },
  emits: ['selectAll'],
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    treeByContract() {
      return this.$store.getters['treeData/getTreeByContract'];
    },
  },
  created() {
    this.selected = this.item.selected === 1;
  },
  watch: {
    item(val) {
      this.selected = val.selected === 1;
    },
    treeByContract() {
      if (this.item.id_tree === 0) {
        this.selected = this.treeByContract.every((item) => item.selected === 1);
      }
    },
  },
  methods: {
    async save() {
      let del;
      if (!this.item.id_tree) {
        // eslint-disable-next-line no-unused-expressions
        this.selected ? del = -1 : del = 2;
      } else {
        // eslint-disable-next-line no-unused-expressions
        this.selected ? del = 0 : del = 1;
      }
      const params = {
        id_tree: this.item.id_tree,
        del,
      };
      const result = await this.$store.dispatch('treeData/bindTreeToContract', params);
      if (result) {
        await this.$store.dispatch('treeData/getTreeByContract');
        this.$emit('selectAll');
      }
    },
  },
};
</script>

<style lang="scss">
.tree-by-contract{
  &__item{
    border: 1px solid #aaa;
    margin: 5px;
    padding-right: 5px;
    border-radius: 10px;
    &--on{
      background: rgba(green, 0.12);
    }
    &--off{
      background: rgba(red, 0.12);
    }
  }
}
</style>
