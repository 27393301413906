<template>
  <mcw-dialog-title>{{title}} {{name}}?</mcw-dialog-title>
  <mcw-dialog-footer>
    <mcw-dialog-button action="dismiss">отмена</mcw-dialog-button>
    <mcw-dialog-button
      action="accept"
      isDefault
      @click="remove"
      @keyup.enter="remove">
      ок
    </mcw-dialog-button>
  </mcw-dialog-footer>
</template>

<script>
import { utils } from '../helpers/utils';

export default {
  name: 'RemoveEmployee',
  mixins: [
    utils,
  ],
  emits: ['finishEditing'],
  props: {
    title: String,
    division: Number,
    position: Number,
    patients: Array,
    moveId: Number,
    tableType: String,
  },
  computed: {
    name() {
      if (this.patients.length) {
        let name = `${this.patients.length} сотрудников`;
        if (this.patients.length === 1) {
          name = this.patient(this.patients[0]) ? this.trimName(this.patient(this.patients[0]).fio) : '';
        }
        return name;
      }
      return null;
    },
  },
  methods: {
    async remove() {
      const fullPatientsData = [];
      this.patients.forEach((item) => {
        fullPatientsData.push(this.patient(item));
      });
      const remove = await this.$store.dispatch('employeesData/removePatients', fullPatientsData);
      if (remove) {
        this.$emit('finishEditing');
        this.$store.commit('treeData/SET_AMOUNT', { id: this.position, amount: -this.patients.length });
      }
    },
    patient(id) {
      return this.$store.getters['employeesData/getPatientById'](id);
    },
  },
};
</script>

<style>

</style>
