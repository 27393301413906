<template>
<div class="edit-contact">
  <mcw-dialog-title>
    {{title}}{{displayName}}?
    <mcw-icon-button class="btn_close" @click="$emit('close')">
      <mcw-material-icon icon="close"/>
    </mcw-icon-button>
  </mcw-dialog-title>
  <mcw-dialog-content class="add-contact__content">
    <SearchSelect
      v-if="!isIncomingEmpty"
      :options="peopleList"
      label="fio"
      titleWidth="100%"
      :placeholder="selectedName || 'Выберите человека'"
      :initial="{}"
      @selectItem="setName"
      :showCounter='true'/>
    <div class="edit-contact__existing">
      <h3 v-if="existingContacts.length">
        Для {{selectedName}} уже существуют контакты:
      </h3>
      <table class="mdc-data-table__table">
        <tr
          class="mdc-data-table__row"
          v-for="contact in existingContacts"
          :key="contact.id_contact">
          <td class="mdc-data-table__cell">
            {{defineContactType(contact.type_val)}}
          </td>
          <td class="mdc-data-table__cell">{{contact.val}}</td>
        </tr>
      </table>
      <div
        class="edit-contact__existing-item">
        {{contact.val}}
      </div>
    </div>
    <h3 v-if="existingContacts.length">Добавить еще контакт:</h3>
    <div class="form-group">
      <mcw-select
        outlined
        tabindex="1"
        class="select_dense"
        label="Тип контакта"
        ref="select"
        v-model="selectedType">
        <mcw-list-item
          v-for="type in contactsTypes"
          :key="type.id"
          :data-value="type.id">
          {{type.label}}
        </mcw-list-item>
      </mcw-select>
      <mcw-select
        outlined
        tabindex="2"
        class="select_dense"
        label="Принадлежность контакта"
        ref="select"
        v-model="selectedBelongs">
        <mcw-list-item
          v-for="type in contactsBelongs"
          :key="type.id"
          :data-value="type.id">
          {{type.label}}
        </mcw-list-item>
      </mcw-select>
    </div>
    <mcw-textfield
      required
      outline
      v-phone:[selectedType]="selectedType"
      helptext="Это обязательное поле"
      helptext-validation
      :disabled="!selectedType"
      v-model="contact.val"
      :label="textFieldLabel"
      @blur="validate"
      @focus="isValid = true"
      tabindex="0" />
    <mcw-textfield
      outline
      :disabled="!selectedType"
      v-model="contact.note"
      label="Комментарий"
      multiline
      rows="4" cols="40"/>
  </mcw-dialog-content>
  <mcw-dialog-footer>
    <mcw-dialog-button
      action="accept"
      isDefault
      :disabled="!isPossibleToSave"
      @click="saveContact">
      Сохранить
    </mcw-dialog-button>
    <mcw-dialog-button action="dismiss" isDefault>Отмена</mcw-dialog-button>
  </mcw-dialog-footer>
</div>
</template>

<script>
import SearchSelect from './SearchSelect/SearchSelect.vue';
import { utils } from '../helpers/utils';

export default {
  name: 'ContactEditor',
  mixins: [
    utils,
  ],
  components: {
    SearchSelect,
  },
  props: {
    incoming: Object,
  },
  emits: [
    'close',
  ],
  data() {
    return {
      contact: {
        fio: this.incoming.fio,
        id_contact: this.incoming.id_contact || null,
        id_people: this.incoming.id_people,
        note: this.incoming.note || '',
        type_val: this.incoming.type_val || null,
        val: this.incoming.val || '',
      },
      selectedType: null,
      // eslint-disable-next-line no-useless-escape
      phoneRegexp: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/g,
      emailRegexp: /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/g,
      isValid: false,
      existingContacts: [],
      selectedName: '',
      contactsBelongs: [
        {
          id: 0,
          label: 'Личный',
        },
        {
          id: 1,
          label: 'Корпоративный',
        },
        {
          id: 2,
          label: 'Личный и корпоративный',
        },
      ],
      selectedBelongs: null,
    };
  },
  async created() {
    if (!this.isIncomingEmpty) {
      await this.$store.dispatch('employeesData/getLastPatients', { includeServices: false });
    } else {
      const existingContacts = this.checkExistingContacts(this.incoming.id_people);
      if (existingContacts) {
        this.existingContacts = existingContacts;
        this.selectedName = this.trimName(this.incoming.fio);
      }
    }
  },
  mounted() {
    const el = this.$refs.select.root;
    el.blur();
  },
  computed: {
    contactTypes() {
      return this.$store.getters['contractsData/getContactsTypes'];
    },
    isIncomingEmpty() {
      return !!Object.keys(this.incoming).length;
    },
    peopleList() {
      return this.arrayUnique(this.$store.getters['employeesData/getAllPatients'], 'id_people');
    },
    contactsTypes() {
      return this.$store.getters['contractsData/getContactsTypes'].filter((item) => item.id !== 4);
    },
    displayName() {
      if (this.contact.fio) {
        return ` для ${this.trimName(this.contact.fio)}`;
      }
      return '';
    },
    title() {
      return this.contact.id_contact ? 'Редактировать контакт' : 'Добавить контакт';
    },
    isPossibleToSave() {
      return this.contact.val && this.selectedType && this.selectedBelongs;
    },
    textFieldLabel() {
      if (this.selectedType) {
        return this.contactsTypes.find((type) => type.id === +this.selectedType).label;
      }
      return '';
    },
  },
  methods: {
    defineContactType(id) {
      return this.contactTypes.find((item) => item.id === id).label;
    },
    validate() {
      if (this.selectedType === '1') {
        this.isValid = this.phoneRegexp.test(this.contact.val);
      }
      if (this.selectedType === '2') {
        this.isValid = this.emailRegexp.test(this.contact.val);
      }
    },
    setName(item) {
      this.selectedName = this.trimName(item.fio);
      const existingContacts = this.checkExistingContacts(item.id_people);
      if (existingContacts) {
        this.existingContacts = existingContacts;
      }
      this.contact = Object.assign(this.contact, item);
    },
    checkExistingContacts(id) {
      return this.$store.getters['contractsData/getResponsiblings']
        .filter((item) => item.id_people === id);
    },
    async saveContact() {
      const params = {
        type_contact: this.selectedType,
        txt_contact: this.contact.val,
        txt_note: this.contact.note,
        id_people: this.contact.id_people,
        corporate: this.selectedBelongs,
      };
      const result = await this.$store.dispatch('contractsData/saveContact', params);
      if (result) {
        this.emitter.emit('message', `Новый контакт для ${this.displayName} успешно создан!`);
        this.emitter.emit('refreshContacts');
        setTimeout(() => {
          this.emitter.emit('close');
        }, 500);
      } else {
        this.emitter.emit('message', 'Не удалось создать контакт');
      }
    },
  },
};
</script>

<style lang="scss">
.edit-contact{
  min-height: 390px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  & .mdc-dialog__content{
    overflow: auto;
  }
  &__existing{
    margin-bottom: 20px;
  }
}

</style>
