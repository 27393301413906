<template>
  <mcw-dialog-title>
    Выберите диапазон дат
    <mcw-icon-button class="btn_close" @click="$emit('close')">
      <mcw-material-icon icon="close" />
    </mcw-icon-button>
  </mcw-dialog-title>
  <mcw-dialog-content>
    <mcw-button @click="setAllDates">все даты</mcw-button>
    <mcw-button @click="setThisYear">текущий год</mcw-button>
    <mcw-button @click="setLastMonth">прошлый месяц</mcw-button>
    <div class="pickers">
      <DatepickerLite
        :locale="datepickerSettings.locale"
        :value-attr="dates.start"
        @value-changed="setStart"
      />
      <span> ~ </span>
      <DatepickerLite
        :locale="datepickerSettings.locale"
        :value-attr="dates.end"
        @value-changed="setEnd"
      />
    </div>
  </mcw-dialog-content>

  <mcw-dialog-footer>
    <mcw-dialog-button @click="setDates">
      Установить даты
    </mcw-dialog-button>
    <mcw-dialog-button action="dismiss">Отмена</mcw-dialog-button>
  </mcw-dialog-footer>
</template>

<script>
import DatepickerLite from 'vue3-datepicker-lite';
import { datepickerSettings } from '../helpers/datepickerSettings';
import { daysInMonth } from '../helpers/setInitialDatesRange';

export default {
  name: 'SetPeriod',
  components: {
    DatepickerLite,
  },
  props: {
    initialRange: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close', 'setRange'],
  data() {
    return {
      datepickerSettings: {},
      dates: {
        start: '',
        end: '',
      },
    };
  },
  created() {
    this.datepickerSettings = datepickerSettings;
    this.dates = { ...this.initialRange };
  },
  methods: {
    setStart(val) {
      this.dates.start = val;
    },
    setEnd(val) {
      this.dates.end = val;
    },
    setDates() {
      const transformDate = (str) => str.split('.').reverse().join('-');
      if (new Date(transformDate(this.dates.start)) > new Date(transformDate(this.dates.end))) {
        this.emitter.emit('message', 'Конечная дата не может быть меньше начальной!');
        return false;
      }
      this.$emit('setRange', this.dates);
      this.$emit('close');
    },
    setAllDates() {
      this.setStart('01.01.1970');
      this.setEnd(new Date().toLocaleDateString('ru-RU'));
    },
    setThisYear() {
      const startDate = new Date(new Date().getFullYear(), 0, 1);
      this.setStart(startDate.toLocaleDateString('ru-RU'));
      this.setEnd(new Date().toLocaleDateString('ru-RU'));
    },
    setLastMonth() {
      const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      this.setStart(startDate.toLocaleDateString('ru-RU'));
      const endDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        daysInMonth(new Date().getMonth() - 1),
      );
      this.setEnd(endDate.toLocaleDateString('ru-RU'));
    },
  },
};
</script>

<style scoped>
.pickers {
  display: flex;
}
</style>
