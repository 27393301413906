const extractDigits = (str) => str.split('').filter((item) => item.charCodeAt(0) > 47 && item.charCodeAt(0) < 58);
const serviceKeys = [8, 46, 13];

function mask(el, type) {
  el.oninput = function (e) {
    if (!e.isTrusted) {
      return;
    }
    const input = el.querySelector('input');
    if (type === '1') {
      const x = input.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
      if (!x[2] && x[1] !== '') {
        input.value = x[1] === '8' || x[1] === '7' ? x[1] : `8${x[1]}`;
      } else {
        input.value = !x[3] ? x[1] + x[2] : `${x[1]} (${x[2]}) ${x[3]}${x[4] ? `-${x[4]}` : ''}`;
      }
    }
  };
}

function snils(el) {
  const input = el.querySelector('input') ? el.querySelector('input') : el;

  input.onkeydown = (e) => {
    if (input.value.length >= 14 && !serviceKeys.includes(e.keyCode)) {
      e.preventDefault();
    }
  };

  input.oninput = (e) => {
    const val = extractDigits(input.value);
    if (val.length > 3) {
      val.splice(3, 0, '-');
    }
    if (val.length > 7) {
      val.splice(7, 0, '-');
    }
    if (val.length > 11) {
      val.splice(11, 0, ' ');
    }
    input.value = val.join('');
  };
}

function dMask(el) {
  const input = el.querySelector('input') ? el.querySelector('input') : el;

  input.onkeydown = (e) => {
    if (input.value.length >= 10 && !serviceKeys.includes(e.keyCode)) {
      e.preventDefault();
    }
  };

  input.oninput = (e) => {
    const val = extractDigits(input.value);
    if (val.length > 2) {
      val.splice(2, 0, '.');
    }
    if (val.length > 5) {
      val.splice(5, 0, '.');
    }
    input.value = val.join('');
  };
}

// function dMask(el) {
//   const input = el.querySelector('input') ? el.querySelector('input') : el;

//   const maskStr = '__.__.____';

//   function setInitialMask() {
//     input.value = maskStr;
//     input.setSelectionRange(0, 0);
//   }
//   input.onfocus = (e) => {
//     if (input.value === '' || input.value === maskStr) {
//       e.preventDefault();
//       setInitialMask();
//     } else if (input.value && input.value !== maskStr) {
//       // input.setSelectionRange(0, 10);
//     }
//   };
//   input.onmouseup = (e) => {
//     if (input.value === '' || input.value === maskStr) {
//       e.preventDefault();
//       setInitialMask();
//     } else if (input.value && input.value !== maskStr) {
//       input.setSelectionRange(0, 10);
//     }
//   };

//   function implementMask(str) {
//     const digitsArr = extractDigits(str);
//     const maskArr = maskStr.split('');
//     const setDate = () => {
//       maskArr.splice(0, digitsArr.length, ...digitsArr);
//       input.setSelectionRange(digitsArr.length, digitsArr.length);
//       return maskArr.join('');
//     };
//     const setMonth = () => {
//       maskArr.splice(0, 2, digitsArr[0], digitsArr[1]);
//       maskArr.splice(3, (digitsArr.length - 2), ...digitsArr.slice(2));
//       input.setSelectionRange(digitsArr.length + 1, digitsArr.length + 1);
//       return maskArr.join('');
//     };
//     const setYear = () => {
//       maskArr.splice(0, 2, digitsArr[0], digitsArr[1]);
//       maskArr.splice(3, 2, digitsArr[2], digitsArr[3]);
//       maskArr.splice(6, digitsArr.length - 4, ...digitsArr.slice(4));
//       input.setSelectionRange(digitsArr.length + 2, digitsArr.length + 2);
//       return maskArr.join('');
//     };
//     if (digitsArr.length < 3) {
//       return setDate();
//     }
//     if (digitsArr.length < 5) {
//       return setMonth();
//     }
//     if (digitsArr.length < 9) {
//       return setYear();
//     }
//     if (digitsArr.length >= 9) {
//       digitsArr.splice(8);
//       return setYear();
//     }
//   }

//   input.addEventListener('input', (e) => {
//     const newVal = implementMask(input.value);
//     console.log(newVal);
//     if (newVal) {
//       input.value = newVal;
//       console.log(input.value);
//     } else {
//       e.preventDefault();
//       return false;
//     }
//     // если убрать второй вызов implementMask, ломается setSelectionRange, хз почему
//     implementMask(input.value);
//   });

//   input.addEventListener('paste', (e) => {
//     e.preventDefault();
//     const str = e.clipboardData.getData('text');
//     const newVal = implementMask(str);
//     if (newVal) {
//       input.value = newVal;
//     } else {
//       e.preventDefault();
//       return false;
//     }
//   });

//   el.oninput = function (e) {
//     if (!e.isTrusted) {
//       return false;
//     }
//   };
// }

const tooltip = (el, args) => {
  const div = document.createElement('div');
  el.addEventListener('mouseover', (ev) => {
    const app = document.querySelector('#app');
    div.classList.add('tooltip');
    div.innerText = args.content;
    div.style.top = `${ev.clientY}px`;
    div.style.left = `${ev.clientX}px`;
    app.append(div);
    const width = div.offsetWidth;
    if (args.pos === 'left') {
      div.style.left = `${ev.clientX - width}px`;
    }
  });
  el.addEventListener('mouseout', () => {
    div.remove();
  });
};

export const phoneMask = {
  updated(el, binding) {
    mask(el, binding.arg);
  },
};

export const dateMask = {
  mounted(el, binding) {
    if (binding.value) {
      dMask(el);
    }
  },
};

export const snilsMask = {
  updated(el, binding) {
    snils(el, binding.arg);
  },
};

export const showTooltip = {
  mounted(el, binding) {
    tooltip(el, binding.value);
  },
};
