<template>
    <mcw-top-app-bar class="main-toolbar mdc-theme--background">
      <div class="mdc-top-app-bar__row">
        <section
          class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
          <router-link to="/" class="logo-link">
            <img class="logo" src="../assets/medsafe-logo.png" alt="">
          </router-link>
        </section>
        <div class="header__menu-button" ref="menuButton">
        <mcw-button
          @click="toggleMenu()"
          v-show="deviceWidth < DESKTOP_BREAKPOINT"
          :style="'height: 100%'">
          <mcw-material-icon icon="menu"></mcw-material-icon>
        </mcw-button>
        </div>
        <div
          class="header"
          v-show="isMenuVisible || deviceWidth >= DESKTOP_BREAKPOINT"
          ref="menu">
        <section
          class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
          <nav class="header__menu" v-if="isAuth">
            <router-link
              v-for="item in mainMenu"
              :key="item.id"
              :to="item.link"
              class="header__link"
              @click="isMenuVisible = false">
              <mcw-button
                :unelevated="currentRoute === item.link">
                {{item.text}}
              </mcw-button>
              <span class="header__link_note">
                {{item.note ? this[item.note] : ''}}
              </span>
            </router-link>
          </nav>
        </section>
        <section
          class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end">
          <div class="header__element" v-if="isAuth">
            <span class="header__username">
              <mcw-material-icon icon="account_circle" />
              {{userName}}
            </span>
          </div>
          <div class="header__element" v-if="isAuth">
            <mcw-button
              @click="logout(), isMenuVisible = false"
              :disabled="!userName">
              <mcw-material-icon
                icon="logout"
                class="icon_base"
                style="font-size: 16px">
              </mcw-material-icon>
              Выход
            </mcw-button>
          </div>
        </section>
        </div>
      </div>
  </mcw-top-app-bar>
</template>

<script>
import { utils } from '../helpers/utils';
import { DESKTOP_BREAKPOINT } from '../constants';

export default {
  name: 'AppHeader',
  mixins: [
    utils,
  ],
  data() {
    return {
      isMenuOpen: false,
      isEditorOpen: false,
      isContractsOpen: false,
      isMenuVisible: false,
      DESKTOP_BREAKPOINT,
      mainMenu: [
        {
          id: 1,
          link: '/orgs',
          text: 'Организации',
          note: 'companyName',
        },
        {
          id: 2,
          link: '/',
          text: 'Договоры',
          note: 'currentContractWithName',
        },
        {
          id: 3,
          link: '/structure',
          text: 'Сотрудники',
        },
        {
          id: 1,
          link: '/contacts',
          text: 'Контакты',
        },
      ],
    };
  },
  mounted() {
    if (this.deviceWidth >= DESKTOP_BREAKPOINT) {
      return;
    }
    document.addEventListener('click', this.clickHandler);
  },
  computed: {
    currentRoute() {
      return this.$route.href;
    },
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },
    isAuth() {
      return this.$store.state.user.isAuth;
    },
    userName() {
      if (this.$store.state.user.user.vrach_fio !== undefined) {
        return this.trimName(this.$store.state.user.user.vrach_fio);
      }
      return '';
    },
    userRank() {
      return this.$store.getters['user/getUserRank'];
    },
    contractsAvailable() {
      return Object.keys(this.selectedCompany).length;
    },
    companyName() {
      return this.$store.getters['user/getSelectedCompanyName'];
    },
    currentContractWithName() {
      const contractId = this.$store.getters['contractsData/getCurrentContract'];
      const contractName = this.$store.getters['contractsData/getCurrentContractName'];
      if (contractId) {
        return `${contractId} | ${contractName}`;
      }
      return '';
    },
  },
  methods: {
    toggleMenu() {
      if (!this.isAuth) {
        return false;
      }
      this.isMenuVisible = !this.isMenuVisible;
    },
    clickHandler(e) {
      if (!this.isMenuVisible) {
        return;
      }
      const { menu, menuButton } = this.$refs;
      if (!menu.contains(e.target) && !menuButton.contains(e.target)) {
        this.isMenuVisible = false;
      }
    },
    openUsersEditor() {
      this.isEditorOpen = true;
    },
    logout() {
      const logout = this.$store.dispatch('user/logout');
      if (logout) {
        window.location.reload();
      }
    },
  },
};
</script>

<style lang="scss">
  @use "@material/top-app-bar/mdc-top-app-bar";
  @use "@material/button/mdc-button";
  @use "@material/select/mdc-select";
  @use "@material/menu/mdc-menu";
  @use "@material/menu-surface/mdc-menu-surface";
  .logo{
    height: 52px;
    width: 132px;
  }
  .logo-link{
    height: 100%;
    width: 132px;
    border-bottom: none!important;
  }
  .mdc-top-app-bar{
    position: static!important;
    background-color: #eee!important;
  }
  .header{
    display: flex;
    width: 100%;
    flex-direction: column;
    position: absolute;
    background: #fff;
    top: 100%;
    right: 0;
    box-shadow: 0px 5px 5px -3px
      rgb(0 0 0 / 20%), 0px 8px 10px 1px
      rgb(0 0 0 / 14%), 0px 3px 14px 2px
      rgb(0 0 0 / 12%);
    @media screen and (min-width: $tablet) {
      width: 50%;
    }
    @media screen and (min-width: $desktop) {
      width: auto;
      position: static;
      display: flex;
      flex-direction: row;
      flex-grow: 30;
      justify-content: space-between;
      box-shadow: none;
      background: inherit;
    }
    &__username{
      color: var(--icon-color);
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    &__element{
      margin: 0 10px 10px 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-top: 1px solid #aaa;
      width: 90%;
      @media screen and (min-width: $desktop){
        width: auto;
        border: none;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin-left: 10px;
      }
    }
    &__select{
      height: 40px;
    }
    &__link{
      text-decoration: none;
      border-bottom: 2px solid transparent;
      position: relative;
      &_note {
        font-size: 0.6rem;
        color: var(--icon-color);
        @media screen and (min-width: $desktop) {
          max-width: 120px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          position: absolute;
          left: 8px;
          bottom: -12px;
        }
      }
    }
    &__menu{
      display: flex;
      flex-direction: column;
      @media screen and (min-width: $desktop){
        flex-direction: row;
        margin-top: 6px;
      }
    }
  }
  .router-link-active{
    // border-bottom: 2px solid var(--mdc-theme-primary);
    background: rgba(var(--mdc-theme-primary), 1);
  }
  .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor{
    height: 100%;
  }
  .actual-redactor{
    color: var(--icon-color);
      @media screen and (min-width: $desktop){
        margin-right: 10px;
      }
  }
  .mdc-top-app-bar__section{
    flex-direction: column;
    align-items: flex-start!important;
    @media screen and (min-width: $desktop){
      flex-direction: row;
      align-items: center;
    }
  }
</style>
