export const utils = {
  methods: {
    trimName(name) {
      if (name) {
        const nameArr = name.split(' ');
        return `${nameArr[0]} ${nameArr[1].trim().slice(0, 1)}.${nameArr[2].trim().slice(0, 1)}.`;
      }
      return null;
    },
    arrayUnique(array, field = null) {
      if (field) {
        return array.filter((n, i, a) => n === a.find((m) => m[field] === n[field]));
      }
      return Array.from(new Set(array));
    },
    joinName(first, middle, last) {
      return `${last || ''} ${first || ''} ${middle || ''}`;
    },
    readableDate(string) {
      let date;
      if (!string) {
        return '';
      }
      if (string.length === 10) {
        date = new Date(
          string
            .split('.')
            .reverse()
            .join(', '),
        );
      } else {
        date = new Date(string);
      }
      return date.toLocaleDateString('ru-RU');
    },
    strToBool(val) {
      if (typeof val === 'boolean') {
        return val;
      }
      return !!Number(val);
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    showRank(num) {
      if (typeof num !== 'number') {
        return num;
      }
      if (num.toString().length < 4) {
        return num.toString();
      }
      const arr = num
        .toString()
        .split('')
        .reverse();
      for (let i = 0; i < arr.length; i += 4) {
        arr.splice(i, 0, ' ');
      }
      const resultStr = arr.reverse().join('');
      const fractionalPart = resultStr.includes('.');
      if (fractionalPart) {
        return resultStr.replace(' .', '.');
      }
      return resultStr.trim().concat('.00');
    },
    debounce(f, t) {
      const calls = {};
      return (args) => {
        calls.previousCall = calls.lastCall;
        calls.lastCall = Date.now();
        const lastCallTimer = setTimeout(() => f(args), t);
        if (calls.previousCall && calls.lastCall - calls.previousCall <= t) {
          clearTimeout(lastCallTimer);
        }
      };
    },
    getSum(field) {
      let sum = 0;
      this.items.forEach((item) => {
        if (item[field] && typeof item[field] === 'number') {
          sum += item[field];
        }
      });
      return Math.round(sum, 0);
    },
    validateSnils(snils) {
      const extractDigits = (str) => str.split('').filter((item) => item.charCodeAt(0) > 47 && item.charCodeAt(0) < 58);
      if (snils.length !== 14) {
        return 'Некорректный номер СНИЛС!';
      }
      const digits = extractDigits(snils);
      const arr = digits.splice(0, 9).reverse();
      const sum = arr.reduce((acc, cur, index) => {
        // eslint-disable-next-line operator-assignment
        acc = acc + cur * (index + 1);
        return acc;
      }, 0);
      const controlSum = Number(digits[0] + digits[1]);
      if (sum < 100) {
        return controlSum === sum;
      }
      if (sum === 100 || sum === 101) {
        return controlSum === 0;
      }
      if (sum > 101) {
        const diff = sum % 101;
        return controlSum === diff;
      }
    },
    scrollToActive(anchor) {
      const rowScrollTo = this.$refs[`row-${anchor}`];
      const { table } = this.$refs;
      if (!rowScrollTo || !table) {
        return;
      }
      const { offsetTop } = rowScrollTo;
      table.scrollTop = offsetTop - 120;
    },
    cropString(str, amount, ellipsis = null) {
      if (str.length <= amount) {
        return str;
      }
      const toReturn = str.slice(0, amount);
      if (ellipsis) {
        return `${toReturn}...`;
      }
      return toReturn;
    },
  },
};
