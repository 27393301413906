<template>
  <div class="load-patients">
    <mcw-dialog-title>{{title}}</mcw-dialog-title>
    <mcw-dialog-content>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button action="dismiss">отмена</mcw-dialog-button>
      <mcw-dialog-button action="accept" @click="loadPatients">ок</mcw-dialog-button>
    </mcw-dialog-footer>
  </div>
</template>

<script>
export default {
  name: 'LoadPatientsDialog',
  props: {
    title: {
      type: String,
    },
  },
  emits: [
    'loadPatients',
  ],
  data() {
    return {
      // includeServices: false,
    };
  },
  methods: {
    loadPatients() {
      this.$emit('loadPatients');
    },
  },
};
</script>

<style>
.load-patients__checkbox{
  padding: 20px;
}
</style>
