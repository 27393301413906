<template>
  <section class='patient-address-container'>
    <mcw-list v-if='addresses'>
      <mcw-list-item v-for='address in addresses' :key='address.id_address'>
        <div v-if='!selectedAddress' class='address-text-wrapper'>
          <span>{{ address.text_address }}</span>
          <mcw-material-icon icon="edit" @click.stop='selectAddress(address)'/>
        </div>
        <div v-if='selectedAddress && selectedAddress.id_address === address.id_address' class='edit-field'>
          <mcw-textfield
            v-model="search"
            placeholder="Адрес"
            @input='searchDadata'
            list="autocomplite-list"
            outline />
          <mcw-material-icon icon="done" @click.stop='editAddress'/>
          <mcw-material-icon icon="close" @click.stop='selectedAddress = null'/>
        </div>
      </mcw-list-item>
    </mcw-list>
    <div v-else class='edit-field'>
      <mcw-textfield
        v-model="search"
        @input='searchDadata'
        placeholder="Адрес"
        list="autocomplite-list"
        outline />
      <mcw-material-icon icon="done" @click.stop='editAddress'/>
    </div>
    <datalist id='autocomplite-list' v-if='autocomplite.length'>
      <option v-for="(variant, index) in autocomplite" :key='index'>{{ variant.value }}</option>
    </datalist>
  </section>
</template>

<script>
import { debounce } from 'debounce';

export default {
  name: 'PatientAddress',
  props: {
    addresses: Array,
    patient: Number,
  },
  emits: ['getAddresses'],
  data() {
    return {
      selectedAddress: null,
      search: '',
      autocomplite: [],
      selectedVariant: null,
    };
  },
  watch: {
    selectedAddress(val) {
      if (val) this.search = val.text_address;
    },
  },
  methods: {
    selectAddress(a) {
      this.selectedAddress = a;
    },
    async editAddress() {
      const result = await this.$store.dispatch('employeesData/postPatientAddress', {
        id_patient: this.patient,
        text_address: this.search,
        dadata: this.selectedVariant ? JSON.stringify(this.selectedVariant) : '',
        id_address: this.addresses ? this.addresses[0].id_address : 0,
      });
      if (result) {
        await this.emitter.emit('getAddresses');
        this.selectedAddress = null;
      }
    },
    searchDadata: debounce(function () {
      let arr = [];
      const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
      const token = '0031b8c5489ec4b645eba12368213f7b32b14587';
      const query = this.search;
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ query }),
      };
      fetch(url, options)
        .then((response) => response.text())
        .then((result) => {
          arr = JSON.parse(result);
          this.autocomplite = arr.suggestions;
        })
        .catch((error) => console.log('error', error));
      this.selectedVariant = this.autocomplite.find((i) => this.search === i.value);
    }, 500),
  },
  computed: {},
};
</script>

<style>
.patient-address-container {
  min-width: 500px;
}
.edit-field {
  width: 100%;
  display: flex;
  align-items: center;
}
.edit-field i {
  padding: 0 15px;
}
.patient-address-container .textfield-container {
  width: 80%;
  margin-top: 0 !important;
}
.address-text-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
}
.patient-address-container .mdc-list-item__text {
  width: 100%;
}
</style>
