import { RESIZER_SIZE } from '../constants';

let prevPosition = 0;

export const resizer = {
  methods: {
    startResize() {
      document.addEventListener('mousemove', this.resize);
      document.addEventListener('mouseup', this.endResize);
    },
    resize(evt) {
      const {
        topSection, bottomSection, leftSection, rightSection,
      } = this.$refs;

      const vResize = () => {
        const topHeight = topSection.offsetHeight;
        const bottomHeight = bottomSection.offsetHeight;
        const maxHeight = (topHeight + bottomHeight) - RESIZER_SIZE;
        const direction = evt.clientY < prevPosition ? 'up' : 'down';
        if ((topHeight >= maxHeight && direction === 'down') || (bottomHeight >= maxHeight && direction === 'up')) {
          return;
        }
        topSection.style.height = `${topHeight + evt.movementY}px`;
        bottomSection.style.height = `${bottomHeight - evt.movementY}px`;
        prevPosition = evt.clientY;
      };

      const hResize = () => {
        const leftWidth = leftSection.offsetWidth;
        const rightWidth = rightSection.offsetWidth;
        const maxWidth = (leftWidth + rightWidth) - RESIZER_SIZE;
        const direction = evt.clientX < prevPosition ? 'left' : 'right';
        if ((leftWidth >= maxWidth && direction === 'right') || (rightWidth >= maxWidth && direction === 'left')) {
          return;
        }
        leftSection.style.width = `${leftWidth + evt.movementX}px`;
        rightSection.style.width = `${rightWidth - evt.movementX}px`;
        prevPosition = evt.clientX;
      };

      if (topSection && bottomSection) {
        vResize();
      } else if (leftSection && rightSection) {
        hResize();
      }
    },
    endResize() {
      prevPosition = 0;
      document.removeEventListener('mousemove', this.resize);
      document.removeEventListener('mouseup', this.endResize);
    },
  },
};
