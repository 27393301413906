<template>
  <table class="mdc-data-table__table">
    <thead>
      <tr class="mdc-data-table__header-row">
      </tr>
    </thead>
    <tbody>
      <tr
        class="mdc-data-table__row"
        v-for="(item, index) in data"
        :key="index">
        <td class="mdc-data-table__cell">
          {{transcriptColumnTitle(item[0])}}
        </td>
        <td v-if="item[1].toString().length < 30" class="mdc-data-table__cell">
          {{item[1]}}
        </td>
        <ExpandableCell v-else :content="item[1]" maxWidth="160" />
      </tr>
    </tbody>
  </table>
</template>

<script>
import { transcriptColumnTitle } from '../helpers/transcriptor';
import ExpandableCell from './ExpandableCell.vue';

const cells = [
  'N_contract',
  'date_add',
  'date_contract',
  'id_contract',
  'name_contract',
  'post',
  'conclusion',
  'recomendation',
];

export default {
  name: 'AddFieldsTable',
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ExpandableCell,
  },
  computed: {
    data() {
      const data = Object.entries(this.items);
      return data.filter((item) => cells.includes(item[0]));
    },
  },
  methods: {
    transcriptColumnTitle(column) {
      return transcriptColumnTitle(column);
    },
  },
};
</script>

<style>

</style>
