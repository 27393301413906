<template>
  <div class="table-container"
    ref="container"
    @dragover.prevent
    @dragenter.prevent
    @dragend="clearHighLight"
    @drop.capture="onDrop($event)">
      <AllPatientsTable
        :tableHeight="100"
        @hideAllPatients="closeAllPatientsTable"
        v-if="allPatients && visibleTable.includes(3)"
        :items="allPatients"/>
      <EmployeesTable
        :tableHeight="!patients.length ? 100 : 50"
        v-if="employees.length && visibleTable.includes(2)"
        :items="employees"
        :tableTitle="title"/>
      <QuestionedTable
        @hideQuestioned="closeQuestionedTable"
        :tableHeight="100"
        v-if="questioned && visibleTable.includes(4)"
        :items="questioned"/>
      <PatientsTable
        :infobox="infobox"
        :tableHeight="!employees.length ? 100 : 50"
        v-if="selectedItem"
        :items="patients"
        :divisionId="selectedItem ? selectedItem.data.parentId : null"
        :positionId="selectedItem ? selectedItem.data.id : null"/>
        <mcw-material-icon
          class="table-container__arrow"
          icon="arrow_downward"
          v-if="highLightTable">
        </mcw-material-icon>
  </div>
</template>

<script>
import QuestionedTable from './QuestionedTable.vue';
import AllPatientsTable from './AllPatientsTable.vue';
import PatientsTable from './PatientsTable.vue';
import EmployeesTable from './EmployeesTable.vue';

export default {
  name: 'TableWrapper',
  components: {
    QuestionedTable,
    AllPatientsTable,
    PatientsTable,
    EmployeesTable,
  },
  props: {
    visibleTable: Array,
    highLightTable: Boolean,
    title: String,
  },
  emits: [
    'closeAll',
  ],
  data() {
    return {
    };
  },
  computed: {
    patients() {
      return this.$store.getters['employeesData/getPatients'];
    },
    employees() {
      return this.$store.getters['employeesData/getEmployees'];
    },
    allPatients() {
      return this.$store.getters['employeesData/getAllPatients'];
    },
    questioned() {
      return this.$store.getters['employeesData/getQuestioned'];
    },
    selectedItem() {
      return this.$store.state.treeData.selectedItem;
    },
    infobox() {
      const type = this.$store.getters['treeData/getSelectedItemType'];
      if (type === 'leaf' && this.employees.length) {
        return true;
      }
      return false;
    },
  },
  methods: {
    closeAllPatientsTable() {
      this.$emit('closeAll');
    },
    closeQuestionedTable() {
      this.$emit('closeAll');
    },
    setContainerHeight() {
      const height = this.$refs.container.offsetHeight;
      this.$refs.container.style.height = `${height}px`;
    },
  },
};
</script>

<style>
  .table-summary {
    font-size: 1rem;
  }
</style>
