<template>
  <mcw-list-item @click="clickHandler($event)">
    <mcw-material-icon
      :icon="expanded ? 'expand_less' : 'expand_more'"
      class="icon_base">
    </mcw-material-icon>
    {{docType.doc_name}}
  </mcw-list-item>
  <mcw-list-item
    class="list-item-child"
    @click="confirmCreateDoc($event, template)"
    v-for="template in docType.children"
    :key="template.id_template">
    {{template.name_template}}
  </mcw-list-item>
</template>

<script>
export default {
  name: 'DocTypesList',
  emits: [
    'expand', 'confirmCreate',
  ],
  props: {
    docType: {
      type: Object,
      default: () => {},
    },
    expanded: {
      type: Boolean,
      default: () => false,
    },
    id: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    clickHandler(event) {
      event.stopPropagation();
      this.$emit('expand', this.docType.doc_type);
    },
    confirmCreateDoc(event, template) {
      event.stopPropagation();
      this.$emit('confirmCreate', template);
    },
    async addDoc(params) {
      const { templateId, fileName } = params;
      const newDoc = await this.$store.dispatch('contractsData/createDoc', {
        templateId,
        fileName,
        type: this.docType.doc_type,
        contractId: this.id,
      });
      if (newDoc) {
        this.emitter.emit('message', 'Документ создан');
      } else {
        this.emitter.emit('message', 'Не удалось создать документ');
      }
    },
  },
};
</script>

<style scoped>
  .list-item-child{
    padding-left: 60px;
  }
</style>
