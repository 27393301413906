<template>
  <section>
    <div>
      <mcw-select
        class='foreign-doc-form-select'
        v-model="selectedType"
        outlined
        label="Тип документа"
      >
        <mcw-list-item
          v-for="type of foreignTypes"
          :key="type.id"
          :data-value="type.id"
          role="option"
          >{{ type.doc_name }}</mcw-list-item
        >
      </mcw-select>
      <div v-if='selectedType'>
        <mcw-button
          @click="openFileUpload">
          <mcw-material-icon icon="attach_file" /> Прикрепить
        </mcw-button>
        <mcw-button
          @click="fillMode = true">
          <mcw-material-icon icon="edit" /> Заполнить
        </mcw-button>
        <input
          type="file"
          ref="file"
          class="file-upload__input_sizeless"
          v-on:change="uploadFile()"/>
      </div>
      <div v-if='fillMode'>
        <EditFillableDocument :id='+selectedType' :patient='patient'/>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import EditFillableDocument from './EditFillableDocument.vue';

export default {
  name: 'AddForeignDocument',
  components: { EditFillableDocument },
  emits: [
    'closeAdditing',
    'getForeignDocuments',
  ],
  props: {
    patient: Number,
  },
  data() {
    return {
      selectedType: null,
      fillMode: false,
      file: null,
    };
  },
  methods: {
    openFileUpload() {
      this.$refs.file.click();
    },
    async uploadFile() {
      [this.file] = this.$refs.file.files;
      const upload = await this.$store.dispatch('contractsData/uploadFile', { file: this.file, contractId: this.patient, typeId: this.selectedType });
      if (upload) {
        this.emitter.emit('message', 'Файл успешно загружен');
      } else {
        this.emitter.emit('message', 'Не удалось загрузить файл');
      }
      await this.emitter.emit('getForeignDocuments');
      this.emitter.emit('closeAdditing');
    },
  },
  watch: {
    selectedType(val) {
      if (val) {
        this.fillMode = false;
      }
    },
  },
  computed: {
    ...mapState({
      foreignTypes: (state) => state.employeesData.foreignTypes,
    }),
  },
};
</script>

<style>
.foreign-doc-form-select.mdc-select {
  width: 100%;
}
</style>
