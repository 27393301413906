<template>
  <mcw-dialog-title>
    Экпорт в .xlsx-файл
    <mcw-icon-button class="btn_close" @click="$emit('close')">
      <mcw-material-icon icon="close" />
    </mcw-icon-button>
  </mcw-dialog-title>
  <mcw-dialog-content>
    <div v-if="visibleColumns.length">
      <h3>Выберите столбцы для экспорта</h3>
      <div class="export-dialog__columns">
        <mcw-checkbox
          v-for="column in visibleColumns"
          :key="column"
          v-model="selectedColumns"
          :value="column"
          :label="transcriptColumnTitle(column)"
        >
        </mcw-checkbox>
      </div>
      <mcw-textfield outline dense v-model="name" label="Имя файла" />
      <div v-if="showCheckbox">
        <mcw-checkbox
          v-model="includeServices"
          label="Включить в файл расшифровку по услугам?"
        >
        </mcw-checkbox>
        (формирование таблицы с услугами может занять продолжительное время)
      </div>
    </div>
  </mcw-dialog-content>
  <mcw-dialog-footer>
    <mcw-dialog-button action="dismiss">Отмена</mcw-dialog-button>
    <mcw-dialog-button @click.prevent="exportToExcel">
      скачать файл
    </mcw-dialog-button>
  </mcw-dialog-footer>
</template>

<script>
import { createExcel } from '../helpers/createExcel';
import { transcriptColumnTitle } from '../helpers/transcriptor';

export default {
  name: 'ExportDialog',
  emits: [
    'close',
  ],
  props: {
    showCheckbox: Boolean,
    title: String,
    table: Array,
  },
  data() {
    return {
      name: '',
      selectedColumns: [],
      includeServices: false,
    };
  },
  created() {
    this.name = `${this.title}|${this.companyName}|${new Date().toLocaleDateString('ru-RU')}`;
    if (this.title === 'Последние визиты') {
      this.name += `|${this.datesRange.start}-${this.datesRange.end}`;
    }
    this.visibleColumns.forEach((item) => this.selectedColumns.push(item));
  },
  computed: {
    columns() {
      return Object.keys(this.table[0]).filter((el) => el !== 'services');
    },
    companyName() {
      return this.$store.getters['user/getSelectedCompanyName'];
    },
    tableColumnNames() {
      return this.$store.getters.tableColumns;
    },
    visibleColumns() {
      return this.columns
        .filter((column) => this.tableColumnNames
          .find((item) => item.name_variable === column && item.field_title));
    },
    datesRange() {
      return this.$store.state.employeesData.patientsDatesRange;
    },
  },
  methods: {
    transcriptColumnTitle(column) {
      return transcriptColumnTitle(column);
    },
    async exportToExcel() {
      let tableData;
      let addServices;
      if (this.includeServices) {
        this.emitter.emit('globalLoading', true);
        let actId = null;
        const isAct = this.$store.state.employeesData.actLoaded?.actId;
        if (isAct) {
          actId = isAct;
        }
        tableData = await this.$store.dispatch('employeesData/getLastPatients', { includeServices: true, actId, returnOnly: true });
        addServices = true;
        this.emitter.emit('globalLoading', false);
      } else {
        tableData = this.table;
        addServices = false;
      }
      let selectedColumns = this.selectedColumns.slice();
      if (this.includeServices) {
        selectedColumns.push('services');
      }
      if (!this.visibleColumns.length) {
        selectedColumns = this.columns;
      }

      const params = {
        addServices,
        tableData,
        selectedColumns,
        tableColumnNames: this.tableColumnNames,
        title: this.title,
      };
      if (!this.selectedColumns.length && this.visibleColumns.length) {
        this.emitter.emit('message', 'Выберите хотя бы 1 столбец!');
        return;
      }
      if (!this.name.length) {
        this.name = `${this.title} | ${this.companyName}`;
      }
      const wb = await createExcel(params);
      wb.xlsx.writeBuffer({
        base64: true,
      }).then((xls64) => {
        // build anchor tag and attach file (works in chrome)
        const a = document.createElement('a');
        const data = new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(data);
        a.href = url;
        a.download = `${this.name}.xlsx`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }).catch((error) => {
        console.log(error.message);
      });
      this.$emit('close');
    },
  },
};
</script>

<style></style>
