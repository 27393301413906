<template>
  <div class="patient-data-table">
      <table class="mdc-data-table__table patient-table">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell">{{transcriptColumnTitle('id_patient')}}</th>
            <th class="mdc-data-table__header-cell">{{transcriptColumnTitle('n_docs')}}</th>
            <th class="mdc-data-table__header-cell">{{transcriptColumnTitle('name_osmotr')}}</th>
            <th class="mdc-data-table__header-cell">{{transcriptColumnTitle('branch')}}</th>
            <th class="mdc-data-table__header-cell">{{transcriptColumnTitle('datebegin')}}</th>
            <th class="mdc-data-table__header-cell">{{transcriptColumnTitle('login_add')}}</th>
            <th class="mdc-data-table__header-cell">{{transcriptColumnTitle('status')}}</th>
            <th class="mdc-data-table__header-cell"></th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="item in items"
            :key="item.main.id_patient">
            <tr
              :class="['mdc-data-table__row',
                'mdc-data-table__row_clickable',
                {row_active: item.main.id_patient === expanded}]"
              @click="expand(item.main.id_patient)">
              <td class="mdc-data-table__cell">
                {{ item.main.id_patient }}
              </td>
              <td class="table__cell">
                <PatientDocList
                  :patientData="item.main"
                  @refresh="checkDocStatus"
                  @closeOthers="closeMenus"
                  ref="docLists"
                />
              </td>
              <td class="mdc-data-table__cell">
                {{ item.main.name_osmotr }}
              </td>
              <td class="mdc-data-table__cell">
                {{ item.main.branch }}
              </td>
              <td class="mdc-data-table__cell">
                {{ item.main.datebegin }}
              </td>
              <td class="mdc-data-table__cell">
                {{ item.main.login_add }}
              </td>
              <td class="mdc-data-table__cell">
                {{ item.main.status }}
              </td>
              <td class="mdc-data-table__cell">
                {{ item.main.conclusion_date }}
              </td>
              <td class="mdc-data-table__cell">
                {{ getServicesSum(item.main.services) }}
              </td>
            </tr>
            <tr v-if="item.main.id_patient === expanded">
              <td colspan="9">
                <mcw-layout-grid>
                  <mcw-layout-cell desktop="6" phone="12" align="top">
                    <AddFieldsTable :items="item.add" />
                  </mcw-layout-cell>
                  <mcw-layout-cell desktop="6" phone="12" align="top">
                    <ServicesTable
                      v-if="item.add.services"
                      :services="item.add.services"/>
                  </mcw-layout-cell>
                </mcw-layout-grid>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
  </div>
</template>

<script>
import PatientDocList from './PatientDocList.vue';
import ServicesTable from './ServicesTable.vue';
import AddFieldsTable from './AddFieldsTable.vue';
import { transcriptColumnTitle } from '../helpers/transcriptor';

export default {
  name: 'PatientDataTable',
  emits: [
    'showDocs', 'selectPatient',
  ],
  props: {
    patient: Object,
    items: Array,
  },
  components: {
    PatientDocList,
    ServicesTable,
    AddFieldsTable,
  },
  data() {
    return {
      expanded: null,
      isSendVisible: false,
      doc: null,
      addFields: false,
    };
  },
  methods: {
    checkDocStatus() {
      this.emitter.emit('checkDocStatus', (this.patient, true));
    },
    expand(id) {
      if (id === this.expanded) {
        this.expanded = null;
        return false;
      }
      this.expanded = id;
      this.$emit('selectPatient', id);
    },
    getServicesSum(services) {
      if (!services) {
        return '';
      }
      const arr = JSON.parse(services);
      // eslint-disable-next-line no-return-assign
      return arr.reduce(((acc, item) => acc += Number(item.price)), 0);
    },
    transcriptColumnTitle(column) {
      return transcriptColumnTitle(column);
    },
    expandAddFields(index) {
      const selectedVisit = this.items[index].add;
      this.addFields = !this.addFields;
    },
    closeMenus(id) {
      this.emitter.emit('closeDocLists', id);
    },
  },
};
</script>

<style lang="scss">
@use "@material/data-table/mdc-data-table";
.patient-data-table {
  border: 2px solid var(--mdc-theme-primary);
  padding: 0 5px;
}
.patient-data-table .mdc-dialog__content {
  // overflow-y: auto;
  // height: calc(100vh - 124px);
}
.patient-table {
  &__close-btn{
    right: 0!important;
    top: 0!important;
    --mdc-ripple-left: 10px!important;
    --mdc-ripple-top: 10px!important;
  }
  &__close-btn:hover{
    --mdc-ripple-left: 10px!important;
    --mdc-ripple-top: 10px!important;
  }
  &__conclusion{
    max-width: 200px;
    white-space: break-spaces;
  }
}
.row_expandable{
  cursor: pointer;
}
.row_active{
  cursor: pointer;
  background-color: rgba($color: #000000, $alpha: 0.08);
}
.mdc-dialog__content{
  overflow: initial;
}
</style>
