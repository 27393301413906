import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    item: Object,
    filterterm: String,
    isBlocked: Boolean,
    icon: String,
  },
  emits: [
    'test',
  ],
  data() {
    return {
      isOpen: false,
      isDragging: false,
      isHovered: false,
      isChildrenBlocked: false,
      pasteDirection: '',
    };
  },
  beforeMount() {
    if (Object.prototype.hasOwnProperty.call(this.item, 'isOpened')) {
      this.isOpen = this.item.data.isOpened;
    } else {
      const { id } = this.item.data;
      if (this.$store.getters['treeData/getToggleState'](id)) {
        this.isOpen = false;
      }
      if (!this.item.data.parentId) {
        this.isOpen = true;
      }
    }
  },
  created() {
    this.$store.watch(
      (state) => this.$store.state.treeData.resetId,
      (val) => {
        if (val && val === this.item.data.id) {
          this.isChildrenBlocked = false;
          this.$store.commit('treeData/RESET_BLOCK', null);
        }
      },
    );
  },
  computed: {
    isFolder() {
      // return this.item.children && (this.item.data.type === 'node' || !this.item.data.parentId);
      return (Object.prototype.hasOwnProperty.call(this.item.data, 'has_children') && this.item.data.has_children === 1) || !this.item.data.parentId;
    },
    isSelected() {
      return this.$store.state.treeData.selectedItem === this.item;
    },
    peopleAmount() {
      return this.$store.getters['treeData/getPeopleAmountById'](this.item.data.id);
    },
    hazardAmount() {
      return this.$store.getters['treeData/getHazardAmountById'](this.item.data.id);
    },
    highLightLeafs() {
      if (this.item.data.type === 'leaf') {
        return this.$store.state.treeData.highLightLeafs;
      }
      return false;
    },
    canIShow() {
      return (this.isFolder && this.filterterm.length && !this.filterlist.length);
    },
    filterlist() {
      const self = this;
      const exp = new RegExp(self.filterterm, 'i');
      // eslint-disable-next-line arrow-body-style
      const deepFilter = (children) => {
        return children.filter((item) => {
          const c = item.children.length ? deepFilter(item.children) : exp.test(item.data.text);
          if (c.length === 0) return false;
          return c;
        });
      };
      const filtering = deepFilter(this.item.children);
      return filtering;
    },
    ...mapState({
      tableType: (state) => state.employeesData.tableType,
    }),
  },
  watch: {
    isBlocked() {
      this.isChildrenBlocked = this.isBlocked;
    },
  },
  methods: {
    ...mapMutations({
      PASTE_ITEM: 'treeData/PASTE_ITEM',
      MOVE_ITEM: 'treeData/MOVE_ITEM',
      RESET_BLOCK: 'treeData/RESET_BLOCK',
      SELECT_ITEM: 'treeData/SELECT_ITEM',
    }),
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
        const toggleData = {
          id: this.item.data.id,
          state: this.isOpen,
        };
        if (!Object.prototype.hasOwnProperty.call(this.item.data, 'isOpened') && this.isOpen) {
          this.$store.dispatch('treeData/loadTreeNode', this.item.data.id);
        }
        this.$store.dispatch('treeData/setItemState', toggleData);
      }
    },
    select() {
      this.SELECT_ITEM(this.item);
      if (this.item.data.type === 'leaf') {
        this.$store.dispatch('employeesData/loadPatients', { id: this.item.data.id, type: 0 });
      }
    },
    selectAll() {
      if (this.item.data.type === 'leaf') {
        return;
      }
      this.SELECT_ITEM(this.item);
      this.$store.dispatch('employeesData/loadPatients', { id: this.item.data.id, type: 1 });
    },
    startDrag(evt) {
      this.isChildrenBlocked = true;
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('itemID', evt.target.dataset.id);
      this.$store.commit('treeData/SAVE_DRAGGED_ID', +evt.target.dataset.id);
    },
    onDrop(evt) {
      const itemId = +evt.dataTransfer.getData('itemID');
      const patient = evt.dataTransfer.getData('item');
      if (!itemId && !patient) {
        return;
      }
      if (itemId) {
        const { target } = this.$refs;
        const { direction } = target.dataset;
        const id = +target.dataset.id;
        if (id === itemId || target.dataset.blocked === 'true') {
          this.RESET_BLOCK(+itemId);
          return;
        }
        if (id === 0) {
          this.dropHandler({ itemId, targetObj: 'root', direction: null });
          return;
        }
        const targetObj = this.$store.getters['treeData/getTargetObj'](id);
        const oldParentId = this.$store.getters['treeData/getItemById'](itemId).parentId;
        if (targetObj) {
          this.dropHandler({
            itemId,
            oldParentId,
            targetObj,
            direction,
          });
        }
        this.SELECT_ITEM(null);
        this.RESET_BLOCK(+itemId);
      }
      if (patient && this.item.data.type === 'leaf' && this.tableType !== 'allPatients') {
        this.emitter.emit('attachPatient', { patient: JSON.parse(patient), node: this.item.data.id });
      }
      if (patient && this.item.data.type === 'leaf' && this.tableType === 'allPatients') {
        this.emitter.emit('staffFromEmployees', { patient: JSON.parse(patient), node: this.item.data.id });
        this.$store.commit('employeesData/SET_TABLE_TYPE', null);
      }
      this.$store.commit('treeData/DRAG_PREVENT', false);
    },
    dropHandler(data) {
      if ((data.targetObj.type === 'node' && data.direction === 'into') || data.targetObj === 'root') {
        this.$store.dispatch('treeData/pasteItem', data);
      } else {
        this.$store.dispatch('treeData/moveItem', data);
      }
    },
    onDragOver(evt) {
      if (this.$store.state.treeData.dragPrevent) {
        return;
      }
      const { target } = this.$refs;
      if (target.dataset.type === 'uncategorized') {
        return;
      }
      const id = this.$store.state.treeData.draggedId;
      if (target) {
        const range = {
          top: target.getBoundingClientRect().top,
          bottom: target.getBoundingClientRect().bottom,
        };
        const halfHeight = (range.bottom - range.top) / 3;
        if (evt.clientY < range.top + halfHeight) {
          target.classList.add('paste_over');
          target.classList.remove('paste_under', 'paste_into');
          this.pasteDirection = 'over';
        } else if (evt.clientY > range.bottom - halfHeight) {
          target.classList.add('paste_under');
          target.classList.remove('paste_over', 'paste_into');
          this.pasteDirection = 'under';
        } else if (target.dataset.blocked !== 'true' && this.item.data.id !== id && target.dataset.type !== 'leaf') {
          this.pasteDirection = 'into';
          target.classList.add('paste_into');
          target.classList.remove('paste_over', 'paste_under');
        }
      }
    },
    onOpenStart(el) {
      el.style.display = 'block';
      const virtualHeight = el.scrollHeight;
      el.style.display = 'none';
      document.body.style.setProperty('--h', `${virtualHeight}px`);
      if (this.isOpen) {
        el.style.display = 'block';
      }
    },
    onCloseStart(el) {
      document.body.style.setProperty('--h', `${el.scrollHeight}px`);
    },
    onTransitionEnd() {
      document.body.style.setProperty('--h', '0px');
    },
  },
};
