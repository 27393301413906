<template>
  <div class="bug-report">
    <mcw-dialog-title>
      Отправить отчет об ошибке
      <mcw-icon-button class="btn_close" @click="$emit('close')">
        <mcw-material-icon icon="close"/>
      </mcw-icon-button>
    </mcw-dialog-title>
      <mcw-dialog-content>
        <div class="bug-report__canvas-field" id="canvas" ref="canvas"></div>
        <p><b>Пожалуйста, сопроводите отчет об ошибке пояснениями!</b></p>
        <mcw-textfield
          tabindex="0"
          v-model="text"
          label="Сообщение"
          multiline rows="4"
          outline
          required
          cols="30"/>
      </mcw-dialog-content>
      <mcw-dialog-footer>
        <mcw-dialog-button action="dismiss">отмена</mcw-dialog-button>
        <mcw-dialog-button @click="sendReport">отправить</mcw-dialog-button>
      </mcw-dialog-footer>
  </div>
</template>

<script>
export default {
  name: 'BugReport',
  emits: [
    'close',
  ],
  props: {
    el: HTMLCanvasElement,
  },
  data() {
    return {
      text: '',
    };
  },
  async mounted() {
    const field = this.$refs.canvas;
    field.append(this.el);
    this.emitter.emit('globalLoading', false);
  },
  methods: {
    async sendReport() {
      const params = {
        file: this.el,
        text: this.text,
      };
      const report = await this.$store.dispatch('sendBugReport', params);
      if (report) {
        this.emitter.emit('message', 'Ваше сообщение об ошибке успешно отправлено');
        this.$emit('close');
      } else {
        this.emitter.emit('message', 'Не удалось отправить сообщение');
      }
    },
  },
};
</script>

<style lang="scss">
.bug-report{
  &__canvas-field{
    width: 317px;
    height: 200px;
    margin: auto;
    & canvas{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
