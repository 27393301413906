<template>
  <div
  class="table__container dense-grid-table"
  ref="container"
  :style="height"
  @dragover.prevent
  @dragenter.prevent
  @dragend="clearHighLight"
  @drop.capture="onDrop($event)">
        <div class="sticky-header">
        <h2
          class="table__title">
          {{ _title }}
          <span class='table-summary' v-if="currentContract && !actLoaded"> ID {{currentContract}} | </span>
          <span class='table-summary' v-if='items.length' v-show="!showOnePatientResults">
            Количество записей: {{patsCountSum}}
          </span>
          <div class='table-summary' v-if='actLoaded'>
            <span v-if="actLoaded.actId && actLoaded.actId !== -1"> | Акт: ID {{actLoaded.actId}} </span>
            <span> | Сумма: {{actLoaded.sum}} </span>
          </div>
          <mcw-button
            v-show="!showOnePatientResults"
            @click="isPeriodsOpened = true" v-if="!actLoaded && !currentContract"
          >{{datesRange.start}} ~ {{datesRange.end}}</mcw-button>
          <mcw-switch
            v-model="myRecords"
            :label="myRecords ? 'Все записи' : 'Мои записи'"
            class="table__checkbox-wrapper" />
          <mcw-icon-button
            class="table__close-btn"
            v-if="items.length"
            @click="closeTable">
            <mcw-material-icon icon="close"/>
          </mcw-icon-button>
        </h2>
        <div class="table__header">
          <div class="search-with-button">
            <mcw-textfield
              v-model.trim="searchString"
              placeholder="Поиск (ФИО)"
              class="table__filter"
              dense
              outline
              @keypress.enter="searchOnePatient"
            />
            <mcw-button
              @click="searchOnePatient"
            >
              <mcw-material-icon
                class="icon_base"
                icon="search">
              </mcw-material-icon>
            </mcw-button>
          </div>
          <mcw-button
            class="table__export-btn"
            @click="exportListAttended"
            :disabled="!items.length">
            <mcw-material-icon
              :class="items.length ? 'icon_base' : 'icon_grey'"
              icon="download">
            </mcw-material-icon>
            Детализация прохождения
          </mcw-button>
          <mcw-button
            class="table__export-btn"
            @click="exportToExcel"
            :disabled="!items.length">
            <mcw-material-icon
              :class="items.length ? 'icon_base' : 'icon_grey'"
              icon="download">
            </mcw-material-icon>
            Экпорт в excel
          </mcw-button>
      </div>
      </div>
  <div
    class="table"
    v-if="items.length"
  >
    <mdc-data-table
      sticky
      >
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row ">
            <th class="mdc-data-table__header-cell">
              Визиты
            </th>
            <th class="mdc-data-table__header-cell">
              ID пациента
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('id_contract')">
              ID договора
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('fio')">
              {{transcriptColumnTitle('fio')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('sex')">
              {{transcriptColumnTitle('sex')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--numeric"
              @click="sortTable('datebirth', 'date')">
              {{transcriptColumnTitle('datebirth')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--numeric"
              @click="sortTable('date_vizit', 'date')">
              {{transcriptColumnTitle('date_vizit')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-menu">
              <MultiSelect
                :key="MSKey"
                v-if="statuses.length"
                :options="statuses"
                label="Статус"
                v-model="selectedStatuses"/>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('sum_pat')">
              {{transcriptColumnTitle('sum_pat')}}
            </th>
            <th class="mdc-data-table__header-cell">
              Акт
            </th>
          </tr>
        </thead>
        <tbody
          class="mdc-data-table__content">
          <Spinner v-show="isLoading"/>
          <template
            v-for="item in filteredData"
            :key="item.id_patient">
            <tr class="mdc-data-table__row row_draggable"
              :class="{patient__row_selected: selectedPatient === item.id_patient}"
              @click='selectPatient(item.id_patient)'
              v-show="!filteredData.length || filteredData.includes(item)"
              draggable="true"
              @dragstart.capture="startDrag($event, item)">
              <td class="table__cell">
                <mcw-button
                  class="all-patient-table-btn visits">
                  <mcw-material-icon icon="info" />
                  <span class="contacts-qty">{{item.n_pat}}/{{item.pat_count}}</span>
                </mcw-button>
              </td>
              <td class="table__cell">{{item.id_patient}}</td>
              <td class="table__cell">{{item.id_contract}}</td>
              <td class="table__cell">{{item.fio}}</td>
              <td class="table__cell">{{item.sex}}</td>
              <td class="table__cell mdc-data-table__cell--numeric">{{item.datebirth}}</td>
              <td class="table__cell mdc-data-table__cell--numeric">{{item.date_vizit}}</td>
              <td class="table__cell">{{item.status}}</td>
              <td class="table__cell mdc-data-table__cell--numeric">{{showRank(item.sum_pat)}}</td>
              <td class="table__cell">
                  <mcw-material-icon
                    :icon="item.inside_act === 1 ? 'task_alt' : item.inside_act === 0 ? 'radio_button_unchecked' : 'radio_button_on'">
                  </mcw-material-icon>
              </td>
            </tr>
            <tr
              :class="{patient__row_selected: selectedPatient === item.id_patient}"
              v-if="selectedPatient && selectedPatient === item.id_patient">
              <td colspan="12">
                <section
                  :id="item.id_patient"
                  >
                  <section class='row-details'>
                    <span>Договор: <b>{{ item.name_contract ? item.name_contract : "Без названия" }}</b>
                    | В штате: <b>{{item.id_tree_people ? 'да' : 'нет'}}</b></span>
                    <span>
                      <mcw-button
                        class="all-patient-table-btn"
                        @click="addNewContact(item)">
                          <mcw-material-icon icon="person_add_alt" /> Добавить контакт
                      </mcw-button>
                      <mcw-button
                        class="all-patient-table-btn"
                        @click="tryDelPatient(item)">
                          <mcw-material-icon icon="delete" /> Удалить
                      </mcw-button>
                      <mcw-button
                        class="all-patient-table-btn"
                        @click="isAddressModalOpen = true">
                        <mcw-material-icon icon="place" /> Адрес
                      </mcw-button>
                      <mcw-button
                        class="all-patient-table-btn"
                        @click="showEditModal(item)">
                        <mcw-material-icon icon="edit" /> Редактировать
                      </mcw-button>
                      <mcw-button
                        class="all-patient-table-btn"
                        @click="openForeignDocumentsModal()">
                        <mcw-material-icon icon="description" /> Документы
                      </mcw-button>
                      <mcw-button
                        v-if='!foreingData'
                        class="all-patient-table-btn"
                        @click="openForeignDataModal()">
                        <mcw-material-icon icon="assignment" /> Иностранный гражданин
                      </mcw-button>
                    </span>
                  </section>
                    <mcw-list v-if='foreingData && countries' id='foreign-list'>
                      <mcw-list-item
                        v-for='item in foreingData'
                        :key='item.id_patient'
                      >
                        <span><b>Сведения иностранного гражданина:</b></span>
                        <span><b>{{ item.lastnamel }} {{ item.firstnamel }}</b>
                        | Место рождения: <b>{{ item.birth_place }}</b></span>
                        <span>Гражданство: <b>{{ countries.find(i => i.id == item.citizen).country }}</b>
                        | Прибыл из: <b>{{ countries.find(i => i.id == item.where_came_from).country }}</b>
                        | Цель: <b>{{ item.mission }}</b>
                        | В России до: <b>{{ item.date_stay }}</b></span>
                        <mcw-button
                          class="all-patient-table-btn"
                          @click="openForeignDataModal(item)">
                          <mcw-material-icon icon="assignment" /> Изменить сведения
                        </mcw-button>
                      </mcw-list-item>
                    </mcw-list>
                </section>
                <PatientDataTable
                  :patient="currentPatient"
                  :items="currentPatientInfo"
                  @selectPatient="getForeignDocuments"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </mdc-data-table>
    <mdc-dialog
      v-model="isDialogOpen"
      escape-key-action="close"
      scrim-click-action="close"
      :auto-stack-buttons="true">
      <component
        :is="componentName"
        v-bind="{
          title: dialogTitle,
          patients: checkedItems,
          moveId: moveId,
          tableType: type,
        }" @finishEditing="finishEditing">
      </component>
    </mdc-dialog>
    <mcw-dialog
      v-model="isAddressModalOpen"
      escape-key-action="close">
      <mcw-dialog-title>
        Адрес пациента
        <mcw-icon-button class="btn_close" @click="isAddressModalOpen = false">
          <mcw-material-icon icon="close"/>
        </mcw-icon-button>
      </mcw-dialog-title>
      <mcw-dialog-content>
        <PatientAddress
          v-if='!processing'
          :addresses='addresses'
          :patient='selectedPatient'/>
        <Spinner v-else/>
      </mcw-dialog-content>
    </mcw-dialog>
    <mcw-dialog
      v-model="isForeignDataModal"
      escape-key-action="close">
      <ForeignDataModal
        v-if="isForeignDataModal"
        :countries="countries"
        :item="selectedItem"
        :selectedPatient="selectedPatient"
        @close="isForeignDataModal = false"/>
    </mcw-dialog>
    <mcw-dialog
      v-model="isForeignDocumentsModal"
      escape-key-action="close">
      <ForeignDocumentsModal
        v-if="isForeignDocumentsModal"
        :docs="docs"
        :selectedPatient="selectedPatient"
        @close="isForeignDocumentsModal = false"/>
    </mcw-dialog>
    <mcw-dialog v-model='isEditModalOpen' escape-key-action="close">
      <EditPatientDialog v-if='editedPatient' :patient='editedPatient'/>
    </mcw-dialog>
  </div>
</div>
    <mcw-dialog
      v-model="isPeriodsOpened"
      escape-key-action="close">
      <SetPeriod
        @close="isPeriodsOpened = false"
        :initialRange="datesRange"
        @setRange="loadPatientsByDates"/>
    </mcw-dialog>
</template>

<script>
import { mapState } from 'vuex';
import SetPeriod from '../SetPeriod.vue';
import PatientDataTable from '../PatientDataTable.vue';
import MultiSelect from '../MultiSelect.vue';
import CommonTable from './CommonTable.vue';
import SearchSelect from '../SearchSelect/SearchSelect.vue';
import PatientAddress from '../PatientAddress.vue';
import EditPatientDialog from '../EditPatientDialog.vue';
import ForeignDataModal from '../ForeignDataModal.vue';
import ForeignDocumentsModal from '../ForeignDocumentsModal.vue';

export default {
  name: 'AllPatientsTable',
  extends: CommonTable,
  props: {
    items: Array,
    tableHeight: Number,
  },
  emits: [
    'hideAllPatients',
  ],
  components: {
    PatientDataTable,
    MultiSelect,
    SetPeriod,
    SearchSelect,
    PatientAddress,
    EditPatientDialog,
    ForeignDataModal,
    ForeignDocumentsModal,
  },
  data() {
    return {
      title: 'Последние визиты',
      type: 'allPatients',
      checkedItems: [],
      select: [],
      sortIndex: null,
      sortOrder: true,
      dialogTitle: '',
      moveId: null,
      currentPatient: {},
      currentPatientInfo: [],
      isPatientInfoOpen: false,
      processing: false,
      isEditModalOpen: false,
      docs: null,
      searchString: '',
      visibleItems: [],
      step: 1,
      selectedStatuses: [],
      isPeriodsOpened: false,
      statuses: [],
      MSKey: 1,
      selectedPatient: null,
      editedPatient: null,
      isLoading: false,
      isForeignDataModal: false,
      isBirthPlaceOpened: false,
      isForeignDocumentsModal: false,
      foreingData: null,
      isAddressModalOpen: false,
      addresses: null,
      selectedItem: null,
    };
  },
  mounted() {
    this.setStatuses();
    this.emitter.on('attachPatient', this.attachPatient);
    this.emitter.on('staffFromEmployees', this.staffFromEmployees);
    this.emitter.on('getForeignDocuments', this.getForeignDocuments);
    this.emitter.on('getAddresses', this.getAddresses);
    this.emitter.on('stopEditPatient', this.stopEditPatient);
  },
  watch: {
    items() {
      this.MSKey += 1;
      this.setStatuses();
    },
    isEditModalOpen(bool) {
      if (!bool) this.editedPatient = null;
    },
  },
  computed: {
    showOnePatientResults() {
      return this.$store.getters['employeesData/showOnePatientResults'];
    },
    patsCountSum() {
      return this.items.reduce((acc, item) => {
        acc += item.pat_count;
        return acc;
      }, 0);
    },
    datesRange: {
      get() {
        return this.$store.state.employeesData.patientsDatesRange;
      },
      set(range) {
        this.$store.commit('employeesData/SET_DATES_RANGE', range);
      },
    },
    getActsSum() {
      let result = '';
      const arr = this.items.map((item) => item.sum_pat);
      if (arr && arr.length) {
        result = arr.reduce((acc, item) => acc + item);
      }
      return result;
    },
    _title() {
      switch (true) {
        case !this.actLoaded && !this.currentContract: return 'Последние визиты';
        case !!this.actLoaded && this.actLoaded.actId === -1: return 'Пациенты, не попавшие в акт';
        case this.currentContract && !this.actLoaded: return 'Пациенты по контракту';
        default: return 'Пациенты';
      }
    },
    ...mapState({
      currentContract: (state) => state.contractsData.currentContract,
      contracts: (state) => state.contractsData.contracts,
      actLoaded: (state) => state.employeesData.actLoaded,
      countries: (state) => state.employeesData.countries,
    }),
  },
  methods: {
    async searchOnePatient() {
      if (!this.searchString.length) {
        return;
      }
      await this.$store.dispatch('employeesData/getOnePatient', this.searchString);
    },
    async exportListAttended() {
      const params = {
        id_org: this.$store.getters['user/getSelectedCompanyId'],
        date_from: this.datesRange.start,
        date_till: this.datesRange.end,
      };
      const result = await this.$store.dispatch('employeesData/loadListAttended', params);
      if (result) {
        this.emitter.emit('exportToExcel', { items: result, title: 'Статистика прохождения' });
      }
    },
    async loadPatientsByDates(dates) {
      if (this.datesRange.start === dates.start && this.datesRange.end === dates.end) {
        return false;
      }
      this.datesRange = { ...dates };
      const params = {
        includeServices: false,
        dateStart: this.datesRange.start,
        dateEnd: this.datesRange.end,
      };
      this.emitter.emit('globalLoading', true);
      await this.$store.dispatch('employeesData/getLastPatients', params);
      this.emitter.emit('globalLoading', false);
    },
    setStatuses() {
      const result = [];
      this.items.forEach((item) => {
        if (!result.includes(item.status)) {
          result.push(item.status);
        }
      });
      this.statuses = result;
    },
    closeTable() {
      this.$emit('hideAllPatients');
    },
    async attachPatient(data) {
      const params = {
        id_tree: data.node,
        id_self_record: 0,
        id_people: data.patient.item.id_people,
      };
      const attach = await this.$store.dispatch('employeesData/approveSelfRecord', { params, source: 0 });
      if (attach) {
        this.$store.commit('treeData/SET_AMOUNT', { id: data.node, amount: 1 });
        if (attach.id_tree_people) {
          this.$store.commit('employeesData/SET_STAFF_STATUS', { id: data.patient.item.id_people, newId: attach.id_tree_people });
        }
      }
    },
    async staffFromEmployees(data) {
      const params = {
        id_tree: data.node,
        id_employee: 0,
        id_patient: data.patient.item.id_patient,
      };
      const attach = await this.$store.dispatch('employeesData/attachEmployee', params);
      if (attach) {
        if (attach.body[0].error) {
          this.emitter.emit('message', attach.body[0].error);
          return;
        }
        this.$store.commit('treeData/SET_AMOUNT', { id: data.node, amount: 1 });
        if (attach.id_tree_people) {
          this.$store.commit('employeesData/SET_STAFF_STATUS', { id: data.patient.item.id_people, newId: attach.id_tree_people });
        }
      }
    },
    async getDirection(data) {
      this.processing = true;
      const direction = await this.$store.dispatch('employeesData/getNapravl', data.id_patient);
      this.processing = false;
      window.open(`https://host2.medsafe.tech/${direction[0].hash}`, '_blank');
    },
    async tryDelPatient(item) {
      const deleted = await this.$store.dispatch('treeData/delPatient', item.id_patient);
      if (!deleted) {
        this.emitter.emit('message', 'Время истекло, удаление невозможно');
      }
    },
    async selectPatient(id) {
      if (this.selectedPatient === id) {
        this.selectedPatient = null;
        this.currentPatient = {};
        return;
      }
      this.isLoading = true;
      this.selectedPatient = id;
      const patient = this.items.find((item) => item.id_patient === this.selectedPatient);
      await this.showPatientInfo(patient);
      const data = await this.$store.dispatch('employeesData/getForeingData', this.selectedPatient);
      if (data) {
        this.foreingData = data;
      } else this.foreingData = null;
      await this.getForeignDocuments(this.selectedPatient);
      const addresses = await this.$store.dispatch('employeesData/getPatientAddress', this.selectedPatient);
      this.addresses = JSON.parse(addresses.patient_adress);
      this.isLoading = false;
    },
    async getAddresses() {
      this.processing = true;
      const addresses = await this.$store.dispatch('employeesData/getPatientAddress', this.selectedPatient);
      if (addresses) this.addresses = JSON.parse(addresses.patient_adress);
      this.processing = false;
    },
    async getForeignDocuments(idPatient) {
      this.docs = null;
      let foreignDocs = await this.$store.dispatch('employeesData/getForeignPatientDocs', idPatient);
      foreignDocs = JSON.parse(foreignDocs.patient_docs);
      foreignDocs.forEach((i) => {
        i.id_document = JSON.parse(i.id_document);
      });
      this.docs = foreignDocs;
    },
    async sendForeignData(patient) {
      await this.$store.dispatch('employeesData/postForeingData', { id_patient: patient.id_patient });
    },
    openForeignDataModal(item = null) {
      if (item) {
        this.selectedItem = item;
      }
      this.isForeignDataModal = true;
    },
    showEditModal(item) {
      this.editedPatient = item;
      this.isEditModalOpen = true;
    },
    stopEditPatient() {
      this.isEditModalOpen = false;
    },
    setBirthPlace(val) {
      this.selectedBirthPlace = val;
    },
    openForeignDocumentsModal() {
      this.isForeignDocumentsModal = true;
    },
  },
};
</script>

<style>
  #foreign-list .mdc-list-item__text {
    flex-direction: column;
    align-items: start;
  }
  .select-patient-mission {
    width: 100%;
    margin-top: 20px;
  }
  .row-details {
    display: flex;
    flex-direction: column;
  }
  #foreign-list .mdc-list-item {
    padding: 0;
  }
  .visits .mdc-button__label {
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 0.5rem;
    padding-left: 4px;
    padding-right: 4px;
    background-color: rgba(0, 0, 0, 0.54);
  }
  .visits i {
    color: #fff;
  }
  .badge {
    display: inline-flex;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 0.5rem;
    padding: 2px 4px;
    background-color: rgba(0, 0, 0, 0.54);
    color: #fff;
  }
  .badges-row:not:last-child {
    margin-right: 5px;
  }
  .document-action-icons {
    display: flex;
  }
  .document-action-icons i {
    padding: 0 15px;
  }

</style>

<style scoped>
  .contacts-qty{
    color: #fff;
  }
  .clue {
    color: gray;
    margin-top: 0;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }
  .selected-contract {
    padding-left: 10px;
    padding-right: 10px;
  }
  .table__title {
    margin-bottom: 0.25rem;
    width: calc(100% - 70px);
  }
  .table__checkbox-wrapper{
    float: right;
  }
  .table-summary{
    display: inline-block;
  }
</style>
