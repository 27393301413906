import { NAME_APP } from '../constants';
import { getCookie, deleteCookie } from '../helpers/cookies';
import Api from '../helpers/Api';

export const auth = {
  namespaced: true,
  state: {
    user: {},
    IMEI: '',
    hash: '',
    companiesList: [],
    selectedCompany: null,
    isAuth: false,
    constantParams: null,
    newCompany: null,
  },
  actions: {
    getImei({ commit }) {
      const imei = getCookie('IMEI');
      return commit('SET_IMEI', imei);
    },
    async getUserData({ state, dispatch, commit }, data) {
      if (state.IMEI === '') {
        await dispatch('getImei');
      }
      const params = {
        id_login: data.login,
        TK: data.token,
        Name_event: 'GET:user_data',
        IMEI: state.IMEI,
        Name_app: NAME_APP,
      };
      const userData = await Api.get(params);
      if (userData) {
        if (userData.errorType === 0) {
          commit('SET_AUTH', true);
          const user = userData.body[0];
          if (user.role_list) {
            const permissionScheme = JSON.parse(user.role_list);
            user.rang = permissionScheme[0].rang;
            // user.rang = 100;
            // user.rang = 10;
            user.role = permissionScheme[0].role;
          } else {
            user.rang = 10;
            user.role = 27;
          }
          user.id_login = data.login;
          user.TK = data.token;
          commit('SET_USER', user);
          commit('SET_CONSTANT_PARAMS', params);
          // const eventsParams = { ...params };
          // eventsParams.Name_event = 'GET:my_events';
          // const availableEvents = await Api.get(eventsParams);
          return user;
        }
        if (userData.errorType === 1) {
          deleteCookie('id_login');
          deleteCookie('TK');
          window.location.reload();
        }
      }
      return false;
    },
    async getCompaniesList({ state, commit }) {
      const data = { ...state.constantParams };
      data.Name_event = 'GET:orgs_list_full';
      const result = await Api.get(data);
      if (result.errorType === 0 && result.body) {
        commit('SET_COMPANIES_LIST', result.body);
        return result.body;
      }
      return false;
    },
    async getAdditionalCompanies({ state }, searchString) {
      const params = {
        TK: state.user.TK,
        id_login: state.user.id_login,
        IMEI: state.IMEI,
        Name_event: 'GET:orgs_list_add',
        Name_app: NAME_APP,
        text: searchString,
      };
      const result = await Api.get(params);
      return result.body;
    },
    async setSelectedCompany({ commit, state, dispatch }, company) {
      const login = state.user.id_login;
      commit('SET_SELECTED_COMPANY', company);
      commit('employeesData/SET_ACT_TO_LOADED', null, { root: true });
      const promises = [
        dispatch('employeesData/getMissionList', null, { root: true }),
        dispatch('employeesData/getCountriesList', null, { root: true }),
        dispatch('contractsData/getContractsList', null, { root: true }),
        dispatch('contractsData/getPaymentList', null, { root: true }),
        dispatch('loginData/getLoginGroups', null, { root: true }),
        dispatch('treeData/loadTree', null, { root: true }),
        dispatch('treeData/getMOTypes', null, { root: true }),
        dispatch('contractsData/getResponsiblings', null, { root: true }),
      ];
      await Promise.all(promises);
      localStorage.setItem(`medSafeCompany-${login}`, company.id_org);
    },
    async addOrgIntoOrgsList({ dispatch, rootState }, idOrg) {
      const data = { ...rootState.user.constantParams };
      data.Name_module = 'WEB_managers';
      data.Name_event = 'POST:add_org_into_orgs_list';
      data.id_org = idOrg;
      const result = await Api.post(data, true);
      if (result) {
        await dispatch('getCompaniesList');
        return true;
      }
      return false;
    },
    logout({ commit }) {
      deleteCookie('id_login');
      deleteCookie('TK');
      commit('LOGOUT');
      commit('SET_AUTH', false);
      return true;
    },
  },
  mutations: {
    SET_IMEI(state, imei) {
      state.IMEI = imei;
    },
    SET_USER(state, userData) {
      state.user = userData;
    },
    SET_COMPANIES_LIST(state, companiesList) {
      state.companiesList = companiesList;
      if (state.companiesList.length === 1) {
        state.selectedCompany = { ...state.companiesList[0] };
      }
      if (state.newCompany) {
        state.companiesList.find((i) => {
          if (i.id_org === state.newCompany.id_org) i.temp = true;
          return null;
        });
        state.newCompany = null;
      }
    },
    SET_NEW_COMPANY(state, payload) {
      state.newCompany = payload;
    },
    SET_SELECTED_COMPANY(state, company) {
      state.selectedCompany = company;
    },
    SET_ACCESS_LIST(state, list) {
      state.accessList = list;
    },
    LOGOUT(state) {
      state.user = {};
    },
    SET_AUTH(state, value) {
      state.isAuth = value;
    },
    SET_CONSTANT_PARAMS(state, params) {
      state.constantParams = {
        id_login: params.id_login,
        TK: params.TK,
        IMEI: params.IMEI,
        Name_app: params.Name_app,
      };
    },
  },
  getters: {
    isAuth(state) {
      return state.isAuth;
    },
    userAuthData(state) {
      return {
        idLogin: state.user.id_login,
        TK: state.user.TK,
        IMEI: state.IMEI,
        nameApp: NAME_APP,
      };
    },
    userId(state) {
      return state.user.id_people;
    },
    companiesList(state) {
      return state.companiesList;
    },
    getSelectedCompanyName(state) {
      if (state.selectedCompany) {
        return state.selectedCompany?.org || '';
      }
      return '';
    },
    getSelectedCompanyId(state) {
      return state.selectedCompany?.id_org;
    },
    getUserRank(state) {
      return state.user.rang;
      // const currentRank = state.user.rang;
      // if (currentRank > 100) {
      //   return 'admin';
      // }
      // if (currentRank > 10) {
      //   return 'manager';
      // }
      // return 'user';
    },
  },
};
