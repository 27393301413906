<template>
  <tr class="mdc-data-table__row doc-list__item">
    <td class="table__cell">
      <span class="doc-list__cell">
        {{ document.doc_name }}
      </span>
      <span v-if="!document.isExist">
        (в процессе)
      </span>
    </td>
    <td class="table__cell">
      <span class="doc-list__cell">
        {{ document.date_document }}
      </span>
    </td>
    <td>
      <mcw-icon-button @click.stop="regenDoc(document.id_document)">
        <mcw-material-icon icon="refresh" class="icon_base" />
      </mcw-icon-button>
    </td>
    <td class="table__cell">
      <mcw-icon-button
        class="doc-list__button"
        :disabled="!document.isExist"
        v-tooltip="{ content: 'Скачать', pos: 'left' }"
        @click.stop="getFile(document, 'download')"
      >
        <mcw-material-icon icon="file_download" class="icon_base" />
      </mcw-icon-button>
    </td>
    <td class="table__cell">
      <mcw-icon-button
        class="doc-list__button"
        :disabled="!document.isExist"
        v-tooltip="{ content: 'Посмотреть', pos: 'left' }"
        @click.stop="getFile(document, 'open')"
      >
        <mcw-material-icon icon="open_in_browser" class="icon_base" />
      </mcw-icon-button>
    </td>
    <td class="table__cell">
      <mcw-button
        class="doc-list__button"
        :disabled="!document.isExist"
        v-tooltip="{ content: 'История', pos: 'left' }"
        @click.stop="$emit('showSendDoc', document)"
      >
        <mcw-material-icon icon="send" class="icon_base"></mcw-material-icon>
      </mcw-button>
    </td>
    <td class="table__cell">
      <mcw-menu-anchor class="myAnchor">
        <mcw-button
          class="doc-list__button"
          :disabled="!document.isExist"
          v-tooltip="{ content: 'Подписать ЭП', pos: 'left' }"
          @click.stop="getSigns(document)"
        >
          <mcw-material-icon
            :icon="loadingSigns ? 'hourglass_empty' : 'draw'"
            class="icon_base"
          />
        </mcw-button>
        <mcw-menu v-model="signsMenuOpen">
          <mcw-list-item
            v-for="sign in signs"
            :key="sign.id"
            @click.stop=""
          >
            <mcw-checkbox v-model="selectedSigns" :value="sign.id" />
            {{ sign.text }}
          </mcw-list-item>
          <mcw-button @click.stop="signDoc">
            <mcw-material-icon icon="draw" class="icon_base" />
            Подписать
          </mcw-button>
        </mcw-menu>
      </mcw-menu-anchor>
    </td>
    <td class="table__cell">
      <mcw-button
        class="doc-list__button"
        :disabled="!document.isExist"
        v-tooltip="{ content: 'Удалить', pos: 'left' }"
        @click.stop="removeDoc(document)"
      >
        <mcw-material-icon icon="delete_forever" class="icon_base"></mcw-material-icon>
      </mcw-button>
    </td>
  </tr>
</template>

<script>
import { BASE_URL } from '../helpers/Api';

export default {
  name: 'PatientDocListItem',
  props: {
    document: Object,
  },
  emits: ['showSendDoc', 'docSigned'],
  data() {
    return {
      isOpen: false,
      signs: [],
      signsMenuOpen: false,
      loadingSigns: false,
      selectedSigns: [],
    };
  },
  methods: {
    async getSigns(doc) {
      this.loadingSigns = true;
      const signs = await this.$store.dispatch('employeesData/getESigns', doc);
      this.loadingSigns = false;
      if (signs[0].id === -1) {
        this.emitter.emit('message', signs[0].text);
        return;
      }
      this.signs = signs;
      this.signsMenuOpen = true;
    },
    async signDoc() {
      const isSigned = await this.$store.dispatch('employeesData/signDocument', {
        id_document: this.document.id_document,
        str: this.selectedSigns.toString(),
      });
      if (!isSigned) {
        this.emitter.emit('message', 'ошибка');
        return;
      }
      this.emitter.emit('message', isSigned.err_txt);
      this.$emit('docSigned');
    },
    async removeDoc(doc) {
      const remove = await this.$store.dispatch('contractsData/removeDocument', { id: doc.id_document });
      if (remove) {
        this.emitter.emit('message', 'Файл удален успешно!');
      } else {
        this.emitter.emit('message', 'Вы не можете удалить этот файл');
      }
    },
    async getFilePath(id) {
      const path = await this.$store.dispatch('getDocPath', id);
      if (path) {
        return path;
      }
      return null;
    },
    async getFile(doc, type) {
      const path = await this.getFilePath(doc.id_document);
      const fileName = doc.doc_name;
      const file = await this.$store.dispatch('getFile', path.hash);
      if (file) {
        const link = document.createElement('a');
        if (type === 'download') {
          const url = window.URL.createObjectURL(new Blob([file]));
          link.href = url;
          link.setAttribute('download', `${fileName}.${path.extension}`);
        } else {
          link.href = BASE_URL + path.hash;
          link.target = 'blank';
        }
        document.body.append(link);
        link.click();
        link.remove();
      } else {
        this.fileNotFound();
      }
    },
    async regenDoc(id) {
      const params = {
        str_params: JSON.stringify({
          id_doc: id,
          gen_status_comment: 1,
          path_now: 1,
        }),
      };
      const result = await this.$store.dispatch('employeesData/regenDoc', params);
      if (result) {
        this.emitter.emit('message', 'Запрос на перегенерацию отправлен');
      }
    },
  },
};
</script>
