<template>
<div class="managers">
    <SearchSelect
      v-if="possibleAgents"
      :options="possibleAgents"
      label="fio_agent"
      :initial="selectedAgent"
      titleWidth="366px"
      :placeholder="isLoading ? 'Загрузка...' : 'Поиск'"
      :showCounter='true'
      @selectItem="selectAgent"/>
    <mcw-button
      class="agents__btn"
      :disabled="!isSelected || !contractId"
      @click="addAgent">
      <mcw-material-icon icon="person_add" :class="isSelected && contractId ? 'icon_base' : 'icon_grey'"/>
      Добавить агента к договору
    </mcw-button>
     <mdc-data-table
      v-if="items.length">
      <div class="table__header">
      </div>
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell">
              ID
            </th>
            <th class="mdc-data-table__header-cell">
              ФИО
            </th>
            <th class="mdc-data-table__header-cell">
              E-mail
            </th>
          </tr>
        </thead>
        <tbody
          class="mdc-data-table__content">
            <tr
              v-for="item in items"
              :key="item.id"
              class="mdc-data-table__row">
              <td class="mdc-data-table__cell">
                {{item.id_agent}}
              </td>
              <td class="mdc-data-table__cell">
                {{item.fio_agent}}
              </td>
              <td class="mdc-data-table__cell">
                <a href="mailto:item.email">{{item.email}}</a>
              </td>
            </tr>
        </tbody>
      </table>
    </mdc-data-table>
  </div>
</template>

<script>
import SearchSelect from '../SearchSelect/SearchSelect.vue';

export default {
  name: 'Managers',
  props: {
    items: Array,
    contractId: Number,
    loading: Boolean,
  },
  emits: [
    'refresh',
  ],
  components: {
    SearchSelect,
  },
  data() {
    return {
      isLoading: false,
      selectDefault: {},
      selectedAgent: {},
      isSelected: false,
    };
  },
  computed: {
    possibleAgents() {
      return this.$store.getters['contractsData/getPossibleAgents'];
    },
    contractName() {
      const contract = this.$store.getters['contractsData/getContractById'](this.contractId);
      if (contract) {
        return contract.name_contract;
      }
      return '';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (!this.possibleAgents.length) {
      await this.$store.dispatch('contractsData/getPossibleAgents', this.contractId);
    }
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },
  methods: {
    selectAgent(agent) {
      this.selectedAgent = agent;
      this.isSelected = true;
    },
    async addAgent() {
      const params = {
        id_contract: this.contractId,
        id_agent: this.selectedAgent.id_people,
      };
      const newAgent = await this.$store.dispatch('contractsData/addAgent', params);
      if (typeof newAgent === 'boolean' && newAgent) {
        this.emitter.emit('message', `${this.selectedAgent.fio_agent} успешно добавлен!`);
        await this.$store.dispatch('contractsData/getAgents', this.contractId);
        this.$emit('refresh');
        this.selectedAgent = {};
        this.isSelected = false;
      } else {
        this.emitter.emit('message', `Что-то пошло не так: ${newAgent.err_text}`);
      }
    },
  },
};
</script>

<style lang="scss">
.agents{
  &__btn{
    margin: 20px 0;
  }
  & .mdc-dialog__content{
    overflow: visible!important;
  }
}
</style>
