<template>
  <div class="acts__summary" v-if='!actsProcessing'>
    <div class="acts__summary-item">
      Итого стоимость: {{showRank(sum.actsCommon)}}р.
    </div>
    <div class="acts__summary-item">
      Итого оплачено: {{showRank(sum.payCommon)}}р.
    </div>
    <div class="acts__summary-item">
      Баланс:
      <span
        class="acts__balance"
        :class="balance < 0 ? 'acts__balance_red' : 'acts__balance_green'">
        {{ showRank(balance)}}р.
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { utils } from '../../helpers/utils';

export default {
  name: 'ActsSummary',
  mixins: [
    utils,
  ],
  props: {
    sum: Object,
  },
  computed: {
    balance() {
      return this.sum.payCommon - this.sum.actsCommon;
    },
    ...mapState({
      actsProcessing: (state) => state.contractsData.actsProcessing,
    }),
  },
  beforeUnmount() {
    this.$store.commit('contractsData/SET_ACTS_SUMMARY');
  },
};
</script>

<style lang="scss">
.acts{
  &__summary{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  &__balance{
    font-weight: bold;
    &_red{
      color: red;
    }
    &_green{
      color: green;
    }
  }
}
</style>
