<template>
  <div class="orgs-view view">
    <OrgsTable :items="fullOrgsList" v-if="fullOrgsList.length"/>
    <Spinner v-show="isLoading"/>
  </div>
</template>

<script>
import OrgsTable from '../components/DataTables/OrgsTable.vue';
import Spinner from '../components/Spinner.vue';

export default {
  name: 'OrgsView',
  components: {
    OrgsTable,
    Spinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  // async created() {
  //   if (!this.fullOrgsList.length) {
  //     this.isLoading = true;
  //     await this.$store.dispatch('user/getCompaniesList');
  //     this.isLoading = false;
  //   }
  // },
  computed: {
    fullOrgsList() {
      return this.$store.getters['user/companiesList'];
    },
  },
  methods: {
    loader(val) {
      this.isLoading = val;
    },
  },
};
</script>

<style>
.orgs-view {
  grid-template-rows: auto 1fr auto;
}
</style>
