<template>
  <div class="contract-docs">
    <div class="contracts__actions__col">
      <mcw-button
        class="contracts__btn"
        @click="$emit('openEditor', id)">
        <mcw-material-icon icon="edit" class="icon_base">
        </mcw-material-icon>
        Изменить договор
      </mcw-button>
      <mcw-button
        @click="openTreeByContractModal"
        class="contracts__btn">
        <mcw-material-icon icon="account_tree" class="icon_base">
        </mcw-material-icon>
        Подразделения заказчика
      </mcw-button>
      <mcw-button
        @click="openBranchListModal"
        class="contracts__btn"
        :disabled="userRank < 100"
      >
        <mcw-material-icon icon="rule" :class="userRank < 100 ? 'icon_grey' : 'icon_base'">
        </mcw-material-icon>
        Филиалы исполнителя
      </mcw-button>
      <span class="contract__list-title">
      <mcw-button
        @click="expandSubdoers()"
        class="contracts__btn">
        <mcw-material-icon icon="group_work" class="icon_base">
        </mcw-material-icon>
        Соисполнители
      </mcw-button>
        <mcw-button
          unelevated
          v-if="showBindButton"
          @click="bindSubdoers">
          {{bindingType === 0 ? 'связать' : 'отвязать'}}
        </mcw-button>
      </span>
    </div>
    <div class="contracts__actions__col">
      <mcw-button
        class="contracts__btn"
        @click="openInterviewLinkModal">
        <mcw-material-icon icon="receipt_long" class="icon_base">
        </mcw-material-icon>
        QR для анкеты
      </mcw-button>
      <mcw-menu-anchor>
        <mcw-button
          :disabled="userRank < 100"
          class="contracts__btn"
          @click="isAddOpen = true">
          <mcw-material-icon icon="note_add" :class="userRank >= 100 ? 'icon_base' : ''">
          </mcw-material-icon>
          Создать документ
        </mcw-button>
        <mcw-menu v-model="isAddOpen">
          <DocTypesList
            ref='DocTypesList'
            v-for="docType in docTypesForGenerate"
            :key="docType.doc_type"
            :docType="docType"
            :id="id"
            @expand="expand"
            @confirmCreate="confirmCreate"
            :expanded="expanded === docType.doc_type"/>
        </mcw-menu>
      </mcw-menu-anchor>
      <mcw-menu-anchor>
        <mcw-button
          class="contracts__btn"
          @click="isAttached ? uploadFile() : isUploadOpen = true">
          <mcw-material-icon icon="upload" class="icon_base">
          </mcw-material-icon>
          {{isAttached ? 'отправить' : 'Прикрепить документ' }}
        </mcw-button>
        <mcw-menu v-model="isUploadOpen">
          <mcw-list-item
            v-for="docType in docTypesForAttach"
            :key="docType.doc_type"
            @click="openFileUpload(docType)">
            {{docType.doc_name}}
          </mcw-list-item>
        </mcw-menu>
      </mcw-menu-anchor>
      <input
        type="file"
        ref="file"
        class="file-upload__input file-upload__input_sizeless"
        v-on:change="handleFileUpload()"/>
      <mcw-button
        @click="loadPatientsByContract"
        class="contracts__btn">
        <mcw-material-icon icon="people" class="icon_base">
        </mcw-material-icon>
        Пациенты
      </mcw-button>
    </div>

    <table
        class="mdc-data-table__table subdoers-table"
        v-if="subDoers.length && showSubdoers">
        <tbody>
          <tr
            :class="['mdc-data-table__row', {highlighted: subdoer.id_contract_sub_doer === highlightedSubdoer}]"
            v-for="(subdoer, i) in subDoers"
            :key="i">
            <td class="mdc-data-table__cell">
              <mcw-checkbox
                :value="subdoer.id_contract_sub_doer"
                v-model="selectedSubdoers"
                @change="selectSubdoer(subdoer.id_contract_sub_doer)">
              </mcw-checkbox>
            </td>
            <td class="mdc-data-table__cell">{{subdoer.name_org_doer}}</td>
            <ExpandableCell :content="subdoer.name_contract" maxWidth="250"/>
            <td class="mdc-data-table__cell subdoers-table__qty-cell">{{subdoer.use_cnt}}</td>
            <td class="mdc-data-table__cell subdoers-table__qty-cell">{{subdoer.services_cnt}}</td>
          </tr>
        </tbody>
      </table>
  </div>
          <mcw-dialog v-model="isBranchesOpen" class="big-modal">
          <AvailableBranches
            v-if="isBranchesOpen"
            :id="id"
            @close="isBranchesOpen = false"/>
        </mcw-dialog>
        <mcw-dialog v-model="isTreeByContractOpen" class="">
          <TreeByContract
            v-if="isTreeByContractOpen"
            :id="id"
            @close="isTreeByContractOpen = false"/>
        </mcw-dialog>
      <mcw-dialog
        v-model="isInterviewLinkOpen">
        <InterviewLink
          v-if="isInterviewLinkOpen"
          :contractId="id"
          @close="isInterviewLinkOpen = false"/>
      </mcw-dialog>
      <mcw-dialog
        class="confirm-modal"
        v-model="showConfirmBox"
        role='alertdialog'
        v-if="fileMode"
        @mdc-dialog-closed="fileMode = null"
      >
        <mcw-dialog-title>
          {{
          fileMode === 'create' ?
          `Создать ${templateName}?` :
          `Загрузить ${templateName}`
          }}
        </mcw-dialog-title>
        <mcw-dialog-content>
          <mcw-textfield label="Название файла" v-model="fileAltName"></mcw-textfield>
        </mcw-dialog-content>
        <mcw-dialog-footer>
          <mcw-dialog-button action="dismiss">Отмена</mcw-dialog-button>
          <mcw-dialog-button
            v-if="fileMode === 'create'"
            action="accept"
            isDefault
            :disabled="!fileAltName"
            @click='createDoc'>
            Создать
          </mcw-dialog-button>
          <mcw-dialog-button
            v-if="fileMode === 'upload'"
            action="accept"
            isDefault
            :disabled="!fileAltName"
            @click='uploadFile'>
            Загрузить
          </mcw-dialog-button>
        </mcw-dialog-footer>
      </mcw-dialog>
  <Spinner v-if="isLoading" />
</template>

<script>
import AvailableBranches from './AvailableBranches.vue';
import InterviewLink from './InterviewLink.vue';
import DocTypesList from './DocTypesList.vue';
import TreeByContract from './TreeByContract.vue';
import ExpandableCell from './ExpandableCell.vue';
import Spinner from './Spinner.vue';

export default {
  name: 'ContractDocs',
  emits: [
    'openEditor',
  ],
  props: {
    id: Number,
  },
  components: {
    InterviewLink,
    AvailableBranches,
    DocTypesList,
    TreeByContract,
    ExpandableCell,
    Spinner,
  },
  data() {
    return {
      isAddOpen: false,
      isUploadOpen: false,
      file: null,
      isAttached: false,
      isContactsOpen: false,
      typeId: null,
      isAgentsOpen: false,
      isInterviewLinkOpen: false,
      isBranchesOpen: false,
      isTreeByContractOpen: false,
      expanded: null,
      showConfirmBox: false,
      template: null,
      fileAltName: '',
      fileMode: null,
      templateName: '',
      showSubdoers: false,
      isLoading: false,
      selectedSubdoers: [],
      highlightedSubdoer: null,
    };
  },
  watch: {
    isTreeByContractOpen(bool) {
      if (!bool) {
        this.$store.commit('treeData/SET_TREE_BY_CONTRACT', []);
        this.$store.dispatch('contractsData/getContractsList');
      }
    },
  },
  computed: {
    docTypesForGenerate() {
      return this.$store.getters['contractsData/getDocTypes']
        .filter((item) => item.upload_flag === 1 || item.upload_flag === 2);
    },
    docTypesForAttach() {
      return this.$store.getters['contractsData/getDocTypes']
        .filter((item) => item.upload_flag === 0 || item.upload_flag === 2);
    },
    userRank() {
      return this.$store.getters['user/getUserRank'];
    },
    subDoers() {
      return this.$store.state.contractsData.subDoers;
    },
    selectedSubdoer() {
      return this.$store.state.contractsData.subdoersBinding.subdoer;
    },
    bindingType() {
      return this.$store.getters['contractsData/getSubdoersBinding'];
    },
    showBindButton() {
      return this.$store.getters['contractsData/getPossibleToBind'];
    },
  },
  mounted() {
    this.emitter.on('highlightSubdoer', this.highlightSubdoer);
    this.emitter.on('selectSubdoer', this.selectSubdoer);
  },
  beforeUnmount() {
    this.$store.commit('contractsData/SET_SUBDOERS', []);
  },
  methods: {
    expand(docType) {
      if (this.expanded === docType) {
        this.expanded = null;
      } else {
        this.expanded = docType;
      }
    },
    openFileUpload(docType) {
      this.typeId = docType.doc_type;
      this.fileAltName = docType.doc_name;
      this.templateName = docType.doc_name;
      this.$refs.file.click();
    },
    async openInterviewLinkModal() {
      await this.$store.dispatch('testData/getContractVaccines', this.id);
      this.isInterviewLinkOpen = true;
    },
    async openBranchListModal() {
      await this.$store.dispatch('testData/getContractBranches', this.id);
      this.isBranchesOpen = true;
    },
    confirmCreate(template) {
      this.fileMode = 'create';
      this.template = template;
      this.fileAltName = template.name_template || '';
      this.showConfirmBox = true;
    },
    createDoc() {
      if (!this.fileAltName) {
        return false;
      }
      this.$refs.DocTypesList.addDoc({ templateId: this.template.id_template, fileName: this.fileAltName });
    },
    handleFileUpload() {
      this.fileMode = 'upload';
      this.showConfirmBox = true;
      [this.file] = this.$refs.file.files;
      this.fileAltName = this.file.name;
      this.isAttached = true;
    },
    async uploadFile() {
      [this.file] = this.$refs.file.files;
      const upload = await this.$store.dispatch('contractsData/uploadFile', { file: this.file, contractId: this.id, typeId: this.typeId });
      this.isAttached = false;
      this.typeId = null;
      if (upload) {
        this.emitter.emit('message', 'Файл успешно загружен');
        this.$store.dispatch('contractsData/getDocuments', this.id);
      } else {
        this.emitter.emit('message', 'Не удалось загрузить файл');
      }
    },
    async loadPatientsByContract() {
      this.emitter.emit('globalLoading', true);
      await this.$store.dispatch('employeesData/getLastPatients', { includeServices: false });
      this.emitter.emit('globalLoading', false);
      this.$router.push({ name: 'Structure', params: { acts: true, fromContract: true } });
    },

    async openTreeByContractModal() {
      await this.$store.dispatch('treeData/getTreeByContract');
      this.isTreeByContractOpen = true;
    },
    async expandSubdoers() {
      if (this.showSubdoers) {
        this.showSubdoers = !this.showSubdoers;
        return;
      }
      if (!this.subDoers.length) {
        this.isLoading = true;
        await this.$store.dispatch('contractsData/getSubDoers', this.id);
        this.isLoading = false;
      }
      this.showSubdoers = !this.showSubdoers;
    },
    async selectSubdoer(idContractSubdoer) {
      if (!this.showSubdoers) {
        await this.expandSubdoers();
      }
      this.emitter.emit('changeSubdoer');
      this.selectedSubdoers = [];
      const params = {
        id_contract: this.id,
      };
      if (this.selectedSubdoer === idContractSubdoer) {
        this.$store.commit('contractsData/BIND_SUBDOER', null);
      } else {
        params.id_contract_sub_doer = idContractSubdoer;
        this.selectedSubdoers.push(idContractSubdoer);
        this.$store.commit('contractsData/BIND_SUBDOER', idContractSubdoer);
      }
      this.emitter.emit('loadServices', true);
      // await this.$store.dispatch('contractsData/getServices', params);
      // this.emitter.emit('loadServices', false);
    },
    async bindSubdoers() {
      const result = await this.$store.dispatch('contractsData/bindSubdoers', this.id);
      if (result) {
        const successMessage = this.bindingType === 0 ? 'Соисполнитель привязан' : 'Соисполнитель отвязан';
        this.emitter.emit('message', successMessage);
      } else {
        this.emitter.emit('message', 'Не удалось добавить соисполнителя');
      }
      this.$store.commit('contractsData/BIND_SUBDOER', null);
      this.selectedSubdoers = [];
      this.$store.commit('contractsData/BIND_SERVICES', []);
    },
    highlightSubdoer(idContractSubdoer) {
      if (this.highlightedSubdoer === idContractSubdoer) {
        this.highlightedSubdoer = null;
        return false;
      }
      this.highlightedSubdoer = idContractSubdoer;
    },
  },
};
</script>

<style lang="scss">
  .contracts__actions__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }
  .contract-docs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    &__actions {
      display: flex;
      justify-content: space-around;
      margin: 10px 0;
      max-width: 500px;
    }
  }
  .contract__list-title {
    display: flex;
    align-items: center;
    // margin-left: 18px;
    text-transform: uppercase;
    font-weight: 500;
    & mdc-button {
      margin-left: 20px;
    }
  }
  .confirm-modal {
    z-index: 1000;
  }
  .selected{
    width: 100%;
    // background-color: rgba(black, 0.16);
    color: green;
  }
  .subdoers-table{
    grid-column: span 2;
    border: 1px solid var(--mdc-theme-primary);
    &__qty-cell{
      padding: 0 10px;
    }
  }
  .highlighted{
    background: rgba(green, 0.16);
  }
  .file-upload__input_sizeless {
    width: 0!important;
    height: 0!important;
  }
</style>
