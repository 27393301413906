import './styles/theme.scss';
import { createApp } from 'vue';
import mitt from 'mitt';
import VueMaterialAdapter from 'vue-material-adapter';
import {
  phoneMask, dateMask, snilsMask, showTooltip,
} from './helpers/directives';
import App from './App.vue';
import store from './store';
import router from './router';

export const emitter = mitt();

const app = createApp(App).use(router).use(store).use(VueMaterialAdapter);
app.config.globalProperties.emitter = emitter;
// app.config.performance = true;
app.directive('phone', phoneMask);
app.directive('date', dateMask);
app.directive('snils', snilsMask);
app.directive('tooltip', showTooltip);

app.mount('#app');
