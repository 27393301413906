<template>
  <footer class="footer">
    <div class="copyright">Copyright © 2011-{{getCurrentYear}} · MedSafe LLC</div>
  </footer>
</template>

<script>

export default {
  name: 'AppFooter',
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style>
  .footer{
    background-color: #fff;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #eee;
  }
  .copyright{
    justify-self: center;
  }
  .actual-redactor{
    justify-self: end;
  }
</style>
