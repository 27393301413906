export const datepickerSettings = {
  locale: {
    format: 'DD.MM.YYYY',
    weekday: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    startsWeeks: 1,
    todayBtn: 'Сегодня',
    clearBtn: 'Очистить',
    closeBtn: 'Закрыть',
  },
};
